import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import Api from "../api";
import {fetchPlace} from "./placeSlice";
export const fetchStaff = createAsyncThunk(
  'user/fetchStaff',
  async function(params){
    let place = params.place;
    const resp = await Api.get(`/users/${place}/staff`);
    return resp.ok ? resp.json() : null;
  }
)
const staffSlice = createSlice({
  name:'user',
  initialState: {
    staff: null,
    loading: false,
  },
  reducers:{
  },
  extraReducers:{
    [fetchStaff.pending]:(state, action)=>{
      state.loading=true;
    },
    [fetchStaff.fulfilled]:(state, action)=>{
      state.loading=false;
      // console.log('staff in slice',action);
      state.staff=action.payload ?? [];
      // console.log('loaded', action.payload, state)
    },
    [fetchStaff.rejected]:(state, action)=>{
      state.loading=false;
      state.staff = [];

    }
  }
})



export const {} = staffSlice.actions;
export default staffSlice.reducer;