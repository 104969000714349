import React, {useState} from 'react';
import bg from "./images/bg-form-3.webp";
import {Box, Container, Grid, Typography} from "@mui/material";
import './styles.css';

import InputMask from 'react-input-mask';
import {Logo, Text} from "./Responsive";
import Api from "../../api";
function IndexForm(props) {
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [phoneValidated, setPhoneValidated] = useState(true)
  const [nameValidated, setNameValidated] = useState(true)
  const normalizePhone = (e) =>{
    let value = e.target.value.replace(/[^\d]/g, '');
    let match = value.match(/^[78]?\d{10}$/);
    setPhoneValidated(match);
    setPhone(value);
    setMessage( match ? "" : "Неверный номер телефона")
  }
  const normalizeName = (e) =>{
    let value = e.target.value.trim();
    setName(value);
    setNameValidated(value && true);
    setMessage( value ? "" : "Укажите имя")
  }
  const [message, setMessage] = useState('')
  const [sent, setSent] = useState(false)

  const send = () => {
    if(!name || !nameValidated){
      setMessage("Укажите имя");
      return;
    }
    if(!phone || !phoneValidated){
      setMessage("Укажите телефон");
      return;
    }
    setSent(true);
    Api.post('/callRequest', {name:name, phone:phone})
  }

  return (
    <Box sx={{background:`url('${bg}')`, minHeight:'500px'}} id={'form'}>
      <Container maxWidth={'xl'}>
        <Box display={'flex'} my={3}>
          <Box sx={{background:'url("./images/tablet.webp") center', maxWidth: {md:'658px', xs: '100%'},
            width: '100%', maxHeight: '500px',
            padding: {md:'30px 105px', xs:'20px 10px'}}} >
            <Box textAlign={"center"} className="title-form-text" mb={3}>
              Готовы начать пользоваться web-меню?
            </Box>
            <Box textAlign={"center"} className="description-form-text" mb={3}>
              Оставьте нам завявку и мы свяжемся с Вами!
            </Box>


            <Box className="col-request-form">
              {!sent && <Box component={'form'} display={'flex'} flexDirection={'column'}>
                  <Box mb={2}>
                    <input type="text" className={"input-big " + (nameValidated ? '' : 'error')} placeholder={'Имя'}
                           onInput={normalizeName} />
                  </Box>
                  <Box mb={2}>
                    <input type="tel" className={"input-big " + (phoneValidated ? '' : 'error')} placeholder={"Телефон"}
                           onInput={normalizePhone} />
                  </Box>
                {message && <Typography color={'#f86464'} mb={2} textAlign={'center'} fontSize={13}>{message}</Typography> }
                  <button type="button" onClick={send} className="btn-form">оставить заявку</button>
              </Box>}
              {sent && <Typography color={'white'} mb={3} textAlign={'center'} fontSize={13}>
                Мы приняли Вашу заявку, в скором времени наш специалист перезвонит Вам
              </Typography>}
            </Box>

          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default IndexForm;