import React, {} from 'react';
import {Link} from "react-router-dom";
import {
  Box,
  Container,
  Paper,
  Typography
} from "@mui/material";
import Api from "../../api";
import ImageHeader from "../../components/Qr/ImageHeader";
import PhoneLink from "../../components/Common/PhoneLink";
import MultilineText from "../../components/Common/MultilineText";
import {useSelector} from "react-redux";

function QrIndexPage(props) {

  document.title = 'Info';

  let {place, style} = useSelector(state=> state.place)
  const defaultStyle = Api.processStyle(style, 'textBlock');
  return (
    <>
      <ImageHeader image={Api.fileUrl(place.image) }   cover noMargin  height={"190px"}/>

      <Container >
        <Paper sx={{borderRadius: '10px', position:'relative',transform:"translateY(-20px)",
          zIndex:50, mb:".5rem", ...defaultStyle}}>
          <Container maxWidth="sm" sx={{marginBottom:1}}>
            <Box sx={{padding:'0.5rem 0'}}>
              <Typography fontWeight={'bold'} fontSize={14} variant={"h5"} textAlign={'center'} mb={2}>
                {place.name}
              </Typography>
              <Typography fontSize={12} mb={2}
                          color={defaultStyle.secondaryColor}>
                Наш адрес: {place.address}<br />
                Наш телефон: <PhoneLink phone={place.phone} />{place.phone2 &&
                  <>, <PhoneLink phone={place.phone2} /></>}<br />
                Наш email: {place.email}
              </Typography>
            </Box>
            <Box>
              <div className="content" dangerouslySetInnerHTML={{__html: place.map_link}}></div>
            </Box>
          </Container>
          <Container maxWidth="sm">
            <Box sx={{padding:'0.5rem 0'}}>
              <Typography fontWeight={'bold'} fontSize={14} variant={"h5"} textAlign={'center'} mb={2}>
                Info
              </Typography>

              <MultilineText color={defaultStyle.secondaryColor} text={place.description} fontSize={12} />
            </Box>

            <Typography textAlign={'center'}>
              <Link to={`/p/${place.code}`} style={{textDecoration: "none"}}>На главную</Link>
            </Typography>
          </Container>
        </Paper>

      </Container>
    </>
  );
}

export default QrIndexPage;