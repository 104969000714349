import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import Api from "../api";
const uuid = require("uuid");

export const createOrder = createAsyncThunk(
  'order/create',
  async function(params){

    const resp = await Api.post('/order/create', params);
    const data = resp.ok ? await resp.json() : null;
    return data;
  }
)
const id = localStorage.getItem('orderUID') ?? uuid.v4();
localStorage.setItem('orderUID', id);
let current = {
  sum:0,items:{},itemsCount:0,itemsTotal:0
}
try{
  let newCurrent = JSON.parse(localStorage.getItem('currentOrder'));
  if(newCurrent && (typeof newCurrent === 'object'))
    current = newCurrent;
} catch (ex){

}

const orderSlice = createSlice({
  name:'order',
  initialState: {
    orderUID: id,
    orders: JSON.parse(localStorage.getItem('orders') ?? '[]'),
    current: current,
  },
  reducers:{
    updateCurrent(state, action){
      state.current = action.payload
    },
    addItem(state, action){
      let item = {...action.payload?.item};
      if(typeof item !== "object" || !(item.id > 0))
        return;
      let items = state.current.items;
      let currentItem = items[item.id] ?? {amount:0}
      let newAmount = (parseInt(action.payload?.amount)) + currentItem.amount;
      item.amount = newAmount;
      if(newAmount > 0)
        items[item.id] = item;
      else
        delete items[item.id];
      let sum = 0, count = 0, total = 0;
      for (const i in items) {
        sum += items[i].amount * items[i].price;
        total += items[i].amount;
        count++;
      }
      state.current.sum = sum;
      state.current.itemsCount = count;
      state.current.itemsTotal = total;

      state.current.items = items;
      localStorage.setItem('currentOrder', JSON.stringify(state.current));
    },
  },
  extraReducers:{
    [createOrder.pending]:(state, action)=>{
      state.loading=true;
    },
    [createOrder.fulfilled]:(state, action)=>{
      state.loading=false;
      if(!action.payload) {
        state.place = null;
        state.style = null;
        return;
      }
      state.place = action.payload.place;
      state.style = action.payload.place.style ?? Api.getDefaultStyle();
    },
    [createOrder.rejected]:(state, action)=>{
    }
  }
})



export const {addItem, removeItem} = orderSlice.actions;
export default orderSlice.reducer;