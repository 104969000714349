import React from 'react';
import {Box, Typography} from "@mui/material";

function SpreadTextElement({text, value, color}) {
  return (
    <Box marginBottom={'0.25rem'} display={'flex'} justifyContent={'space-between'}>
      <Typography fontSize={12} variant={"subtitle1"} color={color ?? "#7E7E7E"} lineHeight={"100%"}>
        {text}
      </Typography>
      <Box borderBottom={"dashed lightgray 1px"} flexGrow={1} />
      <Typography fontSize={12} variant={"subtitle1"} color={color ?? "#7E7E7E"} lineHeight={"100%"}>
        {value}
      </Typography>
    </Box>
  );
}

export default SpreadTextElement;