import React from 'react';
import Api from "../../api";

function PhoneLink({phone, className, style}) {
  const short = Api.toPhone(phone);
  const formatted = Api.formatPhone(phone);
  const classList = className;
  const styles = {
    textDecoration:'none',
  };
  const newStyle = {...styles, ...style};
  if(!short)
    return (<></>)

  return (
    <a href={"tel:+7" + short} className={className} style={newStyle}>{formatted}</a>
  );
}

export default PhoneLink;