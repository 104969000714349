import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import {
  Box, Button, ButtonGroup,
  CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem,
  Paper, Select, Stack, Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import AdmCategoryModal from "../../components/Adm/AdmCategoryModal";
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TagChip from "../../components/Qr/TagChip";
import Utils from "../../utils";

function AdmCategoryListPage(props) {

  document.title = 'Категории меню сайта';

  const [cat, setCat] = useState({categories:[]});
  const [styles, setStyles] = useState({categories:[]});
  const [searchTag, setSearchTag] = useState(0);
  const [searchTagList, setSearchTagList] = useState([]);
  const rows = cat.categories;
  const [loading, setLoading] = useState(true);
  const [onlyActive, setOnlyActive] = useState(false);

  const {place, id} = useParams();
  const navigate = useNavigate();


  const reloadPage = () =>{
    setLoading(true);
    Api.post('/public/categories',
      {place:place, category:id,tag:searchTag ?? 0, onlyactive:onlyActive})
      .then(res => res.ok ? res.json() : null)
      .then((data) => {
        setCat(data);
        // console.log(data);
        Api.post('/company/styles',
          {place:place, type:"category"})
          .then(res => res.ok ? res.json() : null)
          .then((data) => setStyles(data.styles ?? []))
          .catch()
          .finally(() => setLoading(false))
      })
      .catch()
      .finally()

  };
  const loadTags = () => {
    Api.post('/adm/tags/get',{place:place, perpage:100})
      .then(res => res.json())
      .then(data => {
        setSearchTagList(data.tags);
      })
  }

  useEffect(()=>{
    loadTags();
    reloadPage();
  }, [place, id, searchTag, onlyActive])
  const goBack = () => navigate(-1);

  if(loading)
    return (<CircularProgress />);
  const updateRow = (index, newValue)=>{
    rows[index] = newValue;
    cat.categories = rows;
    setCat({...cat});
  }
  const swapCategory = (index, direction) => {
    if(index <= 0 && direction === "up")
      return;
    if(index >= cat.categories.length - 1 && direction === "down")
      return;
    let movedIndex = direction === "up" ? index - 1 : index + 1;
    let catMoving = cat.categories[index];
    let catMoved = cat.categories[movedIndex];
    Api.post('/adm/category/swap',
      {place:place, moving:catMoving.id, moved:catMoved.id})
      .then(res => res.ok ? res.json() : null)
      .then((data) => {
        // console.log(data);
        let categories = cat.categories;
        categories[index] = data.moved;
        categories[movedIndex] = data.moving;
        cat.categories = categories
        setCat({...cat});
      })
      .catch()
      .finally(() => setLoading(false))
  }

  const getDefaultCatForNew = ()=> {
    return {
      id:0,
      name:'Новая категория',
      description:"Описание новой категории",
      parent_id:id > 0 ? id : 0,
      status:'active',
      image:Utils.goodsDefaultImage()
    }
  }

  return (
    <Stack spacing={2}>
      <Box p={'0.5rem'} display={'flex'} gap={'10px'} justifyContent={'space-between'}>
        <Typography variant={'h5'}>
          {cat.current && <Link to={`/adm/${place}/category`
              + (cat.current.parent_id > 0 ? "/" + cat.current.parent_id : "") }>Назад</Link>}
          {cat.current ? " > " + cat.current.name : "Все категории"}
          {cat.current && <Box my={2}>
            <Button variant={'outlined'} onClick={()=>navigate(`/adm/${place}/unit/${cat.current.id}`)}>
              Позиции категории
            </Button>
          </Box>}
        </Typography>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
            <InputLabel id="demo-select-small">По тегу</InputLabel>
            <Select sx={{minWidth: 180 }}
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={searchTag}
                    label="По тегу"
                    onChange={(e) => {setSearchTag (e.target.value);}}
            >
              <MenuItem value={0}>Без разницы</MenuItem>
              {searchTagList.map((i, index) => (
                <MenuItem key={index} value={i.id}>{i.name}:&nbsp;<TagChip tag={i} /></MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel control={<Switch
            checked={onlyActive}
            onChange={(e)=>{setOnlyActive(e.target.checked);}}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Только активные" />
        </Box>
      </Box>
      <Box p={'0.5rem'} display={'flex'} gap={'10px'}>
        <AdmCategoryModal styles={styles} category={getDefaultCatForNew()} color={'success'}
          place={place} text={<AddBoxIcon />} variant={'contained'} new reload={reloadPage}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="center">Название</TableCell>
              <TableCell align="center">Описание</TableCell>
              <TableCell align="center">Статус</TableCell>
              <TableCell align="center">Изображение</TableCell>
              <TableCell align="center">Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cat.categories.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Box display={'flex'} gap={2} alignItems={"center"}>
                    <ButtonGroup
                      orientation="vertical"
                      aria-label="vertical outlined button group"
                    >
                      {index > 0
                        && <Button variant={'outlined'} size={"small"}
                                   onClick={()=>{swapCategory(index, 'up')}}>
                          <ArrowDropUpIcon />
                        </Button>}
                      {index < cat.categories.length - 1
                        && <Button variant={'outlined'} size={"small"}
                                   onClick={()=>{swapCategory(index, 'down')}}>
                          <ArrowDropDownIcon />
                        </Button>}
                    </ButtonGroup>


                    {row.id}
                  </Box>

                </TableCell>
                <TableCell>
                  <Link to={`/adm/${place}/category/${row.id}`}>{row.name}</Link><br/>
                  [<Link to={`/adm/${place}/unit/${row.id}`}>Позиции категории</Link>]
                </TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>
                  {row.status == 'active'
                  ? <Typography color='success.main'>Активен</Typography>
                  : <Typography color='error.main'>Неактивен</Typography>}
                  <Box>
                    {(row.tags).map((i,index) => ( <TagChip key={index} tag={i} />))}
                  </Box>

                </TableCell>
                <TableCell>
                  <img src={Api.fileUrl(row.image ? row.image : Utils.goodsDefaultImage())} style={{maxWidth:'60px', maxHeight:"50px", objectFit: 'cover'}} />
                </TableCell>
                <TableCell>
                  <AdmCategoryModal index={index} update={updateRow} styles={styles}
                      category={row} text={""} place={place}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default AdmCategoryListPage;