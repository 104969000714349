import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import QrCodeNotExists from "../../components/Qr/QrCodeNotExists";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import Api from "../../api";
import PageNotExistsPage from "../PageNotExistsPage";
import ImageHeader from "../../components/Qr/ImageHeader";
import IndexBlockList from "../../components/Qr/MenuPage/IndexBlockList";
import TagChip from "../../components/Qr/TagChip";
import Image from "mui-image";
import LoadingBlock from "../../components/Qr/LoadingBlock";
import {Phonelink} from "@mui/icons-material";
import PhoneLink from "../../components/Common/PhoneLink";
import {useSelector} from "react-redux";
import Utils from "../../utils";

function IndexMenuBtn(props){
  return (<Link to={props.link} style={{textDecoration: "none"}}>
    <Card sx={{position:'relative', zIndex:10,maxHeight:"70px"}} >
      <Image
        height={"100%"} width={'100%'}
        src={Api.fileUrl( props.image ?? Utils.goodsDefaultImage())}
        duration={0} fit={'cover'}
      />

      <Box position={'absolute'} zIndex={15} top={0} left={0} width={'100%'} height={'100%'}
           sx={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 90%)'}}
      />
      <Box sx={{padding: "8px", position:"absolute", zIndex:30, display:'flex',
        alignItems:'center', justifyContent:'center',
        top:0, left:0, width:"100%", height:"100%", color:"white"}}>
        <Typography fontWeight={'bold'} variant={"h5"} textAlign={'center'}>
          {props.text}
        </Typography>
      </Box>
    </Card>
  </Link>)
}


function QrIndexPage(props) {
  const [cat, setCat] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();


  let {place, style} = useSelector(state=> state.place)
  let {code,sum:codeSum} = useSelector(state=> state.code)
  const defaultStyle = Api.processStyle(style, 'textBlock');



  document.title = cat ? place.name : 'Lite Menu';
  useEffect(()=>{
    setLoading(true);
    Api.post('/public/categories', {place:place.code,onlyactive:true})
      .then(res =>{
        console.log(res);
        return res.json()
      })
      .then((data) => {
        // document.title = data.place.name;
        console.log(data);
        setCat(data);
      })
      .catch( e => {
        console.log(e)
      })
      .finally(()=>  {
        setLoading(false)
      })
  }, [place]);



  if(loading)
    return (<LoadingBlock />);

  if(!cat)
    return <PageNotExistsPage />


  return (
    <>
      <ImageHeader image={Api.fileUrl(place.image) }  cover noMargin  height={"190px"}/>

      <Container>
        <Paper sx={{borderRadius: '10px', position:'relative',transform:"translateY(-20px)",
          zIndex:50, mb:".5rem", ...defaultStyle}}>
          <Container maxWidth="sm">
            <Box sx={{padding:'0.5rem'}}>
              <Typography fontWeight={'bold'} variant={"h5"} textAlign={'center'} mb={2}>
                {place.name}
              </Typography>
              <Typography fontSize={12} mb={2}>
                Наш адрес: {place.address}<br />
                Наш телефон: <PhoneLink phone={place.phone} />
              </Typography>
              <Typography textAlign={'end'}>
                <Link to={`/p/${place.code}/about`} style={{textDecoration: "none"}}>Подробнее</Link>
              </Typography>
            </Box>
            <Box>
              <div className="content" dangerouslySetInnerHTML={{__html: place.map_link}}></div>
            </Box>
          </Container>
        </Paper>
        <Grid container spacing={1} my={1}>
          {place?.data?.show_code_links && code && <Grid item xs={12}>
            <IndexMenuBtn link={`/p/${place.code}/codes/${code.code}`}
                          image={place.menu_btn_image}
                          text={"Мой заказ"} />
          </Grid>}
          {place?.data?.show_code_links && !code && <Grid item xs={12}>
            <IndexMenuBtn link={`/p/${place.code}/ask_code`}
                          image={place.menu_btn_image}
                          text={"Мой заказ"} />
          </Grid>}
          <Grid item xs={12}>
            <IndexMenuBtn link={`/p/${place.code}/menu`}
                          image={place.menu_btn_image}
                          text={"Наше меню"} />
          </Grid>

          {/*<Grid item xs={12}>*/}
          {/*  <hr />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12}>*/}
          {/*  <Paper sx={{borderRadius: '10px', mb:".5rem", ...defaultStyle}}>*/}
          {/*    <Container maxWidth="sm">*/}
          {/*      <Box sx={{padding:'0.5rem', cursor:'pointer'}}>*/}
          {/*        <Link to={'/'}>*/}
          {/*          <Typography fontWeight={'bold'} color={'text.default'} sx={{textDecoration:'none'}} variant={"h6"} textAlign={'center'} my={1}>*/}
          {/*            О системе Lite Menu*/}
          {/*          </Typography>*/}
          {/*        </Link>*/}
          {/*      </Box>*/}
          {/*    </Container>*/}
          {/*  </Paper>*/}
          {/*</Grid>*/}


        </Grid>




      </Container>
    </>
  );
}

export default QrIndexPage;