import React from 'react';
import Api from "../../api";
import {Box, Card, CardActionArea, CardContent, CardMedia, Chip, ListItem, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import TagChip from "./TagChip";
import {useSelector} from "react-redux";
import GoodsCardOrderControls from "./Goods/GoodsCardOrderControls";
import Utils from "../../utils";

function GoodsCardVertical({goods, onClick}) {
  const tags = goods.tags ?? [];

  let {place, style} = useSelector(state => state.place);
  let individualStyle = Api.processStyle(style, 'card',
    'goodsList', goods.id);


  let navigate = useNavigate();
  const openLink = ()=>{
    const path = `/p/${place.code}/u/${goods.id}`;
    return navigate(path);
  }

  return (
    <Card sx={{ ...{display: 'flex',
        flexDirection: "column", height: "100%", borderRadius:'8px'},...individualStyle
        }}>
      <CardActionArea onClick={onClick ?? openLink} sx={{display:"flex", flexDirection:'column', width:"100%",
        alignItems:"start", height: "100%"}}>
        <CardMedia
          component="img"
          height={150}
          image={Api.fileUrl(goods.image ? goods.image : Utils.goodsDefaultImage())}
          alt={goods.name}
        />
        <CardContent sx={{padding: 1}}>
          <Typography color={individualStyle.color} component="div" variant={'caption'} fontSize={12} sx={{marginBottom:'6px'}}>
            {goods.name}
          </Typography>
          <Box sx={{display: 'flex', flexWrap:'wrap', columnGap: 1}}>
            {tags.map((i, key) =>
              <TagChip tag={i} key={key} />
            )}
          </Box>
        </CardContent>
        <Box sx={{flexGrow:1}} ></Box>
      </CardActionArea>
      <CardContent sx={{padding: "1rem 0.5rem 0.5rem 0.5rem !important", width:"100%"}}>
        <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography color={individualStyle.tertiaryColor} fontSize={13} fontWeight={'bold'}>
            {goods.price}&nbsp;₽ {(goods.unit && goods.unit.trim()) ? ' / ' + goods.unit.trim() : ''}
          </Typography>
          {(goods.allow_order > 0) && <GoodsCardOrderControls goods={goods} />}
        </Box>
      </CardContent>
    </Card>
  );
}

export default GoodsCardVertical;