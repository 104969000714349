import React, {useEffect, useState} from 'react';
import {
  Box, Button,
  FormControl, FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack, Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import ReservationsTable, {EditReservationModal} from "../../components/Hostess/ReservationsTable";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Utils from "../../utils";
import CodesTable, {EditCodeModal} from "../../components/Hostess/CodesTable";

/**
 * Список бронирований для ресторана
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function HostessCodesList(props) {
  document.title = 'Список qr - Hostess Lite-Menu';
  const {place} = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({search:'', active:true})
  const [openNewModal, setOpenNewModal] = useState(false);
  const update = (field, value) =>{
    setFilter({...filter, [field]:value});
  }
  const setToday = () => {
    setFilter({...filter, from:Utils.getTime(), to:Utils.getTime()});
  }
  return (
    <div>
      <EditCodeModal
        open={openNewModal} adm={props.adm ?? false}
        onClose={()=>setOpenNewModal(false)}
        onSave={()=>{setOpenNewModal(false);setFilter({...filter})}}
      />
      <Box my={2}>
        <Stack spacing={2} direction={'row'}>
          <Typography variant={'h5'}>Список QR-кодов</Typography>
        </Stack>
      </Box>
      <Box my={2}>
        <Stack spacing={2} direction={'row'}>
          <Button variant={'contained'} color={'success'}
                  onClick={()=>{setOpenNewModal(true)}}>Добавить</Button>
          {props.adm && <FormControlLabel control={<Switch
            checked={filter.active}
            onChange={(e)=>update('active', e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label={'Только открытые'} />}
        </Stack>

      </Box>

      <CodesTable filter={filter} adm={props.adm ?? false} />
    </div>
  );
}

export default HostessCodesList;