import React from 'react';
import {Box, Button, Container, Paper, Typography} from "@mui/material";
import LinkButton from "../components/Adm/LinkButton";
import {useNavigate, useParams} from "react-router-dom";

function PageNotExists(props) {
  const navigate = useNavigate();
  let {place} = useParams();
  return (
    <Container maxWidth={"sm"} sx={{marginTop:'120px'}}>
      <Paper sx={{p:2}}>
        <Typography align={"center"} variant={'h4'} mb={3}>Lite Menu</Typography>
        <Typography align={"center"}>Страница не найдена</Typography>
        <Box display={'flex'} justifyContent={'center'} my={3}>
          {place && <LinkButton to={`/p/${place}/menu`}>Вернуться в главное меню</LinkButton>}
          {!place && <Button onClick={()=>navigate(-1)} variant={'text'}>Вернуться</Button>}
        </Box>
      </Paper>

    </Container>

  );
}

export default PageNotExists;