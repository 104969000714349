import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper, Stack,
  Typography
} from "@mui/material";
import Api from "../../api";
import _ from "lodash";
import PageNotExistsPage from "../PageNotExistsPage";
import ImageHeader from "../../components/Qr/ImageHeader";
import LoadingBlock from "../../components/Qr/LoadingBlock";
import {useDispatch, useSelector} from "react-redux";
import QrReservationOrderBlock from "../../components/Hostess/QrReservationOrderBlock";
import {snackShow} from "../../store/snackbarSlice";
import Divider from '@mui/material/Divider';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QrReservationReviewBlock from "../../components/Hostess/QrReservationReviewBlock";
import {setCode} from "../../store/codeSlice";
import QrReservationWaiterBlock from "../../components/Hostess/QrReservationWaiterBlock";

function QrAskForCodeNav(props){
  const navigate = useNavigate();
  const {place} = useParams();
  return (
    <Container maxWidth="sm">
      <Stack direction={'row'} spacing={2} justifyContent={'space-evenly'}>
        <Button startIcon={<RestaurantMenuIcon />} color={'inherit'}
                onClick={()=>navigate(`/p/${place}`)}>
          О нас
        </Button>
        <Divider />
        <Button startIcon={<MenuBookIcon />} color={'inherit'}
                onClick={()=>navigate(`/p/${place}/menu`)}>
          В меню
        </Button>
      </Stack>
    </Container>
  )
}


function QrAskForCode(props) {
  document.title = 'Бронирование';
  let {place:pls, style} = useSelector(state=> state.place)
  const defaultStyle = Api.processStyle(style, 'textBlock');
  return (
    <>
      <ImageHeader image={Api.fileUrl(pls.image) }  cover noMargin  height={"110px"}/>
      <Container>
        <Paper sx={{borderRadius: '10px',position:'relative',transform:"translateY(-20px)",
          minHeight:'400px',
          zIndex:50, py:"1rem", ...defaultStyle}}>
          <QrAskForCodeNav />
          <Container maxWidth="sm" sx={{marginBottom:1}}>
            <Box my={2}>
              <Typography fontWeight={'bold'} fontSize={20} variant={"h5"} textAlign={'center'}>
                Ваш заказ
              </Typography>
            </Box>
            <Box textAlign={'center'}>
              Получите у официанта QR-код с постоянной ссылкой на Ваш заказ, где вы сможете в любой
              момент вызвать официанта, просмотреть свой заказ и оставить оценку на него и наше
              обслуживание.
            </Box>
          </Container>
        </Paper>

      </Container>
    </>


  );
}

export default QrAskForCode;