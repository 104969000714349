import React from 'react';
import {Grid} from "@mui/material";
import GoodsCardVertical from "../GoodsCardVertical";

function BaseLayout(props) {
  return (
    <Grid container spacing={1}>
      {props.goods.map((i, index) =>
        <Grid item xs={6} sm={4} md={3} key={index}>
          <GoodsCardVertical goods={i}/>
        </Grid>
      )}
    </Grid>
  );
}

export default BaseLayout;