import React from 'react';
import {Box, Divider, Grid, Stack, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import Api from "../../../api";
import Image from "mui-image";
import {Link, useNavigate} from "react-router-dom";
import GoodsCardOrderControls from "../Goods/GoodsCardOrderControls";

function CartBlock(props) {
  const order = useSelector(state => state.order);
  let {place, style} = useSelector(state=> state.place)
  const defaultStyle = Api.processStyle(style, 'textBlock');
  const navigate=useNavigate();
  const goToItem = (item)=>{
    navigate(`/p/${place.code}/u/${item.id}`)
  }
  // console.log(defaultStyle);

  const mapOrder = ()=>{
    let first = true;
    return Object.keys(order.current.items).map(key => {
      let i = order.current.items[key];
      // console.log(key);
      const needBar = !first;
      first = false;
      return (
        <Box key={key}>
          {needBar && <Divider sx={{mb:1}} />}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} onClick={()=>goToItem(i)} sx={{cursor:'pointer'}}>
              <Image src={Api.fileUrl(i.image)} duration={0} style={{xs:{maxWidth:'100%', height:'150px',
                objectFit:'cover', cursor:'pointer'},sm:{maxWidth:'150px',height:'100px'}}}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={9} xl={10}>
              <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'}>
                <Link to={`/p/${place.code}/u/${i.id}`} style={{textDecoration:"none",fontSize:16,fontWeight:'bold',
                    ...defaultStyle}}> {i.name}</Link>
                <Typography fontSize={13} mb={1} color={defaultStyle.secondaryColor}>
                  {i.description}
                </Typography>
                <Box flexGrow={1}></Box>
                <Box mt={1} display={'flex'} justifyContent={'space-between'}>
                  <GoodsCardOrderControls goods={i}/>
                </Box>
              </Box>

            </Grid>
          </Grid>

        </Box>
      )
    });
  }
  // console.log(order);
  return (
    <Stack spacing={1}>
      {mapOrder()}
      <Box>
        <Divider sx={{mb:1}} />
        <Typography textAlign={'center'}>
          <Link to={`/p/${place.code}/menu`} style={{color:defaultStyle.color}}>Добавить в корзину</Link>
        </Typography>
      </Box>
    </Stack>
  );
}

export default CartBlock;