import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import StyleElement from "./StyleElement";
import {useDispatch, useSelector} from "react-redux";
import {updateStyle} from "../../../store/sandboxStyleSlice";

function CommonStyles(props) {
  const {setImage} = props;
  const style = useSelector(state => state.sandboxStyle.style);
  const dispatch = useDispatch();
  const update = (val) => {
    dispatch(updateStyle({...style, ...val}))
  }
  return (

    <Grid container spacing={1} my={2}>
      <Grid item md={12}>
        <StyleElement label={'Общий стиль'} part={'main'} setImage={setImage} noborder
          labels={{tertiaryColor:'Цвет текста на фоне'}}
        />
      </Grid>
      <Grid item md={12}><hr /> </Grid>
      <Grid item md={12}>
        <StyleElement label={'Блок текста (главная страница и страница "О нас")'} setImage={setImage} part={'textBlock'}
                      labels={{secondaryColor:'Текст описания'}}
        />
        <Grid item md={12}><hr /> </Grid>
        <Grid item md={12}>
          <StyleElement label={'Карточки товаров'} setImage={setImage} part={'card'}
              labels={{tertiaryColor:'Цена', secondaryColor:'Текст описания'}}
          />
        </Grid>
        <Grid item md={12}>
          <StyleElement label={'Страница позиции'} setImage={setImage} part={'goods'}
                        labels={{tertiaryColor:'Цена', secondaryColor:'Текст описания'}}
          />
        </Grid>
        <Grid item md={12}><hr /> </Grid>
        <Grid item md={4}>&nbsp;</Grid>
        <Grid item md={4}>
          <FormControl fullWidth size={'small'} sx={{marginBottom:2}}>
            <InputLabel id="demo-simple-select-label">Тип блока категорий</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={style?.categoryBlock?.type ?? 'slider'}
              label="Тип блока категорий"
              onChange={(e) =>
                update({categoryBlock:{...(style?.categoryBlock ?? {}),type:e.target.value}})}
            >
              <MenuItem value={'slider'}>Слайдер</MenuItem>
              <MenuItem value={'block'}>Плитка</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <StyleElement label={'Карточки категорий'} setImage={setImage} part={'categoryCard'}
          />
        </Grid>
        <Grid item md={12}>
          <StyleElement label={'Блок категорий'} setImage={setImage} part={'categoryBlock'}
                        labels={{secondaryColor:'Текст описания'}}
          />
        </Grid>

        <Grid item md={12}><hr /> </Grid>
        <Grid item md={12}>
          <StyleElement label={'Страница карусели'} setImage={setImage} part={'carousel'}
                        labels={{}}
          />
        </Grid>
        <Grid item md={12}>
          <StyleElement label={'Блок текста карусели'} setImage={setImage} part={'carouselText'}
                        labels={{secondaryColor:'Состав',tertiaryColor:'Цена'}}
          />
        </Grid>
      </Grid>

      <Grid item md={12} />

    </Grid>
  );
}

export default CommonStyles;