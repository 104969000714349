import React from 'react';
import {Box, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import Api from "../../api";
import {Link} from "react-router-dom";

function QrReservationOrderBlock({order,data}) {
  let {place:pls, style} = useSelector(state=> state.place)
  const defaultStyle = Api.processStyle(style, 'textBlock');
  return (
    <Box my={3}>
      <Typography variant={'h6'} align={'center'} mb={1} fontWeight={'bold'}>
        Заказ №{data.id}
      </Typography>
      {/*<Box my={1} color={defaultStyle.secondaryColor}>*/}
      {/*  МЕСТО: {data.place}*/}
      {/*</Box>*/}
      <Box my={1}>
        {data.positions.map((i, index)=>(
          <Box key={index} mb={1}>

            <Typography variant={'body1'} fontSize={13} color={defaultStyle.color}>
              {(i.goods_id > 0) && <Link to={`/p/${pls.code}/u/${i.goods_id}`}
                                         style={{color:defaultStyle.color}}>
                {i.name}
              </Link>}
              {(!i.goods_id || i.goods_id <= 0) && <>{i.name}</>}
            </Typography>
            <Box display={'flex'} fontSize={13} justifyContent={'space-between'}>
              <Typography variant={'body1'} color={defaultStyle.secondaryColor}>
                {i.amount} ед.
              </Typography>
              <Typography variant={'body1'} color={defaultStyle.color}>
                {i.price}₽
              </Typography>
            </Box>
            <hr style={{background:defaultStyle.color}}/>
          </Box>

        ))}
      </Box>
      <Box my={1} display={'flex'} justifyContent={'space-between'}>
        <Typography fontSize={18}>
          ИТОГО:
        </Typography>
        <Typography fontSize={18}>
          <strong>{data.sum}₽</strong>
        </Typography>
      </Box>
    </Box>
  );
}

export default QrReservationOrderBlock;