import React from 'react';
import {Box, Container, Grid, Typography} from "@mui/material";
import Image from "mui-image";
import cook from "./images/cook.webp";
import {Link} from "react-router-dom";
import {Logo, Text} from "./Responsive";
import qrFazenda from './images/qr-fazenda.png';
import qrObriens1 from './images/qr-obriens1.png';


function IndexServices(props) {
  return (
    <Box py={"50px"}>
      <Container maxWidth={"xl"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={7}>
            <Logo>
              Базовый функционал Lite Menu
            </Logo>
            <Box my={3}>
              <Text>Все базовые функции Lite Menu абсолютно бесплатны. К ним относятся:</Text>
              <ul>
                <li><Text>Основные страницы меню - главная, меню, карточки позиций и страницы позиций с их описаниями и
                  параметрами</Text></li>
                <li><Text>Функционал стилизования меню</Text></li>
                <li><Text>Поисковые и информационные теги для позиций и категорий с настраиваемым внешним видом.</Text></li>
              </ul>
            </Box>


            <Logo>
              Дополнительные возможности
            </Logo>
            <Text mb={2}>
              Интеграция с системами доставки Яндекс.Еда и Delivery Club.
            </Text>
            <Text mb={2}>
              Интеграция с <span style={{color:'#ff5a5a'}}>iiko</span> и <span style={{color:'#00CF6F'}}>R-Keeper</span>
              (автоматическое обновление цен и стоп-листа).
            </Text>
            <Text mb={4}>
              Настройка индивидуальных стилей и дизайна меню.
            </Text>
            <Text mb={4}>
              Прайс лист с ценами на дополнительные услуги Вы можете найти <Link to={"/pricelist"}>здесь</Link>.
            </Text>


            <Logo mb={2}>
              Индивидуальная настройка стиля Вашего меню
            </Logo>
            <Text mb={2} >
              Вы можете менять практически любые параметры внешнего вида любого элемента меню - карточек позиций или категорий, любых
              страниц, а также общий стиль. Ниже приведены ссылки на примеры
            </Text>
            <Box display={'flex'} flexWrap={'wrap'} gap={2} justifyContent={'center'}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Link to={'/p/edr100/menu'} target={'_blank'}><Image src={qrFazenda} duration={0} width={250} height={250} /></Link>
              </Box>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Link to={'/p/obr01/menu'} target={'_blank'}><Image src={qrObriens1} duration={0} width={250} height={250} /></Link>
              </Box>
              

            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={5} display={{xs:'none', md:'block'}}>
            <Image src={cook} duration={0} width={"100%"} style={{maxWidth: '380px', maxHeight: '750px', objectFit: 'contain'}} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default IndexServices;