import React from 'react';
import {Box, Chip, Popover, Typography} from "@mui/material";

import Tooltip from '@mui/material/Tooltip';

function TagChip({tag}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  let unstyledStyle = {
    background:tag.background, color:tag.color,
    borderColor: (tag.border && tag.border != '') ? tag.border : tag.background, borderStyle:'solid',borderWidth:'1px'
  };

  return (
    <Box aria-owns={open ? 'mouse-over-popover' : undefined} display={'inline-block'}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}>
      {(tag.style && tag.style != '') ? <Chip color={tag.style} size={'small'}
                                              sx={{fontSize: '10px', borderRadius:'6px'}} label={tag.short_text} />
        : <Chip size={'small'} style={unstyledStyle}
                sx={{fontSize: '10px', borderRadius:'6px'}} label={tag.short_text} />}
      {tag.description &&
        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: 'none', borderRadius:'8px'}}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{tag.description}</Typography>
        </Popover>}
    </Box>
  );
}

export default TagChip;