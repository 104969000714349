import React, {useEffect, useState} from 'react';
import {
  Alert, Autocomplete, Box,
  Button, ButtonGroup, Checkbox, Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select,
  Snackbar, Stack, TextareaAutosize,
  TextField, Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/icons-material/HighlightOff';
import Image from "mui-image";
import Api from "../../api";
import axios from "axios";
import _ from "lodash";
import ImageSelectBlock from "./ImageSelectBlock";
import AdmTagElementController from "./AdmTagElementController";
import {useParams} from "react-router-dom";
import {MuiColorInput} from "mui-color-input";
import TagChip from "../Qr/TagChip";

function AdmTagsModal(props) {


  const fromDefault = (tag) => {
    let defaultTagData= {
      id: 0,
      name: 'Новый тег',
      short_text: 'Тег',
      description: 'Описание',
      type: 'goods',
      style: 'primary',
      background: 'lightgray',
      color: 'rgb(65,65,65)',
    };
    tag = {...defaultTagData, ...tag};
    if(tag.style == null) tag.style = defaultTagData.style;
    if(tag.background == null) tag.background = defaultTagData.background;
    if(tag.color == null) tag.color = defaultTagData.color;
    if(tag.border == null) tag.border = defaultTagData.border;
    if(tag.description == null) tag.description = '';
    return tag;
  }
  const [open, setOpen] = React.useState(false);
  const [snack, setSnack] = React.useState({message:'Данные успешно сохранены', type:'success'})
  const [loading, setLoading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);

  const [tag, setTag] = React.useState(fromDefault(props.tag) );
  const [type, setType] = React.useState(props.tag.type ?? 'goods');
  const {text, update, index} = props;
  const {place} = useParams();

  // console.log("temp", tempData, 'style', style)

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };
  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const setTagData = (newValue) => {
    let newTag = {...tag, ...newValue};
    setTag(newTag)
  }
  const save = () => {
    setLoading(true);

    // console.log("saving", tag);
    Api.post('/adm/tags/update', {tag:tag, place:place})
      .then(resp => resp.json())
      .then(resp => {
        setSnack({...{message:"Данные успешно сохранены", type:'success'}})
        handleSnackOpen();
        setOpen(false);

        let item = resp.item
        // console.log("item", resp);
        if(typeof item.data === "string")
          item.data = JSON.parse(item.data);
        setTag(item);
        console.log("response", item);
        if(props.new && props.reload)
          props.reload();
        if(props.update)
          props.update(index, item);
      }).catch(error=>{

        console.log("error", error);
        let errdata = error?.response?.data ?? "";
        setSnack({...{message:"Ошибка при сохранении данных: " + errdata.error, type:'error'}})
        handleSnackOpen();
      })
      .finally(()=>{
        setLoading(false);
      })
  };

  return (
    <>
      <Button variant={props.variant ? props.variant : 'outlined'}
              color={props.color ? props.color : 'primary'} onClick={handleClickOpen}>
        {text ? text : <EditIcon />}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"} fullWidth={true}>
        <DialogTitle>Редактирование тега</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} component={'form'} onSubmit={save} py={1}>
            <Grid item md={6}>
                <TextField size="small"
                  autoFocus margin="dense"
                  name='name' value={tag.name ?? ''}
                  onInput={(e)=>setTagData({name: e.target.value})}
                  label="Название" fullWidth variant="standard"
                />
            </Grid>
            <Grid item md={3}>
                <TextField size="small"
                  autoFocus margin="dense"
                  name='name' value={tag.short_text ?? ''}
                  onInput={(e)=>setTagData({short_text: e.target.value})}
                  label="Текст на теге" fullWidth variant="standard"
                />
            </Grid>
            <Grid item md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type ?? 'goods'}
                  label="Age"
                  onChange={(e)=>{setType(e.target.value)}}
                >
                  <MenuItem value={'goods'}>Позиция</MenuItem>
                  <MenuItem value={'category'}>Категория</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={10}>
              <TextField size="small"
                         autoFocus margin="dense"
                         name='name' value={tag.description ?? ''}
                         onInput={(e)=>setTagData({description: e.target.value})}
                         label="Описание" fullWidth variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Box display={'flex'} height={'100%'} width={'100%'} justifyContent={'center'}
                   alignItems={'center'}>
                <TagChip tag={tag} />
              </Box>
            </Grid>


            <Grid item md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tag.style ?? 'primary'}
                  label="Age"
                  onChange={(e)=>{setTagData({style:e.target.value})}}
                >
                  <MenuItem value="">
                    Свои цвета
                  </MenuItem>
                  <MenuItem value={"default"}>
                    <Chip color={"default"} size={'small'} label={'Default'} />
                  </MenuItem>
                  <MenuItem value={"error"}>
                    <Chip color={"error"} size={'small'} label={'Error'} />
                  </MenuItem>
                  <MenuItem value={"secondary"}>
                    <Chip color={"secondary"} size={'small'} label={'Secondary'} />
                  </MenuItem>
                  <MenuItem value={"primary"}>
                    <Chip color={"primary"} size={'small'} label={'Primary'} />
                  </MenuItem>
                  <MenuItem value={"info"}>
                    <Chip color={"info"} size={'small'} label={'Info'} />
                  </MenuItem>
                  <MenuItem value={"warning"}>
                    <Chip color={"warning"} size={'small'} label={'Warning'} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {!tag.style && <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box textAlign={"center"}>
                Текст
                <MuiColorInput value={tag.color ?? 'rgb(44,44,44)'}  size="small" variant="outlined"
                               onChange={(e)=>setTagData({color:e})}/>
              </Box>
            </Grid>}
            {!tag.style && <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box textAlign={"center"}>
                Фон
                <MuiColorInput value={tag.background ?? 'lightgray'}  size="small" variant="outlined"
                               onChange={(e)=>setTagData({background:e})}/>
              </Box>
            </Grid>}
            {!tag.style && <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box textAlign={"center"}>
                Граница
                <MuiColorInput value={tag.border ?? 'lightgray'}  size="small" variant="outlined"
                               onChange={(e)=>setTagData({border:e})}/>
              </Box>
            </Grid>}
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={save} variant={'contained'} color={'success'}>Сохранить</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackOpen} autoHideDuration={6000}
        onClose={handleSnackClose} anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert onClose={handleSnackClose} severity={snack.type} sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AdmTagsModal;
