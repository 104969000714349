import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Button} from "@mui/material";
import {addItem} from "../../../store/orderSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

function GoodsCardOrderControls({goods}) {
  const currentOrder = useSelector(state=>state.order.current);
  const item = useSelector(state=>state.order.current?.items[goods.id]);
  const dispatch = useDispatch();
  const add = ()=> {
    dispatch(addItem({amount:1, item:goods}))
  }
  const remove = ()=> {
    dispatch(addItem({amount:-1, item:goods}))
  }
  return (
    <Box display={'flex'} alignItems={'center'}>
      {item && <Box display={'flex'}>
        {item.amount > 1 ? <IndeterminateCheckBoxIcon sx={{cursor:'pointer'}} color={'action'} onClick={remove} />
          : <DeleteIcon sx={{cursor:'pointer'}} color={'error'} onClick={remove} />}

        {/*<Button sx={{px: 1,minWidth:"30px",bgcolor:'white'}} variant={'outlined'} onClick={remove} size={'small'}>-</Button>*/}
        <Box px={1} display={'flex'} alignItems={'center'}>{item.amount}</Box>
        <AddBoxIcon sx={{cursor:'pointer'}} onClick={add} color={'success'} />
        {/*<Button sx={{px: 1,minWidth:"30px",bgcolor:'white'}} variant={'outlined'} onClick={add} size={'small'}>+</Button>*/}
      </Box>}
      {!item && <AddBoxIcon sx={{cursor:'pointer'}} onClick={add} color={'success'} />}
      {/*{!item && <Button sx={{px: 1,minWidth:"30px",bgcolor:'white'}} variant={'outlined'} onClick={add} size={'small'}>+</Button>}*/}
    </Box>
  );
}

export default GoodsCardOrderControls;