import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  Box,
  Button, Dialog, DialogContent, DialogTitle,
  FormControl,
  FormControlLabel, Grid, IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table, TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Utils from "../../utils";
import {DatePicker} from "@mui/x-date-pickers";
import ReservationsTable from "../../components/Hostess/ReservationsTable";
import HostessReservationList from "../Hostess/HostessReservationList";
import HostessCodesList from "../Hostess/HostessCodesList";
import CodesTable, {EditCodeModal} from "../../components/Hostess/CodesTable";
import Api from "../../api";
import ResponsiveContainer from "../../components/Common/ResponsiveContainer";
import CodeEdit from "../../components/Hostess/CodeEdit";
import {useDispatch, useSelector} from "react-redux";
import {snackShow} from "../../store/snackbarSlice";
import CloseIcon from "@mui/icons-material/Close";

const userDefault = {
  name:'', phone:'', email: '', address:"",pwd:'', pwd_confirm:'',
}
const userDataDefault = {
  public_name:'', public_comment:'', public_tip_data:'', public_tip_link:'',
  staff_id:0,role:'client', ...userDefault
}

function UserEdit(props) {
  const dispatch = useDispatch();
  const {place} = useParams();
  const [userData] = useState(props.user ?? userDataDefault)
  const u = useSelector(state=> state.user.user);
  const staff = useSelector(state=>state.staff.staff);
  // console.log('staff in user edit',staff);
  const [data, setData] = useState( props.user
    ? { ...props.user.user, ...props.user,
        pwd:'', pwd_confirm:'',
        role:userData?.role ?? props.user.user.role,
        staff_id: userData.staff_id ?? 0,
        public_name:userData?.public_name,
        public_comment:userData?.public_comment,
        public_tip_data:userData?.public_tip_data,
        public_tip_link:userData?.public_tip_link,
    }
    : userDefault
  );
  const showSnack = (message, type='success') => {
    dispatch(snackShow({message, type}))
  }
  const update = (field, value, validation='') =>{
    setData({...data, [field]:value});
  }

  const send = () => {

    let endpoint = props.user
      ? `users/${place}/${props.user.id}`
      : `users/${place}`;
    Api.post(endpoint, data)
      .then(res=>res.json())
      .then((data)=>{
        if(data.error){
          showSnack("Ошибка: " + data.error, 'error');
          return;
        }
        if(typeof props.onSave == 'function')
          props.onSave();
      }).catch(e =>{
      let errdata = e?.response?.data ?? "";
      showSnack("Ошибка при сохранении данных: " + errdata.error, 'error');
      if(typeof props.onError == 'function')
        props.onError();
    })

  }
  const closeHandler = ()=> {
    if(typeof props.onClose == 'function')
      props.onClose();
  }
  const disabledMinRole = (minrole = 'sadmin') => {
    let roles = ['sadmin','admin','personnel','hostess','client'];
    let role_not_pass = true;
    let uData = u.place_users?.find(i=>i.place_code == place) ?? {};
    if(roles.find(i=>i==minrole)) {
      for (let i = 0; i < roles.length; i++) {
        if(u.role == roles[i] || uData.role == roles[i]){
          role_not_pass = false;
          break;
        }
        if (minrole == roles[i])
          break;
      }
    }

    return role_not_pass;
  }
  const disabledForEdit = (minrole = 'sadmin') => {
    return props.user
      && disabledMinRole(minrole)
      && props.user.user?.id!=u.id;
  }

  return (

    <Grid container spacing={2} py={2}>
      <Grid item xs={12} md={4}>
        <TextField sx={{width:'100%'}}  label={'Имя'} value={data.name ?? ''}
                   disabled={disabledForEdit()}
                   onChange={(e)=>update('name', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField sx={{width:'100%'}}  label={'Телефон'}
                   disabled={disabledForEdit()}
                   onChange={(e)=>update('phone', e.target.value)}
                   value={data.phone} />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField sx={{width:'100%'}}  label={'Email'}
                   disabled={disabledForEdit()}
                   onChange={(e)=>update('email', e.target.value)}
                   value={data.email} />
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl variant="outlined" sx={{ width: '100%'}}
                     disabled={disabledForEdit('admin')}>
          <InputLabel id="select-status-label">Персонал</InputLabel>
          <Select
                  labelId="select-status-label"
                  disabled={disabledForEdit('admin')}
                  id="select-status"
                  value={data.staff_id}
                  onChange={(e)=>update('staff_id', e.target.value)}
                  label="Персонал"
          >
            <MenuItem value={0}>Нет</MenuItem>
            {staff.map((i, index)=>(
              <MenuItem value={i.id} key={i.id}>{i.name} ({i.phone ? '8'+i.phone : '-'})</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" sx={{ width: '100%'}}
                     disabled={disabledForEdit('admin')}>
          <InputLabel id="select-status-label">Роль</InputLabel>
          <Select disabled={disabledMinRole('admin')}
                  labelId="select-status-label"
                  id="select-role"
                  value={data.role}
                  onChange={(e)=>update('role', e.target.value)}
                  label="Роль"
          >

            <MenuItem value={'client'}>Клиент</MenuItem>
            <MenuItem value={'hostess'}>Хостесс</MenuItem>
            <MenuItem value={'personnel'}>Персонал</MenuItem>
            <MenuItem value={'admin'}>Админ</MenuItem>
            <MenuItem disabled={true} value={'sadmin'}>Суперадмин</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField sx={{width:'100%'}}  label={'Адрес'} autoComplete={'nope'}
                   disabled={disabledForEdit()}
                   onChange={(e)=>update('address', e.target.value)}
                   value={data.address} />
      </Grid>

      <Grid item xs={12}>
        <hr/>
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField sx={{width:'100%'}}  label={'Имя для клиента'} autoComplete={'nope'}
                   disabled={disabledForEdit('admin')}
                   onChange={(e)=>update('public_name', e.target.value)}
                   value={data.public_name ?? ''} />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField sx={{width:'100%'}}  label={'Текст для клиента'} autoComplete={'nope'}
                   disabled={disabledForEdit('admin')}
                   onChange={(e)=>update('public_comment', e.target.value)}
                   value={data.public_comment ?? ''} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField sx={{width:'100%'}}  label={'Данные для копирования'} autoComplete={'nope'}
                   disabled={disabledForEdit('admin')}
                   onChange={(e)=>update('public_tip_data', e.target.value)}
                   value={data.public_tip_data ?? ''} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField sx={{width:'100%'}}  label={'Ссылка для клиента'} autoComplete={'nope'}
                   disabled={disabledForEdit('admin')}
                   onChange={(e)=>update('public_tip_link', e.target.value)}
                   value={data.public_tip_link ?? ''} />
      </Grid>
      <Grid item xs={12}>
        <hr/>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField sx={{width:'100%'}}  label={'Пароль'} type={'password'} name={'pwd'}
                   disabled={disabledForEdit()}
                   autoComplete={'new-password'}
                   onChange={(e)=>update('pwd', e.target.value)}
                   // disabled={props.user && u.role != 'sadmin' && u.id != props.user.id}
                   value={data.pwd} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField sx={{width:'100%'}}  label={'Повторите пароль'}
                   disabled={disabledForEdit()}
                   type={'password'} autoComplete={'off'}
                   onChange={(e)=>update('pwd_confirm', e.target.value)}
                   // disabled={props.user && u.role != 'sadmin' && u.id != props.user.id}
                   value={data.pwd_confirm} />
      </Grid>
      <Grid item xs={12}>
        <hr/>
        <Stack spacing={2} direction={'row'}>
          <Button onClick={()=>send()} variant={'contained'}>Сохранить</Button>
          {(props.showCancel ?? true) && <Button onClick={closeHandler}>Отмена</Button>}
        </Stack>
      </Grid>
    </Grid>
  );
}

export const EditUserModal = (props) => {
  return (
    <Dialog open={props.open} maxWidth={'xl'} onClose={props.onClose}>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{props.title ?? 'Редактирование пользователя'}</DialogTitle>
      <DialogContent>
        <UserEdit user={props.user} onClose={props.onClose}
                  onSave={props.onSave ?? props.onClose}
                  adm={props.adm ?? false}
        />
      </DialogContent>
    </Dialog>
  )
}


function UserTableItem({row, updateTable, adm}){
  const [openModal, setOpenModal] = useState(false);
  const {place} = useParams();
  const staff = useSelector(state=>state.staff.staff);
  let staffEntry = staff.find(e=>e.id == row.staff_id) ?? {'name':'-'}
  const [placeData] = useState(row.place ?? {})
  const onSaveHandler = (e) => {
    e?.stopPropagation();
    setOpenModal(false);
    if(typeof updateTable == 'function')
      updateTable();
  }
  const onCloseHandler = (e) => {
    e?.stopPropagation();
    setOpenModal(false);
    if(typeof updateTable == 'function')
      updateTable();
  }
  const handleOpenModal = (e) => {
    e?.stopPropagation();
    setOpenModal(true);
  }
  return (
    <TableRow key={row.id}>
      <TableCell>{row.id}</TableCell>
      <TableCell>
        {row.user.name}
      </TableCell>
      <TableCell >
        {row.user.phone}
      </TableCell>
      <TableCell >
        {row.user.email ?? '-'}
      </TableCell>
      <TableCell >
        {row.role ?? row.user.role}
      </TableCell>
      <TableCell >
        {staffEntry.name ?? '-'}
      </TableCell>
      <TableCell
        sx={{displayPrint:'none'}}>
        <Button onClick={handleOpenModal}>
          Редактировать
        </Button>
        <EditUserModal open={openModal}
                       adm={adm ?? false}
                       user={row}
                       onClose={onCloseHandler}
                       onSave={onSaveHandler}
        />
      </TableCell>
    </TableRow>
  )
}


function UsersTable(props) {

  const {place} = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(props.filter
    ?? {search:'',from:'',to:'',status:'active', page:1})
  const [items, setItems] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [pagination, setPagination] = useState({last_page:1,current_page:1,per_page:25,total:0});
  useEffect(()=>{
    Api.get(`/users/${place}`,
      {...props.filter, page:pagination.current_page ?? 1, perpage:pagination.per_page ?? 25} ?? {})
      .then(data=>data.json())
      .then((result)=>{
        setItems(result?.users?.data ?? []);
        if(result && result.users) {
          let pagination = {...result.users};
          delete pagination['data'];
          pagination.per_page = parseInt(pagination.per_page);
          setPagination(pagination);
        }
      })
  },[place, props.filter, pagination.current_page, pagination.per_page,updateTrigger])
  return (
    <>
      <TablePagination
        component="div"
        count={pagination.total ?? 0}
        page={pagination.current_page - 1}
        onPageChange={(e, page)=>setPagination({...pagination, current_page: page})}
        rowsPerPage={pagination.per_page}
        labelRowsPerPage={'на странице'}

        onRowsPerPageChange={
          (e)=>setPagination({...pagination, per_page: e.target.value, page:1})
        }
      />
      <ResponsiveContainer>
        <Table size={'small'}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Имя</TableCell>
              <TableCell >Телефон</TableCell>
              <TableCell >Email</TableCell>
              <TableCell >Роль</TableCell>
              <TableCell >Назначенный персонал</TableCell>
              <TableCell >Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => (
              <UserTableItem key={row.id} row={row} updateTable={()=>setUpdateTrigger(Math.random())}
                             index={index} adm={props.adm ?? false}
              />
            ))}
          </TableBody>
        </Table>
      </ResponsiveContainer>
    </>

  );
}
/**
 * Список бронирований для ресторана
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function UsersList(props) {
  const {place} = useParams();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [filter, setFilter] = useState({search:'', active:true})
  const [openNewModal, setOpenNewModal] = useState(false);
  const update = (field, value) =>{
    setFilter({...filter, [field]:value});
  }
  const setToday = () => {
    setFilter({...filter, from:Utils.getTime(), to:Utils.getTime()});
  }
  return (
    <div>
      <EditUserModal
        open={openNewModal} adm={props.adm ?? false}
        onClose={()=>setOpenNewModal(false)}
        onSave={()=>{setOpenNewModal(false);setFilter({...filter})}}
      />
      <Box my={2}>
        <Stack spacing={2} direction={'row'}>
          <Typography variant={'h5'}>Пользователи</Typography>
        </Stack>
      </Box>
      <Box my={2}>
        <Stack spacing={2} direction={'row'}>
          <Button variant={'contained'} color={'success'}
                  onClick={()=>{setOpenNewModal(true)}}>Добавить</Button>
          {props.adm && <FormControlLabel control={<Switch
            checked={filter.active}
            onChange={(e)=>update('active', e.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label={'Только открытые'} />}
        </Stack>

      </Box>

      <UsersTable filter={filter} adm={props.adm ?? false} />
    </div>
  );
}
function AdmUsersPage(props) {

  document.title = 'Пользователи';
  return (
    // <>asdasdasd</>
    <UsersList adm={true} />
  );
}

export default AdmUsersPage;