import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../../api";
import LoadingBlock from "../LoadingBlock";
import _ from "lodash";
import PageNotExistsPage from "../../../Pages/PageNotExistsPage";
import ImageHeader from "../ImageHeader";
import {Container, Grid} from "@mui/material";
import MainBlock from "../UnitPage/MainBlock";
import InfoBlock from "../UnitPage/InfoBlock";
import Utils from "../../../utils";

function UnitBlock(props) {
  let u = props.goods;
  const tags = u.tags ?? [];
  document.title = u.name;

  return (
    <>
      <ImageHeader maxHeight={props.maxHeight ?? '350px'} maxWidth={'520px'}
         cover noMargin image={Api.fileUrl(u.image ? u.image : Utils.goodsDefaultImage()) } />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MainBlock unit={u} tags={tags} noNav />
          </Grid>
          <Grid item xs={12}>
            <InfoBlock unit={u}/>
          </Grid>
        </Grid>
      </Container>
    </>


  );
}

export default UnitBlock;