import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Container, Grid, Paper, Skeleton, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import _ from "lodash";
import CategoryLinkCard from "../CategoryLinkCard";
import Api from "../../../api";
import LoadingPage from "../../../Pages/LoadingPage";
import LoadingBlock from "../LoadingBlock";
import {useSelector} from "react-redux";
import Utils from "../../../utils";

function CategoryBlock({ }) {
  const {place, category} = useParams();

  const [categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cat, setCat] = useState([]);


  const currentPlace = useSelector(state=> state.place)
  const defaultStyle = Api.processStyle(currentPlace.style, 'categoryBlock');

  const slider = (defaultStyle?.type ?? 'slider') === 'slider';
  // console.log(defaultStyle, slider);


  useEffect(()=>{
    setLoading(true);
    Api.post('/categories', {place:place, category:category,onlyactive:true})
      .then(res => res.ok ? res.json() : null)
      .then((data) => setCat(data))
      .catch()
      .finally(()=>  {setLoading(false)})
  }, [place, category])

  if(loading)
    return (
      <Paper sx={{...{borderRadius: '10px', position:'relative',
        zIndex:50, transform: slider ? "translateY(-20px)" : ''}, ...defaultStyle}}>
        {/*<LoadingBlock />*/}
        <Box sx={{padding:'0.5rem'}}>
          <Typography fontWeight={'bold'} variant={"h5"}  mb={0} textAlign={'center'}>
            Наше меню {currentPlace.place.name}
          </Typography>
        </Box>
        <Box display={'flex'} gap={1} width={'100%'} p={2} sx={{overflowX:'scroll'}} flexWrap={'nowrap'}>
          <Skeleton variant="rounded" width={130} height={150}  />
          <Skeleton variant="rounded" width={130} height={150}  />
        </Box>
      </Paper>);
  return (
    <Paper sx={{...{borderRadius: '10px', position:'relative',
      zIndex:50, transform: slider ? "translateY(-20px)" : '', background:'#fff'}, ...defaultStyle}}>
      {cat.current && <Box sx={{padding:'0.5rem'}}>
          <Typography fontWeight={'bold'} variant={"h5"} mb={1}
                      display={'flex'} alignItems={'center'} gap={2}>
            <Link to={`/p/${place}/menu` + (cat.current.parent_id > 0 ? "/" + cat.current.parent_id : "")}
                  style={{display:'flex', alignItems:"center", color:defaultStyle.secondaryColor}}><ArrowBackIcon /></Link>
            {cat.current.name}
          </Typography>
          <Typography color={defaultStyle.secondaryColor} mb={1}
              // dangerouslySetInnerHTML={{__html:Utils.processText(cat.current.description)}}
          >
              {Utils.processLinkedText(cat.current.description)}
          </Typography>
      </Box>}
      {!cat.current && <Container maxWidth="sm">
        <Box sx={{padding:'0.5rem'}}>
          <Typography color={defaultStyle.color} fontWeight={'bold'} variant={"h5"} textAlign={'center'}>
            Наше меню
          </Typography>
        </Box>
      </Container>}

      {cat.categories && cat.categories.length > 0 && slider &&
        <Box display={'flex'} width={'100%'} gap={1} p={2} sx={{overflowX:'scroll'}} flexWrap={'nowrap'}>
          {cat.categories.map((c, i)=> <CategoryLinkCard category={c} key={i} />)}
        </Box>
      }

      {cat.categories && cat.categories.length > 0 && !slider &&

        <Grid container spacing={1} padding={1}>
          {cat.categories.map((c, i)=>
            <Grid item xs={6} sm={3} md={2} key={i} display={'flex'} justifyContent={'center'}>
              <CategoryLinkCard category={c} width={'100%'} />
            </Grid>)}
        </Grid>
      }

    </Paper>
  );
}

export default CategoryBlock;