import React from 'react';
import {Grid, Paper, Typography} from "@mui/material";

function IndexBlockList({place}) {
  return (
    <Grid container spacing={2} my={2}>
      <Grid item xs={12}>
        <Paper sx={{padding:"0.5rem"}}>
          <Typography fontWeight={'bold'} variant={"h6"} textAlign={'center'} mb={2}>
            Здесь будут новости и акции
          </Typography>
        </Paper>
      </Grid>

    </Grid>
  );
}

export default IndexBlockList;