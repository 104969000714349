import React from 'react';
import Image from "mui-image";
import {Box, Button, ButtonGroup} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Api from "../../api";

function ImageSelectBlock(props) {
  const [selectedImage, setSelectedImage] = React.useState(props.selected);
  const getImage = () => {
    return selectedImage ? URL.createObjectURL(selectedImage) : Api.fileUrl(props.image);
  }
  const setImage = (e) => {
    setSelectedImage(e.target.files[0]);
    if(props.setImage)
      props.setImage(e.target.files[0]);
  }
  return (
    <Box>
      {(props.image || selectedImage) &&
        <Image bgColor={props.bgColor ?? 'white'} src={getImage()} height={'150px'} fit={'cover'} duration={0} />}
      <ButtonGroup variant="contained" fullWidth aria-label="outlined primary button group">
        <Button variant="contained" fullWidth component="label">
          Выбрать фотографию
          <input hidden accept="image/*" type="file" name='photo'
                 onChange={setImage}
          />
        </Button>
        {selectedImage &&
          <Button variant="outlined" color="error" component="label" onClick={ () => setSelectedImage(null)}>
            <HighlightOffIcon/>
          </Button>}
      </ButtonGroup>
    </Box>
  );
}

export default ImageSelectBlock;