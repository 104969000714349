import React, {useState} from 'react';
import {Box, Chip, Grid, Paper, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Link, useParams} from "react-router-dom";
import TagChip from "../TagChip";
import Api from "../../../api";
import Image from "mui-image";
import {useSelector} from "react-redux";
import GoodsCardOrderControls from "../Goods/GoodsCardOrderControls";

function MainBlock({unit, tags, category, noNav = false, noTrans = false}) {

  const [todoList, setTodoList] = useState([
    {text:"item1", completed:false}
  ])

  let {place, style} = useSelector(state => state.place);
  let individualStyle = Api.processStyle(style, 'goods');
  return (

    <Paper sx={{...{borderRadius: '10px', transform: noTrans ? "translateY(-20px)" : 'unset', padding:'0.75rem'},
        ...individualStyle}}>
      <Grid container spacing={3}>
        {/*<Grid item xs={12} sm={6} md={4}>*/}
        {/*  <Image fit={'cover'} height={"280px"}*/}
        {/*         style={{borderRadius: "6px"}}*/}
        {/*         src={Api.fileUrl(unit.image)} duration={0} />*/}
        {/*</Grid>*/}

        <Grid item xs={12}>
            <Box marginBottom={'0.5rem'} display={'flex'} gap={1} alignItems={'center'}>
              {!noNav && <Link to={`/p/${place.code}/menu/${category ? category.id : ''}`}
                    style={{display:'flex', alignItems:"center", color:individualStyle.secondaryColor}}><ArrowBackIcon /></Link>}
              <Typography fontSize={14} fontWeight={'bold'} variant={"caption"}>
                {unit.name}
              </Typography>
            </Box>
            <Box marginBottom={'0.75rem'}>
              <Typography fontSize={12} variant={"subtitle2"} color={individualStyle.secondaryColor}
                          lineHeight={"130%"}>
                {unit.description}
              </Typography>
            </Box>
            <Box marginBottom={'0.75rem'} sx={{display: 'flex', flexWrap:'wrap', columnGap: 1}}>
              {tags.map((i, key) =>
                <TagChip tag={i} key={key}/>
              )}
            </Box>

            <Box marginBottom={'0.5rem'} display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography color={individualStyle.tertiaryColor} fontSize={16}>
                {unit.price}&nbsp;₽ {(unit.unit && unit.unit?.trim() !== 'null')
                    ? " / " + unit.unit?.trim()
                    : ''}
              </Typography>
              {(unit.allow_order > 0) && <GoodsCardOrderControls goods={unit} />}
            </Box>

        </Grid>
      </Grid>


    </Paper>
  );
}

export default MainBlock;