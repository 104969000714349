import React, {useState} from 'react';
import {Grid} from "@mui/material";
import GoodsCardVertical from "../GoodsCardVertical";
import QrUnitPage from "../../../Pages/QrPages/QrUnitPage";
import UnitBlock from "./UnitBlock";

function LargeLayout(props) {
  const [selectedGoods, setSelectedGoods] = useState(props.goods[0] ?? null);
  return (
    <Grid container spacing={1}>
      <Grid item lg={4}>
        <Grid container spacing={1}>
          {props.goods.map((i, index)=>
            <Grid item lg={6} key={index}>
              <GoodsCardVertical goods={i} onClick={()=>{setSelectedGoods(props.goods[index])}}/>
            </Grid>
          )}
        </Grid>
      </Grid>

      {(selectedGoods) && <Grid item lg={8}>
        <UnitBlock goods={selectedGoods} maxHeight={'500px'} />
      </Grid>}
    </Grid>
  );
}

export default LargeLayout;