import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Pagination,
  Paper,
  Switch,
  Table, TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import AdmUnitModal from "../../components/Adm/AdmUnitModal";
import AdmUnitTable from "../../components/Adm/AdmUnitTable";
import LoadingPage from "../LoadingPage";
import AdmUnitRow from "../../components/Adm/AdmUnitRow";
// import AdmComponentModal from "../../components/Adm/AdmComponentModal";

function AdmComponentListPage(props) {

  document.title = 'Все позиции';

  const [cat, setCat] = useState({});
  const [search, setSearch] = useState('');
  const pagination = cat.pagination;
  const [loading, setLoading] = useState(true);

  const {place, id} = useParams();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };


  const reloadPage = () =>{
    setLoading(true);
    Api.post('/adm/component/get',
      {place:place,page:page, search:search,})
      .then(res => res.ok ? res.json() : null)
      .then((data) => setCat(data))
      .catch()
      .finally(()=> setLoading(false))
  };

  const handleSearch = (e)=>{
    if(e.key=='Enter' || e.keyCode == 13){
      setSearch(e.target.value);
      reloadPage();
    }
  }

  useEffect(()=>{
    reloadPage();
  }, [place, id, page])
  const goBack = () => navigate(-1);

  if(loading)
    return (<LoadingPage />);
  const updateRow = (index, newValue)=>{
    let goods = cat.goods;
    goods[index] = newValue;
    cat.goods = goods;
    setCat({...cat});
  }

  const getDefaultForNew = ()=> {
    return {
      id:0,
      name:'Новый топпинг',
      description:"Описание нового топпинга",
      image:'/components/default.png',
    }
  }


  return (
    <>
      <Box py={'0.5rem'} display={'flex'} gap={'10px'} justifyContent={'space-between'}>
        <Typography variant={'h5'}>
          Топпинги
        </Typography>
        <Box>

          <TextField variant={'outlined'} label='Поиск' type={'search'} value={search}
                     size="small"
                     onChange={(e)=>{setSearch(e.target.value)}}
              onKeyDown={handleSearch}
          />
          <Button variant={'contained'} onClick={reloadPage}>Поиск</Button>
        </Box>

      </Box>
      <Box py={'0.5rem'} display={'flex'} gap={'10px'}>
        <Button onClick={()=>navigate(-1)} variant={"outlined"}>Назад</Button>
        {/*<AdmUnitModal unit={getDefaultForNew()} color={'success'}*/}
        {/*                  place={place} text={<AddBoxIcon />} variant={'contained'} newitem reload={reloadPage}*/}
        {/*/>*/}
      </Box>

      {pagination && pagination.totalPages > 1 &&
        <Pagination count={pagination.totalPages} page={page}
                    onChange={handlePageChange}  variant="outlined" shape="rounded"
        />
      }

      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Описание</TableCell>
              <TableCell>Изображение</TableCell>
              <TableCell>Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cat.items.map((row, index) => (
              <TableRow key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>
                  {row.description}
                </TableCell>
                <TableCell>
                  <img src={Api.fileUrl(row.image ? row.image : "/components/default.png")}
                       style={{maxWidth:'60px', maxHeight:"50px", objectFit: 'cover'}}
                       alt={row.name}
                  />
                </TableCell>
                <TableCell>
                  {/*<AdmComponentModal index={props.index} update={props.updateRow} unit={row} text={""} place={place}/>*/}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination && pagination.totalPages > 1 &&
        <Pagination count={pagination.totalPages} page={page}
                    onChange={handlePageChange}  variant="outlined" shape="rounded"
        />
      }
    </>
  );
}

export default AdmComponentListPage;