import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper, Stack,
  Typography
} from "@mui/material";
import Api from "../../api";
import _ from "lodash";
import PageNotExistsPage from "../PageNotExistsPage";
import ImageHeader from "../../components/Qr/ImageHeader";
import LoadingBlock from "../../components/Qr/LoadingBlock";
import {useDispatch, useSelector} from "react-redux";
import QrReservationOrderBlock from "../../components/Hostess/QrReservationOrderBlock";
import {snackShow} from "../../store/snackbarSlice";
import Divider from '@mui/material/Divider';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QrReservationReviewBlock from "../../components/Hostess/QrReservationReviewBlock";
import {setCode} from "../../store/codeSlice";
import QrReservationWaiterBlock from "../../components/Hostess/QrReservationWaiterBlock";

function QrReservationNav(props){
  const navigate = useNavigate();
  const {place} = useParams();
  return (
    <Container maxWidth="sm">
      <Stack direction={'row'} spacing={2} justifyContent={'space-evenly'}>
        <Button startIcon={<RestaurantMenuIcon />} color={'inherit'}
                onClick={()=>navigate(`/p/${place}`)}>
          О нас
        </Button>
        <Divider />
        <Button startIcon={<MenuBookIcon />} color={'inherit'}
                onClick={()=>navigate(`/p/${place}/menu`)}>
          В меню
        </Button>
      </Stack>
    </Container>
  )
}
function QrReservationHeader({code}){
  return (
    <>
      {/*<Box>*/}
      {/*  <Typography fontWeight={'bold'} fontSize={20} variant={"h5"} textAlign={'center'}>*/}
      {/*    Ваш заказ*/}
      {/*  </Typography>*/}
      {/*</Box>*/}
      <Box>
        Статус: {code.closed_at
        ? <Typography component={'span'} color={'error.main'}>Закрыт</Typography>
        : <Typography component={'span'} color={'success.main'}>Открыт</Typography>}
      </Box>
    </>
  )
}
function QrReservationOrders({code}){
  const navigate = useNavigate();
  const {place} = useParams();
  return (
    <>
      {code.orders?.map((o, index)=>(
        <QrReservationOrderBlock order={o} data={o.data} key={index} />
      ))}
      {(!code.orders || code.orders.length == 0) && <Box my={1} textAlign={'center'}>
        Заказов не привязано
      </Box>}
    </>
  )
}


function QrReservation(props) {
  document.title = 'Бронирование';
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {place, code} = useParams();
  const [searchParams] = useSearchParams();
  let {place:pls, style} = useSelector(state=> state.place)
  const defaultStyle = Api.processStyle(style, 'textBlock');

  const updateData = () => {
    setLoading(true);
    Api.get(`/codes/${place}/${code}`)
      .then(res => res.ok ? res.json() : null)
      .then((data) => {
        if(!data.item){
          console.log(data);
          return;
        }
        setData(data.item);
        localStorage.setItem('qr_code', data.item.code);
        localStorage.setItem('qr_sum', data.sum);
        dispatch(setCode({code:data.item, sum:data.sum}))
        if(searchParams.has('r'))
          navigate(`/p/${place}`)
        // console.log(data.item);
        // console.log('data reloaded');
      })
      .catch()
      .finally(()=>  setLoading(false))
  }
  useEffect(()=>{
    updateData();
    const interval = setInterval(() => updateData(), 60000);
    return () => {clearInterval(interval);};
  }, [place, code])


  if(!loading && (!data || _.isEmpty(data)))
    return (<PageNotExistsPage />);

  return (
    <>
      <ImageHeader image={Api.fileUrl(pls.image) }  cover noMargin  height={"110px"}/>
      <Container>
        <Paper sx={{borderRadius: '10px',position:'relative',transform:"translateY(-20px)",
          minHeight:'400px',
          zIndex:50, py:"1rem", ...defaultStyle}}>
          {loading && <LoadingBlock />}
          <QrReservationNav />
          {data && !_.isEmpty(data) && <Container maxWidth="sm" sx={{marginBottom:1}}>
            <QrReservationHeader code={data} />
            <QrReservationWaiterBlock code={data} />
            <QrReservationOrders code={data} />
            <QrReservationReviewBlock code={data} />
          </Container>}
        </Paper>

      </Container>
    </>


  );
}

export default QrReservation;