import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import AdmTagElement from "./AdmTagElement";
import TagChip from "../Qr/TagChip";
import CloseIcon from "@mui/icons-material/Close";
import {useParams} from "react-router-dom";
import Api from "../../api";

function AdmTagElementController({tags, setTags}) {

  // console.log("Given tags", tags)
  const [tagList, setTagList] = useState(tags ?? []);
  const [key, setKey] = useState(Math.round(Math.random() * 100000));
  const [tagsForAdd, setTagsForAdd] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const {place} = useParams();
  const deleteTag = (id)=>{
    let newTagList = tagList.filter((item)=>{
      return item.id != id;
    })
    setTagList(newTagList);
    setTags(newTagList);
  }
  const addTag = (tag)=>{
    let newTagList = [...tagList, tag];
    setTagList(newTagList);
    setTags(newTagList);
  }

  const loadNewTags = () => {
    Api.post('/adm/tags/get',{place:place})
      .then(res => res.json())
      .then(data => {
        let loadedTags = data.tags.filter((item)=>{
          for (var i = 0; i < tagList.length; i++){
            if (tagList[i].id == item.id)
              return false;
          }
          return true;
        })
        // console.log('loaded', data.tags, loadedTags);
        setTagsForAdd(loadedTags);
        if(loadedTags.length > 0)
          setSelectedTag(loadedTags[0]);
      })
  }

  useEffect(()=>{
    loadNewTags()
  }, [tagList])
  // console.log('taglist', tagList);

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} px={2} mt={2} fontSize={18} fontWeight={'bold'}>
        Теги
      </Grid>
      <Grid item xs={12} display={'flex'} gap={2}>
        {tagList.map((item, index) =>
          (
            <Box key={index * key} p={3} position={'relative'} >
              <IconButton aria-label="close" onClick={()=>{deleteTag(item.id)}} size="small"
                sx={{ position: 'absolute', right: 0, top: 0, zIndex:20,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon  size="small" />
              </IconButton>
              <TagChip tag={item} />
            </Box>

          )
        )}

        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
          <InputLabel id="demo-select-small">Добавить тег</InputLabel>
          <Select sx={{minWidth: 150 }}
            labelId="demo-select-small"
            id="demo-select-small"
            value={''}
            label="Age"
            onChange={(e) => {addTag (e.target.value);}}
          >
            <MenuItem value={''} disabled={true}>== Добавить тег ==</MenuItem>
            {tagsForAdd.map((i, index) => (
              <MenuItem key={index} value={i}>{i.name}:&nbsp;<TagChip tag={i} /></MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>

  );
}

export default AdmTagElementController;