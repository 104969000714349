import React from 'react';
import {Box, Table, Typography} from "@mui/material";

function ResponsiveContainer(props) {
  return (

      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          {props.children}
        </Box>
      </Box>
  );
}

export default ResponsiveContainer;