import React, {useEffect, useState, Suspense, useRef} from 'react';
import {Await, defer, Link, useLoaderData, useNavigate, useParams} from "react-router-dom";
import {Backdrop, Box, CircularProgress, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import Api from "../../api";
import ImageHeader from "../../components/Qr/ImageHeader";
import {useSelector} from "react-redux";
import CartBlock from "../../components/Qr/Cart/CartBlock";
import GoodsCardVertical from "../../components/Qr/GoodsCardVertical";
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/autoplay';
import UnitBlock from "../../components/Qr/MenuPage/UnitBlock";

function QrCarousel(props) {
  document.title = 'Карусель';
  const goods = useLoaderData();
  let {place, style} = useSelector(state => state.place);
  let individualStyle = Api.processStyle(style, 'carousel');
  let blockStyle = Api.processStyle(style, 'carouselText');
  const[currentIndex, setCurrentIndex] = useState(0)
  const timer = useRef(null);
  useEffect(() => {
    timer.current = setInterval(() => {
      setCurrentIndex(current =>(current >= (goods?.length - 1)) ? 0 : current + 1)
      console.log(goods[currentIndex] ?? []);
    }, 15000);
    return () => {
      clearInterval(timer.current);
    };
  }, []);
  return (
    <Box display={'flex'} height={'100%'} alignItems={'center'} minHeight={"100vh"}
      sx={{...individualStyle}}
    >
      {goods[currentIndex] && <Grid container spacing={2}>
        <Grid item xs={12}>
          <Swiper
            modules={[Autoplay]}
            spaceBetween={20}
            slidesPerView={2}
            onSlideChange={(i) => {}}
            autoplay={{delay:15000}}
            loop={true}
          >
            {goods && goods.map(g =>
              <SwiperSlide key={g.id} style={{height:"100%"}}>
                <Box py={4} px={2}>
                  <Box position={'relative'} borderRadius={"12px"} overflow={'hidden'}>
                    <ImageHeader style={{height:'90vh', position:'relative', zIndex: 20}} duration={200}
                                 cover noMargin image={Api.fileUrl(g.image) } />
                    <Box display={"flex"} position={'absolute'} top={0} left={0} width={'100%'} height={'100%'}
                         zIndex={30} sx={{background:"linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%)"}}></Box>
                    <Box position={'absolute'} bottom={0} left={0} width={'100%'} zIndex={50}>
                      <Typography variant={'h3'} mb={2} textAlign={'center'} color={blockStyle.color}>
                        {g.name}
                      </Typography>
                      {/*<Typography variant={'h6'} mb={2}>*/}
                      {/*  {goods[currentIndex].description}*/}
                      {/*</Typography>*/}
                      {/*<Typography variant={'h6'} mb={2} color={blockStyle.secondaryColor} textAlign={'center'}>*/}
                      {/*  Состав: {g.contents}*/}
                      {/*</Typography>*/}
                      <Typography variant={'h3'} fontWeight={'bold'} mb={2} color={blockStyle.tertiaryColor} textAlign={'center'}>
                         {g.price} ₽
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>)}
          </Swiper>
        </Grid>
        {/*<Grid item xs={12} lg={6}>*/}
        {/*  */}
        {/*</Grid>*/}
        {/*<Grid item xs={12} lg={6}>*/}
        {/*  <Box display={'flex'} width={'100%'} height={'100%'} alignItems={'center'}>*/}
        {/*    <Paper sx={{px:2, py:5, width:'100%',...blockStyle}}>*/}
        {/*      <Typography variant={'h3'} mb={2} textAlign={'center'} color={blockStyle.color}>*/}
        {/*        {goods[currentIndex].name}*/}
        {/*      </Typography>*/}
        {/*      /!*<Typography variant={'h6'} mb={2}>*!/*/}
        {/*      /!*  {goods[currentIndex].description}*!/*/}
        {/*      /!*</Typography>*!/*/}
        {/*      /!*<Typography variant={'h6'} mb={2} color={blockStyle.secondaryColor} textAlign={'center'}>*!/*/}
        {/*      /!*  Состав: {goods[currentIndex].contents}*!/*/}
        {/*      /!*</Typography>*!/*/}
        {/*      <Typography variant={'h3'} mb={2} color={blockStyle.tertiaryColor} textAlign={'center'}>*/}
        {/*         {goods[currentIndex].price} ₽*/}
        {/*      </Typography>*/}
        {/*    </Paper>*/}
        {/*  </Box>*/}

        {/*</Grid>*/}
      </Grid>}
    </Box>

  );
}

async function getGoods(params){
  const res = await Api.get(`/goods/${params.place}`,{only_index:1});
  const data = await res.json();
  // console.log(data);
  return data?.goods?.data ?? [];
}
const qrCarouselLoader = async ({request, params}) => {
  const res = await Api.get(`/goods/${params.place}`,{only_index:1});
  const data = await res.json();
  // console.log(data);
  return data?.goods?.data ?? [];
  // return defer({
  //   goods:getGoods(params)
  // })
}

export default QrCarousel;
export {qrCarouselLoader};