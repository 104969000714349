import React, {useEffect, useState} from 'react';
import {Outlet, useParams} from "react-router-dom";
import Header from "../Qr/Header";
import Footer from "../Qr/Footer";
import {Box, createTheme, Grid, ThemeProvider} from "@mui/material";
import Api from "../../api";
import Snack from "../ui/Snack";
import {useDispatch, useSelector} from "react-redux";
import {fetchPlace} from "../../store/placeSlice";
import LoadingPage from "../../Pages/LoadingPage";
import PageNotExistsPage from "../../Pages/PageNotExistsPage";
import LoadingBlock from "../Qr/LoadingBlock";
import {fetchCode} from "../../store/codeSlice";


const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: [
        'Jost',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  },
})

function QrLayout(props) {
  const turnOffSandbox = () => {
    Api.setSandbox(false);
  }
  const dispatch = useDispatch();
  const {place} = useParams();
  const [sandbox, setSandbox] = useState(false)

  let currentPlace = useSelector(state=> state.place)
  let codeState = useSelector(state=> state.code)
  useEffect(()=>{
    dispatch(fetchCode(place));
    dispatch(fetchPlace({place:place}))
  },[place])


  if(currentPlace.loading || codeState.loading)
    return( <LoadingPage />)
  if(currentPlace.place){
    let pdata = currentPlace.place.metrika;
    // console.log('place', pdata, currentPlace.place);
    if(pdata)
      document.getElementById('metrika_script').innerHTML = pdata;
  }
  return (

    <ThemeProvider theme={theme}>
      {Api.isSandbox() && <Box position={'fixed'} top={0} left={0} width={'100%'} zIndex={500}
         fontSize={12} bgcolor={'magenta'} textAlign={'center'} color={'white'}>
        Sandbox mode!&nbsp;&nbsp;
        <button style={{color:'lightgrey'}} onClick={turnOffSandbox}>Отключить</button>
      </Box>}

      {!currentPlace.place && <PageNotExistsPage />}
      {currentPlace.place &&
        <Grid container sx={{}}>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12} sx={{pb:5, ...Api.processStyle(currentPlace.style, 'main'),
              ...{minHeight: "100vh"}}}>
            <main>
              <Outlet />
            </main>
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>}
      <Snack />
    </ThemeProvider>
  );
}

export default QrLayout;