import {styled} from "@mui/material/styles";
import {Typography} from "@mui/material";
import React from "react";

export const Text =(props) => {
  return (<Typography fontSize={{lg: 20, md: 18, sm: 16, xs: 13}} fontFamily={'Montserrat'} {...props}>{props.children}</Typography>)
}
export const  Logo = (props) =>{
  return (<Typography mb={2} fontSize={{lg: 36, md: 26, sm: 22, xs: 16}} fontFamily={'Montserrat'}
                      fontWeight={'bold'} {...props}>{props.children}</Typography>)
}
export const LogoHeader = styled('div')(({ theme }) => ({
  marginBottom:"30px",
  [theme.breakpoints.down('sm')]: {fontSize:"20px", textAlign:'center'},
  [theme.breakpoints.up('sm')]: {fontSize:"30px"},
  [theme.breakpoints.up('lg')]: {fontSize:"38px"},
  [theme.breakpoints.up('xl')]: {fontSize:"46px"},
}));
export const LogoText = styled('div')(({ theme }) => ({
  marginBottom:"30px",
  [theme.breakpoints.down('sm')]: {fontSize:"13px", textAlign:'center'},
  [theme.breakpoints.up('sm')]: {fontSize:"16px"},
  [theme.breakpoints.up('lg')]: {fontSize:"20px"},
  [theme.breakpoints.up('xl')]: {fontSize:"24px"},
}));
export const LogoImage = styled('div')(({ theme }) => ({
  maxWidth:'300px',
  [theme.breakpoints.down('lg')]: {display:'none'},
}));

export const LogoContent = styled('div')(({ theme }) => ({
  width:"100%", minHeight:"100vh", display:'flex', justifyContent:"center",
  paddingTop: "150px",
  fontFamily:"Aubrey Pro", color:"white",
  [theme.breakpoints.up('sm')]: {
    paddingTop: "150px",
  },
  [theme.breakpoints.down('lg')]: {
    background: "linear-gradient(90deg, rgba(0,0,0,0.37475500454087884) 0%, rgba(0,0,0,0.4253851198682598) 53%, rgba(0,0,0,0.38876060678177526) 100%)",
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: "210px",
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: "220px",
  },
}));


export const BlockHeader = styled('div')(({ theme }) => ({
  marginBottom:"30px",
  fontWeight:'bold',
  textAlign:'center',
  [theme.breakpoints.down('sm')]: {fontSize:"20px", textAlign:'center'},
  [theme.breakpoints.up('sm')]: {fontSize:"30px"},
  [theme.breakpoints.up('lg')]: {fontSize:"38px"},
  [theme.breakpoints.up('xl')]: {fontSize:"46px"},
}));
export const BlockText = styled('div')(({ theme }) => ({
  marginBottom:"30px",
  [theme.breakpoints.down('sm')]: {fontSize:"13px", textAlign:'center'},
  [theme.breakpoints.up('sm')]: {fontSize:"16px"},
  [theme.breakpoints.up('lg')]: {fontSize:"20px"},
  [theme.breakpoints.up('xl')]: {fontSize:"24px"},
}));