import axios from "axios";
import {useDispatch} from "react-redux";
import {snackShow} from "./store/snackbarSlice";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from "dayjs";
import moment from "moment";
import {Link} from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault();

const Utils = {

  getDate:(time= new Date()) =>{
    return moment(time).format('YYYY-MM-DD')
  },
  getTime:(time= new Date()) =>{
    return moment(time).format('YYYY-MM-DD HH:mm')
  },
  getSlicedText:(text, maxLength = 40) =>{
    if(!(typeof text == 'string'))
      return text;
    if(!(typeof maxLength == 'number'))
      maxLength = 40;
    return text?.slice(0, maxLength) + (text?.length > maxLength ? '...' : '')
  },
  goodsDefaultImage:()=>{
    return "/goods/default.png"
  },

  processText:(text)=>{
    text = text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
      .replace(/\r?\n/g, '<br>')
      .replace(/\s\s/g,'&nbsp;');

    return text;
  },
  processLinkedText:(text)=>{
    let textArray = [];
    let match;
    while (match = text.match(/\[\[(!?)([\w\d\/:\-_.&=@%#+?]+)\|([^\]]+)\]\]/)){
      let processedText = <span dangerouslySetInnerHTML={{__html:Utils.processText(text.substring(0, match.index))}}></span>
      textArray.push(processedText)

      let target = match[1] == '!' ? '_blank' : '_self'
      if(match[2].startsWith('http'))
        textArray.push(<a href={match[2].trim()} target={target} style={{color:'currentcolor', textDecoration:'underline'}} >{match[3].trim()}</a>);
      else
        textArray.push(<Link to={match[2].trim()} target={target} style={{color:'currentcolor', textDecoration:'underline'}} >{match[3].trim()}</Link>);

      text = text.substring(match.index + match[0].length)
    }
    textArray.push(<span dangerouslySetInnerHTML={{__html: Utils.processText(text)}}></span>);
    return textArray.map((i, index) => <span key={index}>{i}</span>);
  }
}


export default Utils;