import {createSlice} from '@reduxjs/toolkit'
import Api from "../api";

const styleSlice = createSlice({
  name:'sandboxStyle',
  initialState: {
    style: Api.getDefaultStyle(),
    isSandbox: false,
  },
  reducers:{
    toggleTest(state, action){
      state.isSandbox = !state.isSandbox;
    },
    updateStyle(state, action){
      state.style = {...state.style, ...action.payload}
      if(!state.style.goodsList)
        state.style.goodsList = [];
      if(!state.style.categoryList)
        state.style.categoryList = [];
      Api.setSandboxStyle(state.style);
      // console.log(state.style);
    },
    loadStyle(state, action){
      state.style = action.payload;
    },
  }
})



export const {updateStyle, toggleTest, loadStyle} = styleSlice.actions;
export default styleSlice.reducer;