import React from 'react';
import {Box, Button, Container, Grid} from "@mui/material";
import logoBg from './images/logo-bg1.webp';
import mockup from './images/mockup1.webp';
import { styled } from '@mui/material/styles';
import Image from "mui-image";
import styles from "./IndexLogo.module.css";
import {LogoContent, LogoHeader, LogoImage, LogoText} from "./Responsive";

import {Logo, Text} from "./Responsive";



function IndexLogo(props) {

  const scrollToForm  = () => {
    var element = document.getElementById("form");
    element.scrollIntoView({behavior:"smooth", inline:"nearest"});
  }

  return (
    <Box sx={{background: `url("${logoBg}") center center `, backgroundSize:"cover", backgroundPosition:"right 0 !important"}}>
      <LogoContent>
        <Container maxWidth={"xl"}>
          <Grid container sx={{width:'100%'}}>
            <Grid item lg={6}>
              <LogoHeader>Удобное WEB-меню<br/> для Вашего ресторана</LogoHeader>
              <LogoText>Используйте современное решение для старых традиций</LogoText>
              <Box display={'flex'} justifyContent={'center'} gap={2}>
                <button className={styles.lite_btn_brown} onClick={scrollToForm} >Подключить меню</button>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <LogoImage>
                <Image src={mockup} duration={0} width={"100%"} />
              </LogoImage>
            </Grid>
          </Grid>

        </Container>
      </LogoContent>
    </Box>
  );
}

export default IndexLogo;