import React from 'react';
import {Backdrop, Box, CircularProgress, Paper} from "@mui/material";

function LoadingBlock(props) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>)
}

export default LoadingBlock;