import React, {useEffect, useState} from 'react';
import {
  Box, Button, Dialog, DialogContent, DialogTitle,
  FormControl,
  InputLabel,
  MenuItem, Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TextField, Typography, useMediaQuery, useTheme
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import ReservationsTable, {EditReservationModal} from "../../components/Hostess/ReservationsTable";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Utils from "../../utils";
import ReservationEdit from "../../components/Hostess/ReservationEdit";

/**
 * Список бронирований для ресторана
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function HostessReservationList(props) {
  document.title = 'Список бронирований - Hostess Lite-Menu';
  const {place} = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({search:'',
    from:Utils.getDate(),to:false,
    status:'active'})
  const update = (field, value) =>{
    setFilter({...filter, [field]:value});
  }
  const setToday = () => {
    setFilter({...filter, from:Utils.getDate(), to:Utils.getDate()});
  }
  const theme = useTheme();
  const [openNewModal, setOpenNewModal] = useState(false);
  const lMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <EditReservationModal
        open={openNewModal} adm={props.adm ?? false}
        onClose={()=>setOpenNewModal(false)}
        onSave={()=>{setOpenNewModal(false);setFilter({...filter})}}
      />
      <Box my={2}>
        <Stack spacing={2} direction={'row'}>
          <Typography variant={'h5'}>
            Бронирования на период&nbsp;{filter.from}-{filter.to}
          </Typography>
        </Stack>
      </Box>
      <Box my={2} sx={{displayPrint:'none'}}>
        <Stack spacing={2} direction={lMd ? 'column' : 'row'}>
          <Button variant={'contained'} color={'success'} fullWidth={lMd}
                  onClick={()=>{setOpenNewModal(true)}}>Добавить</Button>
          <Button fullWidth={lMd}
                  onClick={setToday} variant={'outlined'}>Бронирования сегодня</Button>
          <Button variant={'outlined'} color={'info'} fullWidth={lMd}
                  onClick={()=>{window.print()}}>Распечатать</Button>
        </Stack>
      </Box>
      <Stack sx={{displayPrint:'none'}} direction={lMd ? 'column' : 'row'} spacing={2} my={2}>
        <TextField label={'Дата от'}
                   type={'date'}
                   value={filter.from}
                   onChange={(e) => update('from', e.target.value)}
        />
        <TextField label={'Дата по'}
                   type={'date'}
                   value={filter.to}
                   onChange={(e) => update('to', e.target.value)}
        />
        <FormControl variant="outlined" sx={{minWidth:120}}>
          <InputLabel id="select-status-label">Статус</InputLabel>
          <Select
            labelId="select-status-label"
            id="select-status"
            value={filter.status}
            onChange={(e)=>update('status', e.target.value)}
            label="Статус"
          >
            <MenuItem value={0}>Все</MenuItem>
            <MenuItem value={'active'}>Активно</MenuItem>
            {/*<MenuItem value={'in_work'}>В работе</MenuItem>*/}
            {props.adm && <MenuItem value={'cancelled'}>Отменено</MenuItem>}
            {props.adm && <MenuItem value={'completed'}>Выполнено</MenuItem>}
          </Select>
        </FormControl>
      </Stack>

      <ReservationsTable filter={filter} adm={props.adm ?? false} />
    </div>
  );
}

export default HostessReservationList;