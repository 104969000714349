import React, {useState} from 'react';
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {MuiColorInput} from "mui-color-input";
import {useDispatch, useSelector} from "react-redux";
import {updateStyle} from "../../../store/sandboxStyleSlice";
import ImageSelectBlock from "../ImageSelectBlock";

function StyleElement({label, part, id = -1, noborder, nobg, setImage, labels = {}}) {
  const defaultValues = {color:'rgba(0,0,0,0.87)',background:'#f3f3f3',
    borderColor:'lightgray',borderWidth:0};

  const style = useSelector((state) => {
    if(id > 0){
      let list = state.sandboxStyle.style[part];
      return list[id] ?? defaultValues
    }
    return state.sandboxStyle.style[part] ?? defaultValues
  });
  const dispatch = useDispatch();
  const update = (val) => {
    if(id > 0){
      let block = style[part] ?? {};
      block[id] = {...(block[id] ?? defaultValues), ...val};
      dispatch(updateStyle({[part]:block}))
    } else
      dispatch(updateStyle({[part]:{...style, ...val}}))
  }
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6} lg={4} xl={3}>
        {!nobg &&
          <Box>
            <ImageSelectBlock bgColor={style.background} setImage={(img) => setImage(part, img)}
                              image={style.bgImageLink} />
          </Box>}
      </Grid>
      <Grid item sm={12} md={6} lg={8} xl={9}>
        <Box display={"flex"} flexWrap={'wrap'} gap={2}>
          <Box width={'100%'} fontSize={18} fontWeight={'bold'}>
            {label}
          </Box>
          <Box>
            <Typography>{labels.color ?? 'Текст'}</Typography>
            <MuiColorInput value={style.color ?? 'rgba(0,0,0,0.87)'}  size="small" variant="outlined"
                           onChange={(e)=>update({color:e})}/>
          </Box>
          <Box>
            <Typography>{labels.secondaryColor ?? 'Вспомогательный текст 1'}</Typography>
            <MuiColorInput value={style.secondaryColor ?? 'rgba(0,0,0,0.5)'}  size="small" variant="outlined"
                           onChange={(e)=>update({secondaryColor:e})}/>
          </Box>
          <Box>
            <Typography>{labels.tertiaryColor ?? 'Вспомогательный текст 2'}</Typography>
            <MuiColorInput value={style.tertiaryColor ?? '#9d854f'}  size="small" variant="outlined"
                           onChange={(e)=>update({tertiaryColor:e})}/>
          </Box>
          <Box>
            <Typography>{labels.background ?? 'Фон'}</Typography>
            <MuiColorInput value={style.background ?? '#f3f3f3'}  size="small" variant="outlined"
                           onChange={(e)=>update({background:e})}/>
          </Box>
          {!noborder &&
            <Box>
              <Typography>{labels.border ?? 'Border'}</Typography>
              <MuiColorInput value={style.borderColor ?? 'lightgray'}
                             size="small" variant="outlined"
                             onChange={(e)=>update({borderColor:e})}/>
              <TextField type={'number'} size="small" variant="outlined"
                         value={parseInt(style.borderWidth ?? 0)}
                         onChange={(e)=>update({borderWidth:e.target.value + 'px', borderStyle:'solid'})} />
            </Box>}

        </Box>
      </Grid>
    </Grid>

  );
}

export default StyleElement;