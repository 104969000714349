import React from 'react';
import {Box, Container, Grid, Typography} from "@mui/material";
import Image from "mui-image";
import ph1 from "./images/page2-fone1.webp";
import ph2 from "./images/page2-fone2.webp";
import ph3 from "./images/page2-fone3.webp";
import {Logo, Text} from "./Responsive";

function IndexAbout(props) {
  return (
    <Container maxWidth={'xl'} sx={{padding:"50px 0"}}>
      <Logo textAlign={'center'}  my={3}>
        Преимущества Lite Menu
      </Logo>
      <Text my={3}>
        Наше меню быстро работает и интуитивно понятное в использовании.
        Благодаря Lite Menu посетители с легкостью найдут блюдо по вкусу и увидят состав.<br/>
        Настройте своё меню за полчаса и запускайте в работу!
      </Text>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box display={'flex'} justifyContent={{xs:"center", md:'flex-start'}} mb={2}>
            <Image src={ph1} duration={0} width={"100%"} style={{maxWidth: '380px', maxHeight: '750px', objectFit: 'contain'}} />
          </Box>
          <Box px={2}>
            <Typography textAlign={'center'} fontSize={{lg: 24, md: 20, sm: 18}} fontFamily={'Montserrat'} mb={3}>
              Главное меню
            </Typography>
            <ul className={'defaultUl'}>
              <li className="description-mocap mb-2">Слайдер всего меню.</li>
              <li className="description-mocap mb-2">Укажите рекомендации.</li>
              <li className="description-mocap mb-2">Добавление цены к <br/>рекомендациям.</li>
              <li className="description-mocap mb-2">Добавьте изображение в <br/>шапку главного меню.</li>
            </ul>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box display={'flex'} justifyContent={{xs:"center", md:'center'}} pt={{md:'70px', xs:2}} mb={2}>
            <Image src={ph2} duration={0} width={"100%"} style={{maxWidth: '380px', maxHeight: '750px', objectFit: 'contain'}} />
          </Box>
          <Box px={2}>
            <Typography textAlign={'center'} fontSize={{lg: 24, md: 20, sm: 18}} fontFamily={'Montserrat'} mb={3}>
              Меню каталога
            </Typography>
            <ul className={'defaultUl'}>
              <li className="description-mocap mb-2">Покажите весь Ваш ассортимент.</li>
              <li className="description-mocap mb-2">Добавьте рекомендации в ассортименте.</li>
              <li className="description-mocap mb-2">Добавьте изображение к каждой категории блюд.</li>
            </ul>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography textAlign={'center'} fontSize={{lg: 38, md: 30, sm: 24, xs: 20}} fontFamily={'Montserrat'}
                      fontWeight={'bold'} display={{xs:"none", md:"block"}}>
            &nbsp;<br/>&nbsp;
          </Typography>
          <Box display={'flex'} justifyContent={{xs:"center", md:'flex-end'}} pt={{md:5, xs:2}} mb={2}>
            <Image src={ph3} duration={0} width={"100%"} style={{maxWidth: '380px', maxHeight: '750px', objectFit: 'contain'}} />
          </Box>
          <Box px={2}>
            <Typography textAlign={'center'} fontSize={{lg: 24, md: 20, sm: 18}} fontFamily={'Montserrat'} mb={3}>
              Меню блюда
            </Typography>
            <div className="title-mocap mb-3"></div>
            <ul className={'defaultUl'}>
              <li className="description-mocap mb-2">Покажите изображение, описание, состав и/или пищевую ценность каждого
                продукта или блюда (белки,
                жиры, углеводы, вес/объем и энергетическую ценность), а также - по желанию - диетические рекомендации и
                любую другую контекстную информацию.</li>
            </ul>
          </Box>
        </Grid>
      </Grid>

    </Container>
  );
}

export default IndexAbout;