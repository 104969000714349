import React, {useRef, useState} from 'react';
import {Box, Button, Container, Grid, Stack, TextField, Typography} from "@mui/material";
import {Link, useLocation, useNavigate} from "react-router-dom";
import IndexLogo from "../components/index/IndexLogo";
import IndexAbout from "../components/index/IndexAbout";
import IndexForm from "../components/index/IndexForm";
import IndexServices from "../components/index/IndexServices";
import IndexContacts from "../components/index/IndexContacts";
import HeaderLink from "../components/Qr/HeaderLink";
import Utils from "../utils";

function IndexPage(props) {
  const navigate = useNavigate();
  document.title = 'Lite Menu';
  return (
    <Box position={'relative'}>
      <Box position={"absolute"} top={0} left={0} width={'100%'} zIndex={20 }>
        <Container maxWidth={"xl"}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box></Box>
            <Box py={2}>
              <HeaderLink to={'/login'} >
                Вход
              </HeaderLink>
            </Box>
          </Box>
        </Container>
      </Box>
      <IndexLogo />
      {/*<Box my={2}>*/}
      {/*  {Utils.processLinkedText("some text \n[[/sss|qwerty]] and some more [[!https://obriens.ru/barcode?text=some|barcode text]] with ending")}*/}
      {/*</Box>*/}
      <IndexAbout />
      <IndexForm/>
      <IndexServices />
      <IndexContacts />
    </Box>
  );
}

export default IndexPage;