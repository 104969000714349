import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
  Box, Button,
  FormControl, InputLabel, MenuItem,
  Select, Stack, TextField, Typography, useMediaQuery, useTheme
} from "@mui/material";
import Utils from "../../utils";
import {DatePicker} from "@mui/x-date-pickers";
import ReservationsTable from "../../components/Hostess/ReservationsTable";
import HostessReservationList from "../Hostess/HostessReservationList";
import HostessCodesList from "../Hostess/HostessCodesList";

function AdmCategoryListPage(props) {

  document.title = 'QR-коды';
  return (
    // <>asdasdasd</>
    <HostessCodesList adm={true} />
  );
}

export default AdmCategoryListPage;