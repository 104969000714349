import React, {useEffect, useState} from 'react';
import {
  Box,
  Button, Dialog, DialogContent, DialogTitle, IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import Utils from "../../utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReservationEdit from "./ReservationEdit";
import CodeEdit from "./CodeEdit";
import * as PropTypes from "prop-types";
import ResponsiveContainer from "../Common/ResponsiveContainer";
import CloseIcon from "@mui/icons-material/Close";


export const EditCodeModal = (props) => {
  return (
    <Dialog open={props.open} maxWidth={'xl'} onClose={props.onClose}>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{props.title ?? 'Редактирование QR-кода'}</DialogTitle>
      <DialogContent>
        <CodeEdit code={props.code} onClose={props.onClose}
               onSave={props.onSave ?? props.onClose}
               adm={props.adm ?? false}
        />
      </DialogContent>
    </Dialog>
  )
}


function CodeTableItem({row, updateTable, adm}){
  const [openModal, setOpenModal] = useState(false);
  const {place} = useParams();
  const onSaveHandler = (e) => {
    e?.stopPropagation();
    setOpenModal(false);
    if(typeof updateTable == 'function')
      updateTable();
  }
  const onCloseHandler = (e) => {
    e?.stopPropagation();
    setOpenModal(false);
    if(typeof updateTable == 'function')
      updateTable();
  }
  const handleOpenModal = (e) => {
    e?.stopPropagation();
    setOpenModal(true);
  }
  return (
    <TableRow key={row.id}>
      <TableCell>
        <Link to={`/p/${place}/codes/${row.code}`} target={'_blank'}>
          {row.id}:{row.code}
        </Link>
      </TableCell>
      <TableCell>
          {row.comment}
      </TableCell>
      <TableCell >{(Utils.getTime(row.created_at))}</TableCell>
      <TableCell >
        {row.closed_at ?
          <>Закрыт {(Utils.getTime(row.closed_at))}<br/>пользователем {row.closer ? row.closer.name : '-'}</>
          : <>Открыт</>
        }
      </TableCell>
      <TableCell >
        {row.orders?.length}
      </TableCell>
      <TableCell
        sx={{displayPrint:'none'}}>
        <Button onClick={handleOpenModal}>
          Редактировать
        </Button>
        <EditCodeModal open={openModal}
              adm={adm ?? false}
              code={row}
              onClose={onCloseHandler}
              onSave={onSaveHandler}
        />
      </TableCell>
    </TableRow>
  )
}

function CodesTable(props) {

  const {place} = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(props.filter
    ?? {search:'',from:'',to:'',status:'active', page:1})
  const [items, setItems] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [pagination, setPagination] = useState({last_page:1,current_page:1,per_page:25,total:0});
  useEffect(()=>{
    Api.get(`/codes/${place}`,
      {...props.filter, page:pagination.current_page ?? 1, perpage:pagination.per_page ?? 25} ?? {})
      .then(data=>data.json())
      .then((result)=>{
        setItems(result?.codes?.data ?? []);
        if(result && result.codes) {
          let pagination = {...result.codes};
          delete pagination['data'];
          pagination.per_page = parseInt(pagination.per_page);
          setPagination(pagination);
        }
      })
  },[place, props.filter, pagination.current_page, pagination.per_page,updateTrigger])
  return (
    <>
      <TablePagination
        component="div"
        count={pagination.total ?? 0}
        page={pagination.current_page - 1}
        onPageChange={(e, page)=>setPagination({...pagination, current_page: page})}
        rowsPerPage={pagination.per_page}
        labelRowsPerPage={'на странице'}

        onRowsPerPageChange={
          (e)=>setPagination({...pagination, per_page: e.target.value, page:1})
        }
      />
      <ResponsiveContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#:Код</TableCell>
              <TableCell>Комментарий</TableCell>
              <TableCell >Создан</TableCell>
              <TableCell >Статус</TableCell>
              <TableCell >Заказы</TableCell>
              <TableCell >Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => (
              <CodeTableItem key={row.code} row={row} updateTable={()=>setUpdateTrigger(Math.random())}
                             index={index} adm={props.adm ?? false}
              />
            ))}
          </TableBody>
        </Table>
      </ResponsiveContainer>
    </>

  );
}

export default CodesTable;