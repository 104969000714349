import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  TextField,
  Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../api";
import {snackShow} from "../../store/snackbarSlice";

function QrReservationReviewBlock(props) {
  const [rating, setRating] = useState(props.code.review_stars);
  const [review, setReview] = useState(props.code.review);
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState(props.code);
  let {place, style} = useSelector(state=> state.place)
  const defaultStyle = Api.processStyle(style, 'textBlock');
  const dispatch = useDispatch();

  const canEdit = ()=>{
    if(!code.review_left_at)
      return true;
    let diff = ((new Date()) - (new Date(code.review_left_at))) / 1000;
    return diff < 3600 * 3;
  }
  const handleClickOpen = () => {setOpen(true);};
  const handleClose = () => {setOpen(false);};
  const handleSend = () => {
    showSnack("Отзыв отправлен!");
    Api.get(`codes/${place.code}/orders/review/${code.code}`,
      {review,rating})
      .then(resp=>resp.json())
      .then(data=>{
        if(data.error) {
          showSnack(data.error, 'error');
          return;
        }
        setCode(data.item)
        showSnack("Отзыв отправлен");
      }).catch(e=>{
        showSnack("Произошла ошибка при отправке отзыва",'error');
      }).finally(()=>{
        setOpen(false);
      })
  };
  const showSnack = (message, type='success') => {
    dispatch(snackShow({message, type}))
  }

  return (
    <Box my={1}>
      <hr/>
      {code.review_left_at && <Box my={1}>
        <Rating value={code.review_stars} disabled />
        <Typography variant={'body2'} fontSize={14}>
          {code.review}
        </Typography>
      </Box>}
      {canEdit() && <Button onClick={handleClickOpen} fullWidth variant={'outlined'} color={'success'}>
        {code.review_left_at ? "Изменить оценку" : "Оцените нашу работу"}
      </Button>}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          Оцените заказ и оставьте комментарий, пожалуйста
        </DialogTitle>

        <DialogContent>
          <Box my={1}>
            <Rating
              name="simple-controlled"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
          </Box>
          <Box my={1}>
            <TextField size={'small'} multiline minRows={3} value={review} variant={'outlined'} fullWidth
               onInput={(e)=>setReview(e.target.value)} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handleSend} variant={'contained'} color={'success'} autoFocus>
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default QrReservationReviewBlock;