import React from 'react';
import IndexContacts from "../../components/index/IndexContacts";
import Image from "mui-image";
import Api from "../../api";
import {Box, Container, Typography} from "@mui/material";

function MenuEntry(props){
  return (<Box display={"flex"} justifyContent={'space-between'} alignItems={'flex-end'} my={2}>
    <Typography lineHeight={'100%'}>
      {props.name ?? '-'}
    </Typography>
    <Box py={'3px'} px={"3px"} flexGrow={1} >
      <Box borderBottom={"#0f0f0f 1px dotted"}></Box>
    </Box>
    <Typography fontWeight={'bold'} lineHeight={'100%'}>
      {props.price ?? '-'}
    </Typography>
  </Box>)
}


function PricePage(props) {
  return (
    <>
      <Image src={Api.fileUrl('/index/price-1.png')} duration={0} width={"100%"} height={'200px'}/>
      <Box py={'60px'}>
        <Container maxWidth={'xl'}>
          <Typography variant={'h4'} textAlign={'center'} fontWeight={600} mb={5}>
            Прайс-лист Lite-Menu
          </Typography>
          <Typography variant={'body1'} textAlign={'center'} mb={5} fontFamily={'Jost'}>
            Наше меню быстро работает и интуитивно понятное в использовании <br/>. Благодаря web-меню Lite-Menu посетители с
            легкостью найдут блюдо по вкусу и увидят состав. Вы можете сэкономить на буклетах, которые
            часто приходят в негодность.
          </Typography>
        </Container>
        <Container maxWidth={'lg'}>
          <Typography variant={'h5'} textAlign={'center'} fontWeight={600} mb={2}>
            Наши услуги
          </Typography>
          <Box border={{xs:"#C7A265 3px solid", md:"#C7A265 6px solid"}} borderRadius={"8px"} paddingX={{xs:'10px', md:'30px', xl:"150px", }}
               paddingY={{xs:'10px', md:'30px', xl:"60px", }}
          >
            <Typography variant={'h6'} textAlign={'center'} fontWeight={600} mb={2}>
              Базовый пакет услуг
            </Typography>
            <MenuEntry name={"Создание меню"} price={'бесплатно'} />
            <MenuEntry name={"Стилизация меню"} price={'бесплатно'} />
            <Typography variant={'h6'} textAlign={'center'} fontWeight={600} mb={2} mt={3}>
              Услуги по подписке
            </Typography>
            <MenuEntry name={"Интеграция сервисов доставки"} price={'1000 руб/мес'} />
            <MenuEntry name={"Постоянная поддержка"} price={'1000 руб/мес'} />
            <MenuEntry name={"Интеграция систем Keeper"} price={'1000 руб/мес'} />
            <MenuEntry name={"Оплата на сайте"} price={'1000 руб/мес'} />
            <Typography variant={'h6'} textAlign={'center'} fontWeight={600} mb={2} mt={3}>
              Разовые услуги
            </Typography>
            <MenuEntry name={"Создание стиля для меню"} price={'5000 руб'} />
          </Box>
        </Container>
      </Box>
      <IndexContacts />
    </>
  );
}

export default PricePage;