import React from 'react';
import {Box, Container, Typography} from "@mui/material";
import bg from "./images/bg-fourth-page.webp";
import './styles.css';
import {Logo, Text} from "./Responsive";

function IndexContacts(props) {
  return (
    <Box sx={{background:`url('${bg}') center`, minHeight:'400px',backgroundSize:'cover'}}>
      <Container maxWidth={"xl"}>
        <Box display={'flex'} justifyContent={"flex-end"} py={5} height={"100%"}>
          <Box display={'flex'} justifyContent={"center"} alignItems={'center'} flexDirection={'column'} >
            <div className="col-request-footer mt-3">
              <Logo mb={4} color={'white'}>
                Контакты
              </Logo>

              <Box mb={4}>

                <Text color={'white'}>
                  Телефон:
                </Text>
                <Logo
                  component={'a'} href={"tel:+79118302428"} sx={{textDecoration:'none'}}
                  mb={4} textAlign={'center'} color={'white'}>
                  +7 (911) 830-24-28
                </Logo>
              </Box>

              <Box>
                <Text color={'white'}>
                  E-mail:
                </Text>
                <Logo
                  component={'a'} href={"mailto:info@lite-menu.com"} sx={{textDecoration:'none'}}
                  textAlign={'center'} color={'white'}>
                  info@lite-menu.com
                </Logo>
              </Box>
            </div>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default IndexContacts;