import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {snackShow} from "../../store/snackbarSlice";
import {useNavigate, useParams} from "react-router-dom";
import {
  Accordion, AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem, Rating,
  Select, Stack,
  Switch,
  TextField, Typography
} from "@mui/material";
import Api from "../../api";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import 'dayjs/locale/ru';
import Utils from "../../utils";
import dayjs from "dayjs";

function ExpandMoreIcon() {
  return null;
}


function CodeOrdersEdit(props){
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [code, setCode] = useState(props.code);
  const {place} = useParams();
  const dispatch = useDispatch();
  const showSnack = (message, type='success') => {
    dispatch(snackShow({message, type}))
  }
  useEffect(()=>{
    loadUnboundOrders();
  },[code])

  const loadUnboundOrders = () =>{
    setLoading(true)
    Api.get(`/codes/${place}/orders`)
      .then(res=>res.json())
      .then(data=> {
        if(data.error){
          showSnack("Ошибка: " + data.error, 'error');
          return;
        }
        setOrders(data.orders)
      })
      .finally(()=>{
        setLoading(false)
      })
  }
  const reloadCode = () =>{
    setLoading(true)
    Api.get(`/codes/${place}/${code.code}`)
      .then(res=>res.json())
      .then(data=> {
        if(data.error){
          showSnack("Ошибка: " + data.error, 'error');
          return;
        }
        setCode(data.item)
      })
      .finally(()=>{
        setLoading(false)
      })
  }
  const bindOrder = (id)=>{
    Api.get(`/codes/${place}/orders/bind/${code.code}`, {order:id})
      .then(res=>res.json())
      .then(data=> {
        if(data.error){
          showSnack("Ошибка: " + data.error, 'error');
          return;
        }
        loadUnboundOrders();
        reloadCode();
      })
      .finally(()=>{
        setLoading(false)
      })
  }
  const unbindOrder = (id)=>{
    Api.get(`/codes/${place}/orders/unbind/${code.code}`, {order:id})
      .then(res=>res.json())
      .then(data=> {
        if(data.error){
          showSnack("Ошибка: " + data.error, 'error');
          return;
        }
        loadUnboundOrders();
        reloadCode();
      })
      .finally(()=>{
        setLoading(false)
      })
  }

  if(loading)
    return(<Box display={'flex'} justifyContent={'center'}>Загрузка...</Box> )
  return (
    <Box>
      <hr />
      <Typography variant={'h6'}>Привязанные заказы</Typography>
      {code.orders.filter((i)=>i.data).map((i,index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Заказ #{i.data.id}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <Typography>
                Комментарий: {i.data.comment}
              </Typography>
              <Typography>
                Состав заказа:
              </Typography>
              {i.data.positions?.map((pos, index)=>(
                <Typography key={index}>
                  {index + 1}: #{pos.id} <strong>{pos.name}</strong>  - <strong>{pos.amount} ед</strong>
                </Typography>
              ))}

              {props.adm && !code.closed_at && <Button onClick={()=>unbindOrder(i.data.id)}
                  variant={'contained'}
                  color={'error'}>Отвязать заказ от кода</Button>}
            </Stack>

          </AccordionDetails>
        </Accordion>
      ))}

      {!code.closed_at && <hr />}
      {!code.closed_at && <Typography variant={'h6'}>Непривязанные заказы</Typography>}
      {!code.closed_at && orders.map((i,index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Заказ #{i.id}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <Typography>
                Комментарий: {i.comment}
              </Typography>
              <Typography>
                Состав заказа:
              </Typography>
              {i.positions?.map((pos, index)=>(
                <Typography key={index}>
                  {index + 1}: #{pos.id} <strong>{pos.name}</strong>  - <strong>{pos.amount} ед</strong>
                </Typography>
              ))}

              <Button onClick={()=>bindOrder(i.id)} variant={'contained'}>Привязать заказ к коду</Button>
            </Stack>

          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}


function CodeEdit(props) {
  const newItem = !!props.code;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {place} = useParams();
  const [data, setData] = useState( props.code
    ? {...props.code, closed:!!props.code.closed_at}
    : {code:'', created_at:'', closed: false, comment:""}
  );
  const showSnack = (message, type='success') => {
    dispatch(snackShow({message, type}))
  }
  const update = (field, value, validation='') =>{
    setData({...data, [field]:value});
  }
  const send = () => {

    let endpoint = props.code?.code
      ? `codes/${place}/${props.code.code}`
      : `codes/${place}`;
    Api.post(endpoint, data)
      .then(res=>res.json())
      .then((data)=>{
        if(data.error){
          showSnack("Ошибка: " + data.error, 'error');
          return;
        }
        if(typeof props.onSave == 'function')
          props.onSave();
      }).catch(e =>{
        let errdata = e?.response?.data ?? "";
        showSnack("Ошибка при сохранении данных: " + errdata.error, 'error');
        if(typeof props.onError == 'function')
          props.onError();
    })

  }
  const closeHandler = ()=> {
    if(typeof props.onClose == 'function')
      props.onClose();
  }
  return (

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField sx={{width:'100%'}}  label={'Код'} disabled={true} value={data.code ?? ''} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField sx={{width:'100%'}}  label={'Создан'} disabled={true}
             value={data.created_at
               ? Utils.getTime(data.created_at)
               : Utils.getTime()
             } />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField sx={{width:'100%'}}  label={'Закрыт'} disabled={true}
                     value={data.closed_at
                       ? (Utils.getTime(data.closed_at))
                       : '-'
                     }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch
              checked={data.closed}
              onChange={(e)=>update('closed', e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />}
            label={data.closed ? "Закрыт" : 'Открыт'} />
        </Grid>
        <Grid item xs={12}>
          <TextField name={'comment'} sx={{width:'100%'}} label={'Комментарий'} value={data.comment ?? ''}
                     onInput={(e)=>update('comment', e.target.value)} />
        </Grid>
        {data.review_left_at && <Grid item xs={12} sm={4} md={3} xl={2}>
          <Box>
            Оценка от {new Date(data.review_left_at).toLocaleString()}
          </Box>
          <Rating disabled value={data.review_stars} />
        </Grid>}
        {data.review_left_at && <Grid item xs={12} sm={8} md={9} xl={10}>
          <TextField multiline name={'review'} disabled fullWidth
                     label={'Отзыв'} value={data.review} />
        </Grid>}
        <Grid item xs={12}>
          <CodeOrdersEdit code={props.code} adm={props.adm ?? false} />
        </Grid>
        <Grid item xs={12}>
          <hr/>
          <Stack spacing={2} direction={'row'}>
            <Button onClick={()=>send()} variant={'contained'}>Сохранить</Button>
            {(props.showCancel ?? true) && <Button onClick={closeHandler}>Отмена</Button>}
          </Stack>
        </Grid>
      </Grid>
  );
}

export default CodeEdit;