import React from 'react';
import {Box, Button, ButtonGroup, TableCell, TableRow, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import Api from "../../api";
import AdmUnitModal from "./AdmUnitModal";
import TagChip from "../Qr/TagChip";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Utils from "../../utils";

function AdmUnitRow(props) {
  const row = props.row;
  const {place} = useParams();
  return (
    <TableRow
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Box display={'flex'} gap={2} alignItems={"center"}>
          <ButtonGroup
            orientation="vertical"
            aria-label="vertical outlined button group"
          >
            {props.index > 0
              && <Button variant={'outlined'} size={"small"}
                         onClick={()=>{props.swap(props.index, 'up')}}>
                <ArrowDropUpIcon />
              </Button>}
            {!props.last && <Button variant={'outlined'} size={"small"}
                         onClick={()=>{props.swap(props.index, 'down')}}>
                <ArrowDropDownIcon />
              </Button>}
          </ButtonGroup>

          {row.id}
        </Box>
      </TableCell>
      <TableCell>
        {row.name} [{row.sku}]
      </TableCell>
      <TableCell>
        Меню: {row.category_id > 0
        ? <Link to={`/adm/${place}/category/${row.category_id}`}>{row.category?.name}</Link>
        : <span>Без категории</span>}
      </TableCell>
      <TableCell>{row.description.slice(0, 50)}{row.description.length > 50 ? "..." : ''}</TableCell>
      <TableCell>{row.price}</TableCell>
      <TableCell>
        {row.status == 'active'
          ? <Typography color='success.main'>Активен</Typography>
          : <Typography color='error.main'>Неактивен</Typography>}
        <Box>
          {(row.tags ?? []).map((i,index) => ( <TagChip key={index} tag={i} />))}
        </Box>
      </TableCell>
      <TableCell>
        <img src={Api.fileUrl(row.image ? row.image : Utils.goodsDefaultImage())}
             style={{maxWidth:'60px', maxHeight:"50px", objectFit: 'cover'}}
             alt={row.name}
        />
      </TableCell>
      <TableCell>
        <AdmUnitModal categories={props.categories} styles={props.styles}
                    index={props.index} update={props.updateRow} unit={row} text={""} place={place}/>
      </TableCell>
    </TableRow>
  );
}

export default AdmUnitRow;