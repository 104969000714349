import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import QrCodeNotExists from "../../components/Qr/QrCodeNotExists";
import {Box, Button, Chip, CircularProgress, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import Api from "../../api";
import GoodsCard from "../../components/Qr/GoodsCard";
import Image from "mui-image";
import CategoryLinkCard from "../../components/Qr/CategoryLinkCard";
import GoodsCardVertical from "../../components/Qr/GoodsCardVertical";
import _ from "lodash";
import PageNotExistsPage from "../PageNotExistsPage";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SpreadTextElement from "../../components/Qr/SpreadTextElement";
import ImageHeader from "../../components/Qr/ImageHeader";
import MainBlock from "../../components/Qr/UnitPage/MainBlock";
import InfoBlock from "../../components/Qr/UnitPage/InfoBlock";
import LoadingBlock from "../../components/Qr/LoadingBlock";
import Utils from "../../utils";

function QrUnitPage(props) {
  document.title = 'Наше меню';
  const [data, setData] = useState([]);
  const [cat, setCat] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const {place, unit} = useParams();


  useEffect(()=>{
    document.title = 'Наше меню';
    setLoading(true);
    Api.post('/public/unit', {place:place, unit:unit})
      .then(res => res.ok ? res.json() : null)
      .then((data) => {
        setData(data);

      })
      .catch()
      .finally(()=>  setLoading(false))
  }, [place, unit])

  const goBack = () => navigate(-1);

  if(loading)
    return (<LoadingBlock />);

  if(!data || _.isEmpty(data))
    return (<PageNotExistsPage />);
  let u = data.unit;
  let category = data.category;
  if(!u || _.isEmpty(u))
    return (<PageNotExistsPage />);

  const tags = u.tags ?? [];
  document.title = u.name;

  return (
    <>
      <ImageHeader maxHeight={'350px'} maxWidth={'520px'} cover noMargin
                   image={Api.fileUrl(u.image ? u.image : Utils.goodsDefaultImage()) } />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MainBlock unit={u} tags={tags} category={data.category} />
          </Grid>
          <Grid item xs={12}>
            <InfoBlock unit={u}/>
          </Grid>
        </Grid>
      </Container>
    </>


  );
}

export default QrUnitPage;