import React, {useEffect} from 'react';
import {Box, Grid} from "@mui/material";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import AdmHeader from "../Adm/AdmHeader";
import AdmSideBar from "../Adm/AdmSideBar";
import Snack from "../ui/Snack";
import {useDispatch, useSelector} from "react-redux";
import {fetchPlace} from "../../store/placeSlice";
import LoadingPage from "../../Pages/LoadingPage";
import PageNotExistsPage from "../../Pages/PageNotExistsPage";
import {fetchUser} from "../../store/userSlice";
import UnauthorizedPage from "../../Pages/UnauthorizedPage";
import {fetchStaff} from "../../store/staffSlice";

function AdmLayout(props) {

  const dispatch = useDispatch();
  const {place} = useParams();
  const navigate = useNavigate();
  useEffect(()=>{
    dispatch(fetchUser())
    dispatch(fetchPlace({place:place}))
    dispatch(fetchStaff({place:place}))
  },[place])
  const currentPlace = useSelector(state => state.place);
  const user = useSelector(state => state.user);
  const staff = useSelector(state => state.staff);
  if(currentPlace.loading || staff.loading || !user.loaded)
    return <LoadingPage />
  if(!user.user || !user.place) {
    navigate('/login');
  }
  if(!user.places.filter((i)=>i.code === place))
    return <UnauthorizedPage />
  if(!currentPlace.place)
    return <PageNotExistsPage />

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <AdmSideBar />
        </Grid>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <main>
            <AdmHeader />
            <Box p={1}>
              <Outlet />
            </Box>
          </main>
        </Grid>
      </Grid>
      <Snack />
    </>

  );
}

export default AdmLayout;