import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogTitle, FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper, Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import AdmCategoryModal from "../../components/Adm/AdmCategoryModal";
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdmStyleModal from "../../components/Adm/AdmStyleModal";
import EditIcon from "@mui/icons-material/Edit";

function AdmCategoryListPage(props) {

  document.title = 'Стили';

  const [styles, setStyles] = useState({categories:[]});
  const [styleType, setStyleType] = useState('all');
  const [loading, setLoading] = useState(true);

  const {place} = useParams();
  const navigate = useNavigate();


  const reloadPage = () =>{
    setLoading(true);
    Api.post('/company/styles',
      {place:place, type:styleType})
      .then(res => res.ok ? res.json() : null)
      .then((data) => setStyles(data.styles ?? []))
      .catch()
      .finally(() => setLoading(false))
  };

  useEffect(()=>{
    reloadPage();
  }, [place, styleType])
  const goBack = () => navigate(-1);

  if(loading)
    return (<CircularProgress />);

  const updateRow = (index, newValue)=>{
    let styleList = styles;
    styleList[index] = newValue;
    setStyles([...styleList]);
  }

  const getDefaultStyleForNew = (type)=> {
    return {
      id:0,
      name:'Новый стиль',
      type:styleType === 'all' ? 'category' : styleType,
      data: {}
    }
  }

  return (
    <Stack spacing={2}>
      <Box p={'0.5rem'} display={'flex'} gap={'10px'}>
        <Typography variant={'h5'}>
          Стили
        </Typography>
      </Box>
      <Box p={'0.5rem'} display={'flex'} gap={'10px'}>
        <AdmStyleModal style={getDefaultStyleForNew()} color={'success'}
          text={<AddBoxIcon />} variant={'contained'} new reload={reloadPage}
        />
        <Button variant={'contained'} color={'primary'}
                onClick={() => navigate(`/adm/${place}/style/new`)}>Добавить пресет</Button>
        <FormControl size={'small'}>
          <InputLabel id="demo-simple-select-label">Тип</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={styleType}
            label="Age"
            onChange={(e) => {setStyleType(e.target.value)}}
          >
            <MenuItem value={'all'}>Все</MenuItem>
            <MenuItem value={'goods'}>Позиция</MenuItem>
            <MenuItem value={'category'}>Категория</MenuItem>
            <MenuItem value={'place'}>Заведение</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell >Название</TableCell>
              <TableCell >Тип</TableCell>
              <TableCell >Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {styles.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Box display={'flex'} gap={2} alignItems={"center"}>
                    {row.id}
                  </Box>

                </TableCell>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>
                  {row.type==='place' ?
                    <Button variant={props.variant ? props.variant : 'outlined'}
                            color={props.color ? props.color : 'primary'}
                            onClick={() => navigate(`/adm/${place}/style/${row.id}`)}>
                      {<EditIcon />}
                    </Button> :
                    <AdmStyleModal index={index} update={updateRow}
                                   style={row}/>
                  }

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default AdmCategoryListPage;