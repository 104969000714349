import React, {useEffect, useState} from 'react';
import {
  Box,
  Button, Dialog, DialogContent, DialogTitle, IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import Utils from "../../utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReservationEdit from "./ReservationEdit";
import LoadingBlock from "../Qr/LoadingBlock";
import ResponsiveContainer from "../Common/ResponsiveContainer";
import CloseIcon from '@mui/icons-material/Close';

export const EditReservationModal = (props) => {
  return (
    <Dialog open={props.open} maxWidth={'xl'} onClose={props.onClose}>
      <IconButton
        aria-label="close"
        onClick={props.onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{props.title ?? 'Редактирование бронирования'}</DialogTitle>
      <DialogContent>
        <ReservationEdit reservation={props.reservation} onClose={props.onClose}
                         onSave={props.onSave ?? props.onClose}
                         adm={props.adm ?? false}
        />
      </DialogContent>
    </Dialog>
  )
}

function ReservationTableItem({row, updateTable, adm}){
  const [openModal, setOpenModal] = useState(false);
  const onSaveHandler = (e) => {
    e?.stopPropagation();
    setOpenModal(false);
    if(typeof updateTable == 'function')
      updateTable();
  }
  const onCloseHandler = (e) => {
    e?.stopPropagation();
    setOpenModal(false);
  }
  const handleOpenModal = (e) => {
    e?.stopPropagation();
    setOpenModal(true);
  }
  return (
    <TableRow key={row.id}>
      <TableCell>{row.id}</TableCell>
      <TableCell >{row.name}</TableCell>
      <TableCell >{row.phone}</TableCell>
      <TableCell>{row.table_name}</TableCell>
      <TableCell>{row.people_count}</TableCell>
      <TableCell>
        <Box display={'flex'} alignItems={'center'} gap={2}>
          {row.preorder && <CheckCircleIcon sx={{displayPrint:'none'}} color={'success'} />}
          {/*{Utils.getSlicedText(row.comment, 40)}*/}
          {row.comment}
        </Box>

      </TableCell>
      <TableCell sx={{whiteSpace:'none'}}>{(Utils.getTime(row.reserved_to))}</TableCell>
      <TableCell
        sx={{displayPrint:'none'}}>{row.status}</TableCell>
      <TableCell
        sx={{displayPrint:'none'}}>
        <Button onClick={handleOpenModal}>
          Редактировать
        </Button>
        <EditReservationModal open={openModal}
                              adm={adm ?? false}
            reservation={row}
            onClose={onCloseHandler}
            onSave={onSaveHandler}
        />
      </TableCell>
    </TableRow>
  )
}

function ReservationsTable(props) {

  const {place} = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(props.filter
    ?? {search:'',from:'',to:'',status:'active', page:1})
  const [items, setItems] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({last_page:1,current_page:1,per_page:25,total:0});
  useEffect(()=>{
    let filter = {...props.filter, page:pagination.current_page, perpage:pagination.per_page}
    if(props.filter.from)
      filter.from = props.filter.from;
    if(props.filter.to)
      filter.to = props.filter.to;
    setLoading(true);
    Api.get(`/reservations/${place}`,
      filter ?? {})
      .then(data=>data.json())
      .then((result)=>{
        setItems(result?.reservations?.data ?? []);
        if(result && result.reservations) {
          let pagination = {...result.reservations};
          delete pagination['data'];
          pagination.per_page = parseInt(pagination.per_page);
          setPagination(pagination);
        }
      }).finally(()=>{
        setLoading(false)
    })
  },[place, props.filter, pagination.current_page, pagination.per_page, updateTrigger])
  if(loading)
    return(<LoadingBlock />)
  return (
    <>
      <TablePagination
        sx={{displayPrint:'none'}}
        component="div"
        count={pagination.total ?? 0}
        page={pagination.current_page - 1}
        onPageChange={(e, page)=>setPagination({...pagination, current_page: page})}
        rowsPerPage={pagination.per_page}
        labelRowsPerPage={'на странице'}

        onRowsPerPageChange={
          (e)=>setPagination({...pagination, per_page: e.target.value, page:1})
        }
      />
      <ResponsiveContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell >Имя</TableCell>
              <TableCell >Телефон</TableCell>
              <TableCell>Стол</TableCell>
              <TableCell>Количество</TableCell>
              <TableCell >Комментарий</TableCell>
              <TableCell >Дата</TableCell>
              <TableCell
                sx={{displayPrint:'none'}}>Статус</TableCell>
              <TableCell sx={{displayPrint:'none'}}>Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => (
              <ReservationTableItem updateTable={()=>setUpdateTrigger(Math.random())}
                        key={row.id} row={row} index={index} adm={props.adm ?? false} />
            ))}
          </TableBody>
        </Table>
      </ResponsiveContainer>
    </>

  );
}

export default ReservationsTable;