import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Grid, Link,
  Stack,
  Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../api";
import {snackShow} from "../../store/snackbarSlice";
import {useParams} from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

function QrReservationWaiterBlock({code}) {
  const {place} = useParams();
  const [loading, setLoading] = useState(false);
  const [lastCall, setLastCall] = useState(false);
  const [lastCallDiff, setLastCallDiff] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [copiedTipData, setCopiedTipData] = React.useState(false);
  const [wdata] = React.useState(code.waiter_data[0] ?? {});
  const dispatch = useDispatch();

  const waiterCall = () => {
    console.log('waiter call!');
    setLoading(true)
    Api.get(`/codes/${place}/waiter/call/${code.code}`)
      .then(res => res.json())
      .then((data) => {
        if(data.error)
          showSnack(data.error, 'error');
        else {
          showSnack("Вызов отправлен!");
          setLastCallDiff(60);
        }
      })
      .catch()
      .finally(()=>  setLoading(false))
  }
  const showSnack = (message, type='success') => {
    dispatch(snackShow({message, type}))
  }


  const checkLastCall = () => {
    if(lastCallDiff > 0)
      setLastCallDiff(lastCallDiff - 1);
    return;
    if(!lastCall)
      return;
    let diff = Math.floor (((new Date()) - lastCall) / 1000);
    if(diff > 60)
      setLastCall(false);
    setLastCallDiff(Math.max(0, 60 - diff));
  }
  useEffect(()=>{
    if(code.last_notification){
      let lastCall = new Date(code.last_notification);
      let diff = Math.floor (((new Date()) - lastCall) / 1000);
      if(diff < 60) {
        setLastCallDiff(Math.max(0, 60 - diff))
      }
    }
    checkLastCall();
  }, [place, code])

  useEffect(()=>{
    const callInterval = setInterval(() => checkLastCall(), 1000);
    return () => {
      clearInterval(callInterval);
    };
  }, [lastCallDiff])

  const handleClickOpen = () => {setOpen(true);};
  const handleClose = () => {setOpen(false);};
  const copyToClipboard = () => {
    if(navigator?.clipboard){
      navigator?.clipboard?.writeText(wdata.public_tip_data)
        .then(()=>{
          setCopiedTipData(true)
          showSnack("Данные для чаевых скопированы!")
          setTimeout(()=>{setCopiedTipData(false)}, 5000)

        }).catch(()=>{
          showSnack("Произошла ошибка, данные не скопированы!", 'error')
        });
    } else {
      showSnack("Произошла ошибка, данные не скопированы!", 'error')
    }

  };

  if(!code.waiter)
    return (<></>);
  return (
    <Box my={1}>
      <Grid container spacing={1}>
        {!code.closed_at &&
        <Grid item xs={(wdata.public_tip_data || wdata.public_tip_link) ? 6 : 12}>
          <Button onClick={waiterCall} color={'success'} sx={{flexGrow:1}}
                  disabled={loading || lastCallDiff > 0}
                  variant={'contained'} fullWidth>
            {(lastCallDiff > 0) ? (lastCallDiff + ' сек. до следующего вызова') : "Вызов официанта"}
          </Button>
        </Grid>}
        {(wdata.public_tip_data || wdata.public_tip_link) &&
        <Grid item xs={code.closed_at ? 12 : 6}>
          <Button onClick={handleClickOpen} variant={'outlined'} color={'success'} fullWidth>
            оставить чаевые
          </Button>
        </Grid>}
      </Grid>


      <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
        <DialogTitle id="alert-dialog-title">
          {wdata.public_name}
        </DialogTitle>

        <DialogContent sx={{pb:0}}>
          <Typography variant={'subtitle1'} mb={3}>
            {wdata.public_comment}
          </Typography>

          {(wdata.public_tip_data || wdata.public_tip_link) && <Box>
            <Box>
              <Typography variant={'subtitle2'}>
                Данные для чаевых (нажмите чтобы скопировать или перейти):
              </Typography>
              <Stack direction={'row'} spacing={4}>
                {wdata.public_tip_data && <Button variant={'text'}
                        onClick={copyToClipboard}
                        color={copiedTipData ? 'success' : 'primary'}
                        startIcon={copiedTipData ? <CheckIcon /> : <ContentCopyIcon />}>
                  {copiedTipData ? "Скопировано!" : wdata.public_tip_data}
                </Button>}
                {wdata.public_tip_link &&
                  <Button component={Link} target={'_blank'}
                          href={wdata.public_tip_link}
                          variant="text" color="success">
                    Перейти
                  </Button>
                }

              </Stack>

            </Box>

          </Box>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default QrReservationWaiterBlock;