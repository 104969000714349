import React, {useEffect, useState} from 'react';
import {
  Alert, Autocomplete, Box,
  Button, ButtonGroup, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select,
  Snackbar, Stack, TextareaAutosize,
  TextField, Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/icons-material/HighlightOff';
import Image from "mui-image";
import Api from "../../api";
import axios from "axios";
import _ from "lodash";
import ImageSelectBlock from "./ImageSelectBlock";
import AdmTagElementController from "./AdmTagElementController";
import {useParams} from "react-router-dom";
import {MuiColorInput} from "mui-color-input";

function AdmCategoryModal(props) {

  const normalDefaults = {
    color:"rgba(0, 0,0,0.87)",
    backgroundColor:"white",
    borderColor:"darkgray",
    borderWidth:0,
    borderStyle:'solid'
  };
  const [open, setOpen] = React.useState(false);
  const [snack, setSnack] = React.useState({message:'Данные успешно сохранены', type:'success'})
  const [loading, setLoading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);

  const [style, setStyle] = React.useState(props.style);
  const [type, setType] = React.useState(props.style.type ?? 'category');
  const [tempData, setTempData] = React.useState(typeof(style.data) ==='object'
    ? {...{normal:normalDefaults}, ...style.data}
    : JSON.parse(style.data) ?? {normal:normalDefaults});
  const [normal, setNormal] = useState(tempData.normal ?? normalDefaults);
  const {text, update, index} = props;
  const {place} = useParams();

  // console.log("temp", tempData, 'style', style)

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };
  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const setNormalData = (newValue) => {
    let newNormal = {...normal, ...newValue};
    let tdata = tempData;
    tdata.normal = newNormal
    // console.log('value ', newValue)
    // console.log('new ', newNormal)
    // console.log('tdata', tdata)
    // console.log('temp', tempData)
    setTempData(tdata);
    setNormal(newNormal)
  }

  const toDefaults = () => {
    setNormalData(normalDefaults);
  }


  const processStyle = () => {
    let st = style;
    st.type = type;
    st.data = tempData;
    let bw = parseInt(st.data.normal.borderWidth);
    st.data.normal.borderWidth = bw + "px";

    return st;
  }
  const save = () => {
    setLoading(true);
    let st = processStyle();
    console.log("saving", st, tempData);

    Api.post('/company/styles/update', {style:st, place:place, new:props.new ?? false})
      .then(resp => resp.json())
      .then(resp => {
        setSnack({...{message:"Данные успешно сохранены", type:'success'}})
        handleSnackOpen();
        setOpen(false);

        let item = resp.item
        console.log("error", resp);
        if(typeof item.data === "string")
          item.data = JSON.parse(item.data);
        setStyle(item);
        console.log("response", item);
        if(props.new && props.reload)
          props.reload();
        if(props.update)
          props.update(index, item);
      }).catch(error=>{

        console.log("error", error);
        let errdata = error?.response?.data ?? "";
        setSnack({...{message:"Ошибка при сохранении данных: " + errdata.error, type:'error'}})
        handleSnackOpen();
      })
      .finally(()=>{
        setLoading(false);
      })
  };

  const setStyleData = (value) => {
    setStyle({...style, ...value});
  }

  return (
    <>
      <Button variant={props.variant ? props.variant : 'outlined'}
              color={props.color ? props.color : 'primary'} onClick={handleClickOpen}>
        {text ? text : <EditIcon />}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"} fullWidth={true}>
        <DialogTitle>Редактирование категории</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} component={'form'} onSubmit={save} py={1}>
            <Grid item md={8}>
                <TextField size="small"
                  autoFocus margin="dense"
                  name='name' value={style.name}
                  onInput={(e)=>setStyleData({name: e.target.value})}
                  label="Название" fullWidth variant="standard"
                />
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Тип</InputLabel>
                <Select size
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Age"
                  onChange={(e)=>{setType(e.target.value)}}
                >
                  <MenuItem value={'goods'}>Позиция</MenuItem>
                  <MenuItem value={'category'}>Категория</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box textAlign={"center"}>
                Текст
                <MuiColorInput value={normal.color}  size="small" variant="outlined"
                               onChange={(e)=>setNormalData({color:e})}/>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box textAlign={"center"}>
                Фон
                <MuiColorInput value={normal.backgroundColor}  size="small" variant="outlined"
                               onChange={(e)=>setNormalData({backgroundColor:e})}/>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box textAlign={"center"}>
                Border
                <MuiColorInput value={normal.borderColor}
                               size="small" variant="outlined"
                               onChange={(e)=>setNormalData({borderColor:e})}/>
                <TextField type={'number'} size="small" variant="outlined"
                           value={parseInt(normal.borderWidth)}
                           onChange={(e)=>setNormalData({borderWidth:e.target.value})} />
              </Box>
            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={toDefaults}>Значения по умолчанию</Button>
          <Button onClick={save} variant={'contained'} color={'success'}>Сохранить</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackOpen} autoHideDuration={6000}
        onClose={handleSnackClose} anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert onClose={handleSnackClose} severity={snack.type} sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AdmCategoryModal;
