import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, Routes} from "react-router-dom";
import IndexPage from "./Pages/IndexPage";
import QrLayout, {qrLayoutLoader} from "./components/Layouts/QrLayout";
import AdmLayout from "./components/Layouts/AdmLayout";
import QrIndexPage from "./Pages/QrPages/QrIndexPage";
import PageNotExistsPage from "./Pages/PageNotExistsPage";
import QrMenuPage from "./Pages/QrPages/QrMenuPage";
import QrUnitPage from "./Pages/QrPages/QrUnitPage";
import AdmIndexPage from "./Pages/AdmPages/AdmIndexPage";
import AdmUnitListPage from "./Pages/AdmPages/AdmUnitListPage";
import AdmCategoryListPage from "./Pages/AdmPages/AdmCategoryListPage";
import AdmKeeperCategoryListPage from "./Pages/AdmPages/AdmKeeperCategoryListPage";
import AdmKeeperUnitListPage from "./Pages/AdmPages/AdmKeeperUnitListPage";
import AdmPlacePage from "./Pages/AdmPages/AdmPlacePage";
import QrMenuIndexPage from "./Pages/QrPages/QrMenuIndexPage";
import AdmStyleListPage from "./Pages/AdmPages/AdmStyleListPage";
import QrAboutPage from "./Pages/QrPages/QrAboutPage";
import AdmComponentListPage from "./Pages/AdmPages/AdmComponentListPage";
import AdmTagsListPage from "./Pages/AdmPages/AdmTagsListPage";
import AdmStyleEditPage from "./Pages/AdmPages/AdmStyleEditPage";
import LoginPage from "./Pages/LoginPage";
import PricePage from "./Pages/Service/PricePage";
import QrCart from "./Pages/QrPages/QrCart";
import QrCarousel, {qrCarouselLoader} from "./Pages/QrPages/QrCarousel";
import HostessLayout from "./components/Layouts/HostessLayout";
import HostessIndex from "./Pages/Hostess/HostessIndex";
import HostessReservationList from "./Pages/Hostess/HostessReservationList";
import QrReservation from "./Pages/QrPages/QrReservation";
import HostessCodesList from "./Pages/Hostess/HostessCodesList";


import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/ru';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from "dayjs";
import ErrorPage from "./Pages/ErrorPage";
import AdmReservationsPage from "./Pages/AdmPages/AdmReservationsPage";
import AdmCodesPage from "./Pages/AdmPages/AdmCodesPage";
import AdmUsersPage from "./Pages/AdmPages/AdmUsersPage";
import QrAskForCode from "./Pages/QrPages/QrAskForCode";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault();

const router = createBrowserRouter(createRoutesFromElements(
  <Route path="/" errorElement={<ErrorPage />}>
    <Route index element={<IndexPage />}/>
    <Route path="pricelist" element={<PricePage />}/>
    <Route path="yandex-test" element={<div>Логин в Метрике</div>}/>
    <Route path="login" element={<LoginPage />} />
    <Route path="qr/:code" element={<QrIndexPage />} />
    <Route path="error" element={<ErrorPage />} />
    <Route path="p/:place/*" element={<QrLayout />}>
      <Route index  element={<QrIndexPage />}/>
      <Route path="error" element={<ErrorPage />} />
      <Route path="menu" element={<QrMenuIndexPage />} />
      <Route path="menu/:category" element={<QrMenuPage />} />
      <Route path="u/:unit" element={<QrUnitPage />} />
      <Route path="about" element={<QrAboutPage />} />
      <Route path="cart" element={<QrCart />} />
      <Route path="orders" element={<QrCart />} />
      <Route path="orders/:id" element={<QrCart />} />
      <Route path="codes/:code" element={<QrReservation />} />
      <Route path="carousel" element={<QrCarousel />} loader={qrCarouselLoader} />
      <Route path="ask_code" element={<QrAskForCode />} />
      <Route path="*" element={<PageNotExistsPage />} />
    </Route>
    <Route path="hostess/:place/*" element={<HostessLayout />}>
      <Route index element={<HostessReservationList />}/>
      <Route path="reservations" element={<HostessReservationList />} />
      <Route path="codes" element={<HostessCodesList />} />
      <Route path="*" element={<PageNotExistsPage />} />
    </Route>
    <Route path="adm" element={<LoginPage />} />
    <Route path="adm/:place/*" element={<AdmLayout />}>
      <Route index element={<AdmIndexPage/>}/>
      <Route path="category" element={<AdmCategoryListPage />}/>
      <Route path="category/:id" element={<AdmCategoryListPage />}/>
      <Route path="unit/:id" element={<AdmUnitListPage />}/>
      <Route path="unit" element={<AdmUnitListPage />}/>
      <Route path="component" element={<AdmComponentListPage />}/>
      <Route path="keeper" element={<AdmKeeperCategoryListPage />}/>
      <Route path="keeper/:id" element={<AdmKeeperUnitListPage />}/>
      <Route path="place" element={<AdmPlacePage />}/>
      <Route path="style" element={<AdmStyleListPage />}/>
      <Route path="style/:id" element={<AdmStyleEditPage />}/>
      <Route path="tags" element={<AdmTagsListPage />}/>
      <Route path="reservations" element={<AdmReservationsPage />}/>
      <Route path="codes" element={<AdmCodesPage />}/>
      <Route path="users" element={<AdmUsersPage />}/>
      <Route path="*" element={<PageNotExistsPage />} />
    </Route>
    <Route path="*" element={<PageNotExistsPage />} />
  </Route>
));
function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
     <RouterProvider router={router} />
    </LocalizationProvider>
  );
}

export default App;
