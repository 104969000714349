import React from 'react';
import {Alert, Snackbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {snackHide} from "../../store/snackbarSlice";

function Snack(props) {
  const snack = useSelector(state => state.snackbar)
  const dispatch = useDispatch();
  return (
    <Snackbar open={snack.open} autoHideDuration={6000}
              onClose={()=> {dispatch(snackHide())}} anchorOrigin={{ vertical:'top', horizontal:'right' }}
    >
      <Alert onClose={()=> {dispatch(snackHide())}} severity={snack.type} sx={{ width: '100%' }}>
        {snack.message}
      </Alert>
    </Snackbar>
  );
}

export default Snack;