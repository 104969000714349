import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import Api from "../api";
import {fetchPlace} from "./placeSlice";
export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async function(){
    let params = {token:localStorage.getItem('token')};
    const resp = await Api.post('/auth/check', params);
    return resp.ok ? resp.json() : null;
  }
)
const userSlice = createSlice({
  name:'user',
  initialState: {
    user: null,
    places: [],
    place:'',
    id: null,
    token: localStorage.getItem('token'),
    loaded:false,
    loggedIn:false,
    staff:[]
  },
  reducers:{
    setAuth(state, action){
      // console.log('data', action.payload);
      state.user = action.payload?.user;
      state.id = action.payload?.user?.id;
      state.token = action.payload?.token;
      state.staff = action.payload.staff;
      state.places = action.payload?.places;
      state.place = state.places.length > 0 ? state.places[0].code : '';
      state.loggedIn = state.user && state.token && state.place;
      localStorage.setItem("token", state.token);
      // console.log('token', localStorage.getItem('token'))
    },
    logout(state, action){
      state.id = state.user = state.token = null;
      state.places = [];
      state.place = '';
      state.loggedIn = false;
      localStorage.removeItem('token');
    },
  },
  extraReducers:{
    [fetchUser.pending]:(state, action)=>{
      state.loaded=false;
    },
    [fetchUser.fulfilled]:(state, action)=>{
      state.loaded=true;
      state.user = action.payload?.user;
      state.id = action.payload?.user?.id;
      state.places = action.payload?.places ?? [];
      state.place = state.places.length > 0 ? state.places[0].code : '';
      state.loggedIn = state.user && state.token && state.place;
      // console.log('loaded', action.payload, state)
    },
    [fetchUser.rejected]:(state, action)=>{
      state.loaded=true;
      state.user = null;
      state.id = null;
      state.places = [];
      state.place = '';
      state.loggedIn = false;

    }
  }
})



export const {setAuth, logout} = userSlice.actions;
export default userSlice.reducer;