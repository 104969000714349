import React from 'react';
import {Link, useParams} from "react-router-dom";
import {Box, Grid} from "@mui/material";

function AdmIndexPage(props) {

  document.title = 'Админ. панель';
  const {place} = useParams();
  return (
    <Box m={2}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={4} lg={3}>
          <Link to={`/adm/${place}/category`}>Категории меню</Link>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <Link to={`/adm/${place}/keeper`}>Категории Keeper</Link>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <Link to={`/adm/${place}/unit`}>Все позиции меню</Link>
        </Grid>
      </Grid>
    </Box>

  );
}

export default AdmIndexPage;