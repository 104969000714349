import React from 'react';
import {CircularProgress, Container, Typography} from "@mui/material";

function PageNotExists(props) {
  return (
    <Container maxWidth={"sm"}>
      <Typography align={"center"}><CircularProgress /></Typography>
    </Container>

  );
}

export default PageNotExists;