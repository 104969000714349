import React from 'react';
import Image from "mui-image";
import Api from "../../api";
import {Box} from "@mui/material";

function ImageHeader(props) {
  let objectFit = 'contain';
  if(props.cover)
    objectFit = 'cover'

  return (
    <Box marginTop={props.noMargin ? 0 : "50px"} position={'relative'}>
      <Image height={"100%"} src={props.image}
             duration={props.duration ?? 0} style={{objectFit:objectFit,
        width:props.width??"100%",
        maxWidth:props.maxWidth??"100%",
        height:props.height??"100%",
        maxHeight:props.maxHeight??"100%",
        ...(props.style ?? {})
      }} />
      {/*<Box position={'absolute'} zIndex={10} top={0} left={0} width={'100%'} height={'100%'}*/}
      {/*     sx={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.0) 90%)'}}*/}
      {/*>*/}
      {/*</Box>*/}
    </Box>
  );
}

export default ImageHeader;