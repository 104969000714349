import React, {useEffect, useState} from 'react';
import {
  Alert, Autocomplete, Box,
  Button, ButtonGroup, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel, Grid,
  Snackbar, Stack, TextareaAutosize,
  TextField, Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/icons-material/HighlightOff';
import Image from "mui-image";
import Api from "../../api";
import axios from "axios";
import _ from "lodash";
import ImageSelectBlock from "./ImageSelectBlock";
import AdmTagElementController from "./AdmTagElementController";
import {useParams} from "react-router-dom";

function getDefaultUnitData(){
  return {
    image:'',
    category_id:0,
    name:'',
    sku:'',
    description:'',
    price:0,
    status:'active',
    contents:'',
    show_on_index:false,
    allow_order:false,
    long_description:'',
    data:{},
    unit:'ед',

  };
}
function getDefaultUnitDataEcost(){
  return {ecost:{ecost:'0 ккал',protein:0,lipid:0,carbohydrate:0,show:1}, weight:0};
}

function AdmUnitModal(props) {
  const [open, setOpen] = React.useState(false);
  // const [styles, setStyles] = React.useState([{name:"По умолчанию", id:0}, ...props.styles]);
  const [snack, setSnack] = React.useState({message:'Данные успешно сохранены', type:'success'})
  const [loading, setLoading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const {place, id:catId} = useParams();

  const [cat, setCat] = React.useState(props.unit
    ? {...props.unit}
    : {...getDefaultUnitData(), category_id: catId ? parseInt(catId) : 0 })
  const [data, setData] = React.useState(props.unit?.data
    ? props.unit.data
    : getDefaultUnitDataEcost());

  // console.log("from modal", catId, cat);
  const {text, update, index, newitem, reload} = props;

  const getImage = () => {
    return selectedImage ? URL.createObjectURL(selectedImage) : Api.fileUrl(cat.image);
  }
  // useEffect(()=>{
  //   setLoading(true);
  //   Api.post('/public/categories', {place:place, nomenu:true, all:true})
  //     .then(res => res.ok ? res.json() : null)
  //     .then((data) => setOptions([{name:"Верхний уровень", id:0}, ...data.categories]))
  //     .catch()
  //     .finally(()=>{setLoading(false)})
  // }, [place])

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };
  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const save = () => {

    setLoading(true);
    const formData = new FormData();
    formData.set('place', place);
    cat.data = JSON.stringify(data);
    for ( var key in cat ) {
      formData.append(`unit[${key}]`, cat[key]);
    }
    formData.append('data', JSON.stringify(data));
    if(newitem)
      formData.set('new', true);
    let savedTags = JSON.stringify(cat.tags ? cat.tags.map(i => i.id) : []);
    formData.append(`unit[tags]`,savedTags );
    console.log("saving" ,cat, savedTags);
    // Update the formData object
    if(selectedImage) {
      formData.append(
        "photo",
        selectedImage,
        selectedImage.name
      );
    }
    let endpoint = `/goods/${place}` + (cat.id > 0 ? ('/' + cat.id) : '');

    Api.postForm(endpoint, formData, {}, true)
      .then(resp => {
        setSnack({...{message:"Данные успешно сохранены", type:'success'}})
        handleSnackOpen();
        setOpen(false);
        let item = resp.data.item;
        console.log("response2" ,item);
        setCat(item);
        setData(item.data ?? {});
        if(update) {
          update(index, item);
        }
        if(newitem && reload)
          reload();
      }).catch(error=>{
        let errdata = error.response.data;
        setSnack({...{message:"Ошибка при сохранении данных: " + errdata.error, type:'error'}})
        handleSnackOpen();
      })
      .finally(()=>{
        setLoading(false);
      })
  };

  const setCatData = (value) => {
    setCat({...cat, ...value});
  }
  const setUnitData = (value) => {
    setData({...data, ...value});
  }
  const setUnitEcostData = (e, field, checkbox = false) => {
    let v = data.ecost ? data.ecost : {};
    let val = checkbox
      ? (e.target.checked ? 1 : 0)
      : e.target.value;
    v[field] = val;
    setUnitData({ecost:v});
  }
  const findParentCat = () => {
    return _.find(props.categories,
      {id: (typeof cat.category_id == 'string') ? parseInt(cat.category_id) : cat.category_id})
      ?? {name:"Верхний уровень", id:0};
  }
  // const findStyle = () => {
  //   let value = _.find(styles, function(option){
  //     if(option.id == cat.style_id)
  //       return true;
  //   });
  //   if(!value)
  //     value = options[0];
  //   return value;
  // }


  return (
    <>
      <Button variant={props.variant ? props.variant : 'outlined'}
              color={props.color ? props.color : 'primary'} onClick={handleClickOpen}>
        {text ? text : <EditIcon />}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"} fullWidth={true}>
        <DialogTitle>Редактирование позиции</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} component={'form'} onSubmit={save}>
            <Grid item md={4} lg={3} xl={2}>
              <ImageSelectBlock setImage={setSelectedImage} image={cat.image} />

            </Grid>
            <Grid item md={8} lg={9} xl={10} pt={2}>
              <Stack spacing={2}>


                <Grid container spacing={1}>
                  <Grid item md={8}>
                    <TextField size="small"
                               autoFocus margin="dense"
                               name='name' value={cat.name}
                               onInput={(e)=>setCatData({name: e.target.value})}
                               label="Название" fullWidth variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4}>

                    <Autocomplete
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={props.categories}
                      value={findParentCat()}
                      onChange={(event, newValue)=> {
                          setCatData({category_id: newValue.id});
                        }
                      }
                      sx={{ width: '100%' }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Категория" variant='outlined' />}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField size="small"
                               margin="dense"
                               name='sku' value={cat.sku}
                               onInput={(e)=>setCatData({sku: e.target.value})}
                               label="SKU" fullWidth variant="outlined"
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField size="small"
                       margin="dense"
                       name='price' value={cat.price}
                       onInput={(e)=>setCatData({price: e.target.value})}
                       label="Цена" fullWidth variant="outlined"
                    />
                  </Grid>
                  {/*<Grid item md={3}>*/}
                    {/*<Autocomplete*/}
                    {/*  size="small"*/}
                    {/*  fullWidth*/}
                    {/*  disablePortal*/}
                    {/*  id="combo-box-demo"*/}
                    {/*  options={styles}*/}
                    {/*  value={findStyle()}*/}
                    {/*  isOptionEqualToValue={(option, value) =>*/}
                    {/*    option.id === value.id*/}
                    {/*  }*/}
                    {/*  onChange={(event, newValue)=> {*/}
                    {/*    setCatData({style_id: newValue.id});*/}
                    {/*  }*/}
                    {/*  }*/}
                    {/*  sx={{ width: "100%" }}*/}
                    {/*  getOptionLabel={(option) => option.name}*/}
                    {/*  renderInput={(params) => <TextField {...params} label="Стиль элемента" />}*/}
                    {/*/>*/}
                  {/*</Grid>*/}

                  <Grid item md={3}>
                    <TextField size="small"
                               margin="dense"
                               name='unit' value={cat.unit}
                               onInput={(e)=>setCatData({unit: e.target.value})}
                               label="Единица" fullWidth variant="outlined"
                    />
                  </Grid>
                </Grid>

                <TextField size="small"
                           autoFocus margin="dense"
                           name='name' value={cat.contents}
                           onInput={(e)=>setCatData({contents: e.target.value})}
                           label="Состав" fullWidth variant="outlined"
                />

                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={cat.status == 'active'}
                          onChange={(event)=>{setCatData({status:event.target.checked?'active':'inactive'})}} />
                    }
                    label="Активен"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={cat.show_on_index > 0}
                          onChange={(event)=>{
                            setCatData({show_on_index:event.target.checked ? 1 : 0})}} />
                    }
                    label="Показывать на главной странице меню"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={cat.allow_order > 0}
                          onChange={(event)=>{
                            setCatData({allow_order:event.target.checked})}} />
                    }
                    label="Позволить заказ"
                  />
                </Stack>

                <TextField multiline rows={4} size="small"
                  autoFocus margin="dense"
                  name='description' value={cat.description}
                  onInput={(e)=>setCatData({description: e.target.value})}
                  label="Описание" fullWidth variant="outlined"
                />
              </Stack>


            </Grid>
            <Grid item md={12}>
              <AdmTagElementController tags={cat.tags ?? []} setTags={(tags)=>setCatData({tags: tags})}/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h6'} fontWeight={'bold'}>
                Дополнительно
              </Typography>
            </Grid>

            <Grid item sm={12}>
              <FormControlLabel
                control={
                  <Checkbox checked={(data.ecost?.show ?? 1) == 1}
                            onChange={(e)=> {
                              setUnitEcostData(e, 'show', true)
                            }} />
                }
                label="Показывать на странице товара"
              />
            </Grid>
            <Grid item md={6} lg={4} xl={3}>
              <TextField size="small" margin="dense" value={data.ecost ? data.ecost.ecost : "0 ккал"}
                 onInput={(e)=>
                   setUnitEcostData(e, 'ecost')}
                 label="Энергетическая ценность" fullWidth variant="outlined"
              />
            </Grid>
            <Grid item md={6} lg={4} xl={3}>
              <TextField size="small" margin="dense" value={data.ecost ? data.ecost.protein : "0"}
                 inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                 onInput={(e)=>
                   setUnitEcostData(e, 'protein')}
                 label="Белки" fullWidth variant="outlined"
              />
            </Grid>
            <Grid item md={6} lg={4} xl={3}>
              <TextField size="small" margin="dense" value={data.ecost ? data.ecost.lipid : "0"}
                         type={'number'}
                 onInput={(e)=>
                   setUnitEcostData(e, 'lipid')}
                 label="Жиры" fullWidth variant="outlined"
              />
            </Grid>
            <Grid item md={6} lg={4} xl={3}>
              <TextField size="small" margin="dense" value={data.ecost ? data.ecost.carbohydrate : "0"}
                 type={'number'}
                 onInput={(e)=>
                   setUnitEcostData(e, 'carbohydrate')}
                 label="Углеводы" fullWidth variant="outlined"
              />
            </Grid>
            <Grid item md={6} lg={4} xl={3}>
              <TextField size="small" margin="dense" value={data.weight ? data.weight : 0}
                 type={'number'}
                 onInput={(e)=>
                   setUnitData({weight: e.target.value})}
                 label="Вес, г" fullWidth variant="outlined"
              />
            </Grid>
            <Grid item md={6} lg={4} xl={3}>
              <TextField size="small" margin="dense" value={data.volume ? data.volume : 0}
                 type={'number'}
                 onInput={(e)=>
                   setUnitData({volume: e.target.value})}
                 label="Объем, мл" fullWidth variant="outlined"
              />
            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={save} variant={'contained'}>Сохранить</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackOpen} autoHideDuration={6000}
        onClose={handleSnackClose} anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert onClose={handleSnackClose} severity={snack.type} sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AdmUnitModal;
