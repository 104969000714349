import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import QrCodeNotExists from "../../components/Qr/QrCodeNotExists";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Pagination,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Api from "../../api";
import GoodsCard from "../../components/Qr/GoodsCard";
import Image from "mui-image";
import CategoryLinkCard from "../../components/Qr/CategoryLinkCard";
import GoodsCardVertical from "../../components/Qr/GoodsCardVertical";
import PageNotExistsPage from "../PageNotExistsPage";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _ from "lodash";
import ImageHeader from "../../components/Qr/ImageHeader";
import CategoryBlock from "../../components/Qr/MenuPage/CategoryBlock";
import LoadingBlock from "../../components/Qr/LoadingBlock";
import BaseLayout from "../../components/Qr/MenuPage/BaseLayout";
import LargeLayout from "../../components/Qr/MenuPage/LargeLayout";

function QrMenuPage(props) {
  const [data, setData] = useState({});
  const pagination = data?.pagination ?? {totalPages:1,page:1};

  const [cat, setCat] = useState([]);
  const [loading, setLoading] = useState(true);

  const {place, category} = useParams();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const theme = useTheme();
  const bpLG = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(()=>{
    document.title = 'Наше меню';
    setLoading(true);
    Api.post('/public/menu', {place:place, category:category, onlyactive:true, page:page,perpage:12})
      .then(res => {
        if(res.ok)
          return res.json()
        navigate(`/p/${place}/menu`);
        return null;
      })
      .then((data) => {
        setData(data);
      })
      .catch()
      .finally(()=>  setLoading(false));
  }, [place, page, category])

  const goBack = () => navigate(-1);

  if(loading)
    return (<LoadingBlock />);

  if(!data || _.isEmpty(data))
    return (<PageNotExistsPage />);

  const menu = data.category;
  const image = (data?.category?.image_wide && (data?.category?.image_wide !== '/goods/default-wide.png'))
    ? data.category.image_wide
    : data?.category?.image ? data.category.image : data.place.image;

  return (
    <>
      <ImageHeader cover noMargin height={"240px"}
        image={Api.fileUrl(image) } />
      <Container>
        <CategoryBlock/>
        {pagination && pagination.totalPages > 1 &&
          <div style={{backdropFilter:'blur(5px)', background:'rgba(255,255,255,0.8)',padding:'0.2rem', margin:"10px 0",
            borderRadius:"5px", width:'fit-content'}}>
            <Pagination count={pagination.totalPages} page={page}
                        onChange={handlePageChange}  variant="text" shape={'rounded'}
            />
          </div>
        }

        {!bpLG && <BaseLayout goods={data.goods} /> }
        {bpLG && <LargeLayout goods={data.goods} /> }

        {pagination && pagination.totalPages > 1 &&
          <div style={{backdropFilter:'blur(5px)', background:'rgba(255,255,255,0.8)',padding:'0.2rem', margin:"10px 0",
            borderRadius:"5px", width:'fit-content'}}>
            <Pagination count={pagination.totalPages} page={page}
                        onChange={handlePageChange}  variant="text" shape={'rounded'}
            />
          </div>

        }
      </Container>
    </>


  );
}

export default QrMenuPage;