import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import {useDispatch} from "react-redux";
import {logout} from "../../store/userSlice";

function AdmHeader(props) {

  const {place} = useParams();
  const [places, setPlaces] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  }
  const handlePlaceChange = (event) =>{
    let newPlace = event.target.value;
    navigate(`/adm/${newPlace}`)
  }

  useEffect(()=>{
    Api.post('/adm/place/get')
      .then(res => res.json())
      .then(data => {
        setPlaces(data.places ? data.places : [])
      })
  }, [])

  return (
    <Box px={1} py={2} mb={1} boxShadow={'1px 1px 5px #9E9E9E'} display={'flex'}
         justifyContent={'space-between'} displayPrint={'none'}>
      <Stack spacing={1} direction={'row'}>
        <FormControl sx={{maxWidth:300}} size="small">
          <InputLabel id="place-select-label">Заведение</InputLabel>
          <Select
            labelId="place-select-label"
            id="place-select"
            value={place}
            label="Заведение"
            onChange={handlePlaceChange}
          >
            {places.map(elm => <MenuItem key={elm.id} value={elm.code}>{elm.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Stack>
      <Box>
        <Button variant={'outlined'} onClick={handleLogout}>Выход</Button>
      </Box>
    </Box>
  );
}

export default AdmHeader;