import React from 'react';
import {Link} from "react-router-dom";
import {Button} from "@mui/material";
import PropTypes from 'prop-types';

const LinkButton = (props) => {
  return (
    <Button {...props} component={Link}>{props.children}</Button>
  );
};

LinkButton.propTypes = {
  to:PropTypes.string.isRequired,
  sx:PropTypes.object,
  variant:PropTypes.oneOf(["contained", "text", "outlined"]),
  color:PropTypes.oneOf(["success", "info", "primary",'error','secondary', 'warning', 'inherit'])
}

export default LinkButton;