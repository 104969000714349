import React from 'react';
import {Container, Typography} from "@mui/material";

function PageNotExists(props) {
  return (
    <Container maxWidth={"sm"} sx={{marginTop:'120px'}}>
      <Typography align={"center"}>Нет доступа!</Typography>
    </Container>

  );
}

export default PageNotExists;