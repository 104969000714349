import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import {
  Box, Button,
  CircularProgress, FormControlLabel, Pagination, Switch, TextField, Typography
} from "@mui/material";
import AdmUnitTable from "../../components/Adm/AdmUnitTable";
import Utils from "../../utils";

function AdmKeeperUnitListPage(props) {

  document.title = 'Позиции меню Keeper';

  const [cat, setCat] = useState({});
  const [search, setSearch] = useState('');
  const pagination = cat.pagination;
  const [loading, setLoading] = useState(true);
  const [onlyActive, setOnlyActive]= useState(false);
  const [styles, setStyles] = useState([]);

  const {place, id} = useParams();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };


  const reloadPage = () =>{
    setLoading(true);
    Api.post('/adm/keeper/get/goods',
      {place:place, id:id,page:page, search:search, active:onlyActive})
      .then(res => res.ok ? res.json() : null)
      .then((data) => {
        setCat(data);
        Api.post('/company/styles',
          {place:place, type:"goods"})
          .then(res => res.ok ? res.json() : null)
          .then((data) => setStyles(data.styles ?? []))
          .catch()
          .finally(() => setLoading(false))
      })
      .catch()
      .finally(()=>  {})
  };

  const handleSearch = (e)=>{
    if(e.key=='Enter' || e.keyCode == 13){
      setSearch(e.target.value);
      reloadPage();
    }
  }

  useEffect(()=>{
    reloadPage();
  }, [place, id, page, onlyActive])
  const goBack = () => navigate(-1);

  if(loading)
    return (<CircularProgress />);
  const updateRow = (index, newValue)=>{
    let goods = cat.goods;
    goods[index] = newValue;
    cat.goods = goods;
    setCat({...cat});
  }

  const getDefaultForNew = ()=> {
    return {
      id:0,
      name:'Новое блюдо',
      description:"Описание нового блюда",
      category_id:0,
      keeper_category_id:id,
      status:'active',
      image:Utils.goodsDefaultImage(),
      sku:'',
      contents:'',
      tags:'[]',
      data:'{}',
      price:0
    }
  }


  return (
    <>
      <Box py={'0.5rem'} display={'flex'} gap={'10px'} justifyContent={'space-between'}>
        <Typography variant={'h5'}>
          {cat.category.name ? `Позиции из категории Keeper '${cat.category.name}'` : "Все позиции из Keeper"}
        </Typography>
        <Box>
          <FormControlLabel control={<Switch
            checked={onlyActive}
            onChange={(e)=>{setOnlyActive(e.target.checked);}}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Только активные" />
          <TextField variant={'outlined'} label='Поиск' type={'search'} value={search}
                     size="small"
                     onChange={(e)=>{setSearch(e.target.value)}}
              onKeyDown={handleSearch}
          />
          <Button variant={'contained'} onClick={reloadPage}>Поиск</Button>
        </Box>

      </Box>
      <Box py={'0.5rem'} display={'flex'} gap={'10px'}>
        <Button onClick={()=>navigate(-1)} variant={"outlined"}>Назад</Button>
      </Box>
      {pagination && pagination.totalPages > 1 &&
        <Pagination count={pagination.totalPages} page={page}
                    onChange={handlePageChange}  variant="outlined" shape="rounded"
        />
      }

      <AdmUnitTable goods={cat.goods} styles={styles} updateRow={updateRow} />

      {pagination && pagination.totalPages > 1 &&
        <Pagination count={pagination.totalPages} page={page}
                    onChange={handlePageChange}  variant="outlined" shape="rounded"
        />
      }
    </>
  );
}

export default AdmKeeperUnitListPage;