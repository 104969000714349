import React from 'react';
import {Typography} from "@mui/material";

function MultilineText(props) {
  return (
    <>
      {props.text.split(/\r?\n/).map((i, index)=>
        <Typography {...props} key={index}>{i}</Typography>
      )}
    </>
  );
}

export default MultilineText;