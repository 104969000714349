import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {Box, Container, Grid, Paper, Stack, Typography} from "@mui/material";
import Api from "../../api";
import ImageHeader from "../../components/Qr/ImageHeader";
import MainBlock from "../../components/Qr/UnitPage/MainBlock";
import InfoBlock from "../../components/Qr/UnitPage/InfoBlock";
import LoadingBlock from "../../components/Qr/LoadingBlock";
import {useSelector} from "react-redux";
import CartBlock from "../../components/Qr/Cart/CartBlock";

function QrCart(props) {
  document.title = 'Корзина';
  const order = useSelector(state => state.order);
  let {place, style} = useSelector(state=> state.place)
  const defaultStyle = Api.processStyle(style, 'textBlock');

  const mapOrder = ()=>{
    return Object.keys(order.current.items).map(key => {
      let i = order.current.items[key];
      // console.log(key);
      return (
        <Box key={key}>{i.id}: {i.name}</Box>
      )
    });
  }
  // console.log(order);
  return (
    <>
      <ImageHeader image={Api.fileUrl(place.image) }  cover noMargin  height={"190px"}/>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{borderRadius: '10px', position:'relative',transform:"translateY(-20px)",
              zIndex:50, mb:".5rem", ...defaultStyle}}>
              <Typography variant={'h5'} textAlign={'center'} py={2}>
                Корзина
              </Typography>
            </Paper>

          </Grid>

          <Grid item xs={12}>
            <Paper sx={{borderRadius: '10px', position:'relative',
              zIndex:50, mb:".5rem", padding:2, ...defaultStyle}}>
              {order.current.itemsCount > 0
                ? <CartBlock />
                : <Typography textAlign={'center'}>
                  Корзина пуста. <Link to={`/p/${place.code}/menu`}>Добавить в корзину</Link>
                </Typography>}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>


  );
}

export default QrCart;