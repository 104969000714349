import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {snackShow} from "../../store/snackbarSlice";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select, Stack,
  TextareaAutosize,
  TextField,
  Typography
} from "@mui/material";
import Api from "../../api";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import 'dayjs/locale/ru';
import Utils from "../../utils";
import dayjs from "dayjs";
import ImageSelectBlock from "../Adm/ImageSelectBlock";

function ReservationEdit(props) {
  const newItem = !!props.reservation;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {place} = useParams();
  const [urlParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [data, setData] = useState( props.reservation
    ? {...props.reservation,phone:props.reservation.phone,
      reserved_to:Utils.getTime(props.reservation.reserved_to)}
    : {name:'', people_count:1, table_name:'', phone:urlParams.get('phone') ?? '+7',
      comment:'',reserved_to:Utils.getTime(),status:'active',preorder:''})
  const [error, setError] = useState({invalid:false});
  const showSnack = (message, type='success') => {
    dispatch(snackShow({message, type}))
  }
  const validate = () => {
    let errors = {invalid:false};
    if(!data.name){
      errors.invalid = true;
      errors.name = 'Нет имени';
    }
    if(!data.phone || !data.phone.replace(/\D+/g, '').match(/^[78]?\d{10}$/)) {
      errors.phone = 'Неверный формат телефона';
      errors.invalid = true;
    }
    if(!data.reserved_to) {
      errors.reserved_to = 'Не указана дата';
      errors.invalid = true;
    }
    setError(errors);
    return errors;
  }
  const update = (field, value, validation='') =>{
    setError({...error, [field]:''});
    setData({...data, [field]:value});
    // console.log(value);
    if(validation == 'phone'){
      let ph = value.replace(/\D+/g, '');
      if(!ph.match(/^[78]?\d{10}$/)){
        setError({...error, [field]:'Неверный формат телефона'})
        return;
      }
    }
  }
  const save = () => {

    let errors = validate();
    if(errors.invalid){
      return
    }

    try {
      setLoading(true);
      const formData = new FormData();
      for ( var key in data ) {
        if(key == 'reserved_to') {
          let t = Utils.getTime(data.reserved_to);
          formData.append(`data[${key}]`, t);
          continue;
        }
        formData.append(`data[${key}]`, data[key]);
      }
      // Update the formData object
      if(selectedImage) {
        formData.append(
          "image",
          selectedImage,
          selectedImage.name
        );
      }
      let endpoint = props.reservation?.id
        ? `reservations/${place}/${props.reservation.id}`
        : `reservations/${place}`;
      Api.postForm(endpoint, formData, {}, true)
        .then(resp => {
          if(data.error){
            showSnack(data.error, 'error');
            return;
          }
          if(typeof props.onSave == 'function')
            props.onSave();
          // navigate(`/hostess/${place}/reservations`);
        }).catch(error=>{
          let errdata = error.response.data;
          showSnack("Ошибка: " + errdata.error, 'error');
          if(typeof props.onError == 'function')
            props.onError();
        })
        .finally(()=>{
          setLoading(false);
        })
    } catch (e){
      setLoading(false);
      setError({invalid: true,message:e.error})
    }

  };
  const closeHandler = ()=> {
    if(typeof props.onClose == 'function')
      props.onClose();
  }
  const canBeEdited = () => {
    return !props.reservation || props.adm || props.reservation.status == 'active'
  }

  return (

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          {/*{props.reservation?.reserved_to ?? '--'} / {data.reserved_to.toISOString() ?? '--'}*/}
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={4}>
          <TextField name={'name'} sx={{width:'100%'}}  label={'Имя'} value={data.name ?? ''}
                     error={(error.name?.length > 0)} helperText={error.name} disabled={loading}
                     inputProps={{ maxLength: 50 }}
                     onInput={(e)=>update('name', e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <TextField sx={{width:'100%'}} name={'phone'} label={'Телефон'} value={data.phone ?? ''}
             error={(error.phone?.length > 0)} helperText={error.phone} disabled={loading}
             inputProps={{ maxLength: 20 }}
             onInput={(e)=>update('phone', e.target.value, 'phone')} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={2}>
          <TextField sx={{width:"100%"}} disabled={loading}
                     label="Дата бронирования" type={'datetime-local'}
                     value={data.reserved_to ?? ''} error={error.reserved_to?.length > 0}
                     helperText={error.reserved_to}
                     onChange={(e) => {update('reserved_to', e.target.value)}}
          />
          {error.reserved_to && <div style={{color:'darkred'}}>{error.reserved_to}</div>}
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField name={'people_count'} sx={{width:'100%'}}  label={'Количество человек'}
                     value={data.people_count ?? 1} inputProps={{ maxLength: 20 }}
                     disabled={loading}
                     onInput={(e)=>update('people_count', e.target.value)} />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField sx={{width:'100%'}} name={'table_name'} label={'Стол'} value={data.table_name ?? ''}
                     disabled={loading}  onInput={(e)=>update('table_name', e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <TextField name={'comment'} sx={{width:'100%'}} label={'Комментарий'} value={data.comment ?? ''}
                     error={(error.comment?.length > 0)} helperText={error.comment} disabled={loading}
                     onInput={(e)=>update('comment', e.target.value)} />
          {/*<TextareaAutosize  name={'comment'} sx={{width:'100%'}}*/}
          {/*           label={'Комментарий'} value={data.comment ?? ''}*/}
          {/*           error={error.comment} helperText={error.comment} disabled={loading}*/}
          {/*           onInput={(e)=>update('comment', e.target.value)} />*/}
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <FormControl variant="outlined" sx={{ width: '100%'}}
                   disabled={!newItem || loading || !canBeEdited()}>
            <InputLabel id="select-status-label">Статус</InputLabel>
            <Select disabled={!newItem}
              labelId="select-status-label"
              id="select-status"
              value={data.status}
              onChange={(e)=>update('status', e.target.value)}
              label="Статус"
            >
              <MenuItem value={'active'}>
                Активно
              </MenuItem>
              {/*<MenuItem value={'in_work'}>В работе</MenuItem>*/}
              <MenuItem value={'cancelled'}>
                Отменено</MenuItem>
              <MenuItem value={'completed'}>
                Выполнено
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ImageSelectBlock setImage={setSelectedImage} image={data.image} />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <TextField name={'comment'} sx={{width:'100%'}} label={'Предзаказ'} value={data.preorder ?? ''}
                     error={(error.preorder?.length > 0)} helperText={error.preorder} disabled={loading}
                     multiline rows={7}
                     onInput={(e)=>update('preorder', e.target.value)} />
        </Grid>
        {error.invalid && error.message
          && <Grid item xs={12}>
            <Typography color={'darkred'}>{error.message}</Typography>
          </Grid>}
        <Grid item xs={12}>
          <Stack spacing={2} direction={'row'}>
            <Button onClick={()=>save()} variant={'contained'}
              disabled={!canBeEdited()}
            >Сохранить</Button>
            {(props.showCancel ?? true) && <Button onClick={closeHandler}>Отмена</Button>}
          </Stack>

        </Grid>
      </Grid>
  );
}

export default ReservationEdit;