import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import Api from "../api";
import {fetchPlace} from "./placeSlice";
export const fetchCode = createAsyncThunk(
  'user/fetchCode',
  async function(place){
    let code = localStorage.getItem('qr_code');
    if(!code)
      return null;
    const resp = await Api.get(`/codes/${place}/${code}`);
    return resp.ok ? resp.json() : null;
  }
)
const codeSlice = createSlice({
  name:'code',
  initialState: {
    code: null,
    sum: 0,
    loading:true
  },
  reducers:{
    setCode(state, action){
      // console.log('data', action.payload);
      state.code = action.payload?.code;
      state.sum = action.payload?.sum ?? 0;
      // console.log('token', localStorage.getItem('token'))
    }
  },
  extraReducers:{
    [fetchCode.pending]:(state, action)=>{
      state.loading=true;
    },
    [fetchCode.fulfilled]:(state, action)=>{
      state.loading=false;
      state.code = action.payload?.item;
      state.sum = action.payload?.sum ?? 0;
    },
    [fetchCode.rejected]:(state, action)=>{
      state.loading=false;
      state.code = null;
      state.sum = 0;
    }
  }
})



export const {setCode} = codeSlice.actions;
export default codeSlice.reducer;