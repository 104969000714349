import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import {
  Box, Button,CircularProgress,Tab, Tabs,
  TextField,
} from "@mui/material";
import CommonStyles from "../../components/Adm/Style/CommonStyles";
import {useDispatch, useSelector} from "react-redux";
import {loadStyle, updateStyle} from "../../store/sandboxStyleSlice";
import {snackShow} from "../../store/snackbarSlice";
import PageNotExistsPage from "../PageNotExistsPage";
import CategoryStyles from "../../components/Adm/Style/CategoryStyles";

function AdmStyleEditPage(props) {

  document.title = 'Редактирование стиля';
  const defaultStyleData = Api.getDefaultStyle();
  const {place, id = 0} = useParams()
  const [style, setStyle] = useState({});
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isSandbox, setIsSandbox] = useState(Api.isSandbox());
  const [notExists, setNotExists] = useState(false);
  const [images, setImages] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styleData = useSelector(state => state.sandboxStyle.style);

  const reloadPage = () =>{
    setLoading(true);
    if(id === 'new'){
      setStyle({type:'place', data:defaultStyleData, name:'Новый стиль'});
      dispatch(loadStyle(defaultStyleData));
      setLoading(false);
      return;
    }
    Api.postForm('/company/styles/find',
      {place:place, id:id})
      .then((resp) => {
        let data = resp.data;
        if(!data) {
          setNotExists(true);
          return;
        }
        if(data.style.type !== 'place'){
          setNotExists(true);
          return;
        }

        setStyle(data.style);

        let d = defaultStyleData;
        try {
          d = (typeof data.style.data === 'string') ? JSON.parse(data.style.data) : data.style.data;
        } catch(e) {
          d = defaultStyleData;
        }
        dispatch(loadStyle(d))

      })
      .catch()
      .finally(() => setLoading(false))
  };
  useEffect(()=>{
    reloadPage();
  },[place, id])
  const save = () => {
    setLoading(true);
    style.type = 'place';
    style.data = styleData;
    style.id = id;
    console.log("saving", style, place, images);

    const formData = new FormData();
    formData.set('place', place);
    formData.append('style', JSON.stringify(style));
    for (let key in images ) {
      if(images[key]) {
        formData.append(`images-${key}`, images[key], images[key].name);
      }
    }


    Api.postForm('/company/styles/update', formData, {}, true)
      .then(resp => {
        let item = resp.data.item
        // console.log("resp", resp);
        if(typeof item.data === "string")
          item.data = JSON.parse(item.data);
        setStyle(item);
        dispatch(loadStyle(item.data));
        showSnack("Данные успешно сохранены");
        setIsSandbox(false)
        setImages({});
        if(item.id > 0 && id==='new') {
          navigate(`/adm/${place}/style/${item.id}`)
        }
      }).catch(error=>{
        console.log("error", error);
        let errdata = error?.response?.data ?? "";
        showSnack("Ошибка при сохранении данных: " + errdata.error, 'error');
      })
      .finally(()=>{
        setLoading(false);
      })
  };

  const toggleSandbox = () => {
    Api.setSandbox(!isSandbox, styleData);
    setIsSandbox(!isSandbox);
  };
  const loadFromSandbox = () => {
    if(Api.getSandboxStyle())
      dispatch(updateStyle(Api.getSandboxStyle()));
  };

  const showSnack = (message, type='success') => {
    dispatch(snackShow({message, type}))
  }

  const setImage = (part, img) => {
    setImages({...images, [part]:img})
  }

  if(loading)
    return (<CircularProgress />);
  if(notExists)
    return <PageNotExistsPage />

  return (
    <Box>
      <Box my={1}>
        <Button variant={isSandbox ? 'contained' : 'outlined'}
                color={'secondary'}
                onClick={toggleSandbox} sx={{marginRight:"5px"}}>
          {isSandbox ? 'Отключить режим песочницы' : 'Включить режим песочницы'}
        </Button>
        <Button variant={'outlined'} color={'secondary'}
                onClick={loadFromSandbox}>
          Последний стиль в песочнице
        </Button>
      </Box>

      <Box sx={{ width: '100%' }} component={'form'} onSubmit={save}>
        <Box>
          <TextField size="small"
                     margin="dense"
                     name='name' value={style.name ?? ''}
                     onInput={(e)=>setStyle({name: e.target.value})}
                     label="Название" fullWidth variant="outlined"
          />
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={(e, newValue)=> setTab(newValue)} aria-label="basic tabs example">
            <Tab label="Стили по умолчанию" />
            <Tab label="Стили категорий" />
            <Tab label="Стили позиций" />
          </Tabs>
        </Box>
        {tab === 0 && <CommonStyles style={style} setImage={setImage}/>}
        {tab === 1 && <CategoryStyles style={style} setImage={setImage}/>}

        <Box my={3}>
          <Button variant={'contained'} onClick={save}>Сохранить</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AdmStyleEditPage;