import React, {useEffect} from 'react';
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {Box, Button, createTheme, Stack} from "@mui/material";
import Api from "../../api";
import Snack from "../ui/Snack";
import {useDispatch, useSelector} from "react-redux";
import {fetchPlace} from "../../store/placeSlice";
import LoadingPage from "../../Pages/LoadingPage";
import PageNotExistsPage from "../../Pages/PageNotExistsPage";
import {fetchUser} from "../../store/userSlice";
import UnauthorizedPage from "../../Pages/UnauthorizedPage";
import LinkButton from "../Adm/LinkButton";
import {fetchStaff} from "../../store/staffSlice";

const HostessSideBar = (props)=>{
  const {place} = useParams();
  return(
    <Box displayPrint={'none'}
         bgcolor={'#222222'}
         color={'white'}
         minWidth={{xs:"100%",md:'300px'}}
         minHeight={{xs:"auto",md:'100vh'}}
    >
      <Stack spacing={2} padding={2} sx={{}}>

        <LinkButton to={`/hostess/${place}`} variant={'contained'} color={'primary'}
                    fullWidth>Бронирование</LinkButton>
        {/*<LinkButton to={`/hostess/${place}/reservations`} variant={'contained'} color={'primary'}*/}
        {/*            fullWidth>Бронирование</LinkButton>*/}
        <LinkButton to={`/hostess/${place}/codes`} variant={'contained'} color={'primary'}
                    fullWidth>QR-коды</LinkButton>
        <hr/>
        <Button variant={'contained'} color={'error'} fullWidth
                onClick={()=>Api.logout(place)}>Выход</Button>
      </Stack>
    </Box>
  )
}
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: [
        'Jost',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  },
})

function HostessLayout({children}) {

  const dispatch = useDispatch();
  const {place} = useParams();
  const navigate = useNavigate();
  useEffect(()=>{
    dispatch(fetchUser())
    dispatch(fetchPlace({place:place}))
    dispatch(fetchStaff({place:place}))
  },[place])
  const currentPlace = useSelector(state => state.place);
  const user = useSelector(state => state.user);
  const staff = useSelector(state => state.staff);
  if(currentPlace.loading || staff.loading || !user.loaded)
    return <LoadingPage />
  if(!user.user || !user.place) {
    navigate('/login?from=hostess');
  }
  if(!user.places.filter((i)=>i.code === place))
    return <UnauthorizedPage />
  if(!currentPlace.place)
    return <PageNotExistsPage />

  return (
    <>
      {!currentPlace.place && <PageNotExistsPage />}
      {currentPlace.place &&
        <Box display={'flex'} flexDirection={{xs:'column',md:'row'}} gap={2} width={'100%'}>
          <HostessSideBar />
          <Box minHeight={'100vh'} flexGrow={1}>
            <main style={{padding:'10px'}}>
              <Outlet />
            </main>
          </Box>
        </Box>}
      <Snack />
    </>
  );
}

export default HostessLayout;