import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {
  Alert, Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid, Snackbar,
  Stack,
  TextField, Typography
} from "@mui/material";
import LoadingPage from "../LoadingPage";
import _ from 'lodash';
import Api from "../../api";
import PageNotExistsPage from "../PageNotExistsPage";
import Image from "mui-image";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ImageSelectBlock from "../../components/Adm/ImageSelectBlock";

function AdmIndexPage(props) {

  document.title = 'Настройки заведения';
  const {place} = useParams();
  const [loading, setLoading] = useState(true);
  const [styles, setStyles] = React.useState([{name:"По умолчанию", id:0}]);
  const [placeData, setPlaceData] = useState([])
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedMenuImage, setSelectedMenuImage] = React.useState(null);
  const [selectedMenuBtnImage, setSelectedMenuBtnImage] = React.useState(null);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snack, setSnack] = React.useState({message:'Данные успешно сохранены', type:'success'})

  const reloadPage = () =>{
    setLoading(true);
    Api.post('/adm/place/find',
      {place:place})
      .then(res => res.ok ? res.json() : null)
      .then((data) => {
        setPlaceData(data.place);
        setStyles([{name:"По умолчанию", id:0}, ...(data.styles ?? [])]);
      })
      .catch()
      .finally(()=> setLoading(false))
  };
  useEffect(()=>{
    reloadPage();
  }, [place])

  if(loading)
    return <LoadingPage />

  if(!placeData || _.isEmpty(placeData))
    return <PageNotExistsPage />

  const setPlace = (value) => {
    setPlaceData({...placeData, ...value});
  }

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };
  const handleSnackClose = () => {
    setSnackOpen(false);
  };


  const save = () => {
    setLoading(true);
    const formData = new FormData();
    formData.set('place', place);
    for ( var key in placeData ) {
      formData.append(`data[${key}]`, placeData[key]);
    }
    // Update the formData object
    if(selectedImage) {
      formData.append(
        "photo",
        selectedImage,
        selectedImage.name
      );
      setSelectedImage(null);
    }
    if(selectedMenuImage) {
      formData.append(
        "m_menu_image",
        selectedMenuImage,
        selectedMenuImage.name
      );
      setSelectedMenuImage(null);
    }
    if(selectedMenuBtnImage) {
      formData.append(
        "m_menu_btn_image",
        selectedMenuBtnImage,
        selectedMenuBtnImage.name
      );
      setSelectedMenuBtnImage(null);
    }
    Api.postForm('/adm/place/update', formData, {}, true)
      .then(resp => {
        console.log(resp.data);
        setPlaceData(resp.data.item);
        setSnack({...{message:"Данные успешно сохранены", type:'success'}})
        handleSnackOpen();
      }).catch(error=>{
        let errdata = error.response.data;
        setSnack({...{message:"Ошибка при сохранении данных: " + errdata.error, type:'error'}})
        handleSnackOpen();
      })
      .finally(()=>{
        setLoading(false);
      })
  };

  const findStyle = () => {
    let value = _.find(styles, function(option){
      return option.id == placeData.style_id;
    });
    if(!value)
      value = styles[0];
    return value;
  }

  return (
    <Box m={0}>

      <Grid container spacing={2} component={'form'} onSubmit={save}>
        <Grid item md={3}>
          <TextField size="small"
                     margin="dense" disabled={true}
                     name='sku' value={placeData.code}
                     onInput={(e)=>setPlace({code: e.target.value})}
                     label="Код" fullWidth variant="outlined"
          />
        </Grid>
        <Grid item md={3}>
          <Autocomplete
            size="small"
            fullWidth
            disablePortal
            id="combo-box-demo"
            options={styles}
            value={findStyle()}
            isOptionEqualToValue={(option, value) =>
              option.id === value.id
            }
            onChange={(event, newValue)=> {
                setPlace({style_id: newValue ? newValue.id : 0});
              }
            }
            sx={{ width: "100%" }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Текущий пресет" />}
          />
        </Grid>
        <Grid item md={6}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={placeData.status == 'active'}
                        onChange={(event)=>{setPlace({status:event.target.checked?'active':'inactive'})}} />
            }
            label="Активно"
          />
        </Grid>
        <Grid item md={4} xl={3}>
          <Typography fontSize={12}>
            Логотип заведения
          </Typography>
          <Typography fontSize={10} color={'text.secondary'}>
            Изображение должно быть 400х140 пикселей или соответствующее по пропорциям, в идеале с прозрачным
            фоном или подходящим под цвет страницы
          </Typography>
          <ImageSelectBlock setImage={setSelectedImage}
                            image={placeData.image} />


        </Grid>
        <Grid item md={8} xl={9}>
          <Stack spacing={2}>

            <TextField size="small"
                       autoFocus margin="dense"
                       name='name' value={placeData.name}
                       onInput={(e)=>setPlace({name: e.target.value})}
                       label="Название" fullWidth variant="outlined"
            />

            <TextField multiline rows={4} size="small"
                       autoFocus margin="dense"
                       name='description' value={placeData.description}
                       onInput={(e)=>setPlace({description: e.target.value})}
                       label="Описание" fullWidth variant="outlined"
            />
          </Stack>

        </Grid>

        <Grid item md={4} xl={3}>
          <ImageSelectBlock setImage={setSelectedMenuBtnImage}
                      image={placeData.menu_btn_image} />
          <Typography fontSize={12}>
            Кнопка меню на главной странице
          </Typography>
        </Grid>
        <Grid item md={4} xl={3}>
          <ImageSelectBlock setImage={setSelectedMenuImage}
                            image={placeData.menu_image} />
          <Typography fontSize={12}>
            Заглавное изображение меню
          </Typography>
          <Typography fontSize={10} color={'text.secondary'}>
            Изображение должно быть 400х140 пикселей или соответствующее по пропорциям, в идеале с прозрачным
            фоном или подходящим под цвет страницы.
          </Typography>
        </Grid>
        <Grid item md={12}>
          <TextField size="small"
                     margin="dense"
                     name='address' value={placeData.address}
                     onInput={(e)=>setPlace({address: e.target.value})}
                     label="Адрес" fullWidth variant="outlined"
          />
        </Grid>
        <Grid item md={12}>
          <TextField size="small"
                     margin="dense"
                     name='map_link' value={placeData.map_link??''}
                     onInput={(e)=>setPlace({map_link: e.target.value})}
                     label="Тег карты" fullWidth variant="outlined"
          />
        </Grid>
        <Grid item md={4}>
          <TextField size="small"
                     margin="dense"
                     name='phone' value={placeData.phone}
                     onInput={(e)=>setPlace({phone: e.target.value})}
                     label="Телефон 1" fullWidth variant="outlined"
          />
        </Grid>
        <Grid item md={4}>
          <TextField size="small"
                     margin="dense"
                     name='phone' value={placeData.phone2}
                     onInput={(e)=>setPlace({phone2: e.target.value})}
                     label="Телефон 2" fullWidth variant="outlined"
          />
        </Grid>
        <Grid item md={4}>
          <TextField size="small"
                     margin="dense" type={'email'}
                     name='email' value={placeData.email}
                     onInput={(e)=>setPlace({email: e.target.value})}
                     label="Email" fullWidth variant="outlined"
          />
        </Grid>
        <Grid item md={4}>
          <Button variant={'contained'} onClick={save}>Сохранить</Button>
        </Grid>

      </Grid>
      <Snackbar open={snackOpen} autoHideDuration={6000}
                onClose={handleSnackClose} anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert onClose={handleSnackClose} severity={snack.type} sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </Box>

  );
}

export default AdmIndexPage;