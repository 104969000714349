import {createSlice} from '@reduxjs/toolkit'

const snackbarSlice = createSlice({
  name:'snackbar',
  initialState: {
    message:"",
    type:'success',
    open:false,
  },
  reducers:{
    snackShow(state, action){
      state.open = true;
      if(typeof action.payload === 'string'){
        state.type = 'success';
        state.message = action.payload;
      } else {
        state.type = action.payload.type ?? 'success';
        state.message = action.payload.message;
      }
    },
    snackHide(state, action){
      state.open = false;
    }
  }
})



export const {snackShow, snackHide} = snackbarSlice.actions;
export default snackbarSlice.reducer;