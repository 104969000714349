import React, {useState} from 'react';
import {Box, Chip, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {MuiColorInput} from "mui-color-input";

function AdmTagElement({tagData, updateTag, removeTag, index}) {
  const [tag, setTag] = useState(tagData);


  const setTagData = (value) => {
    let newValue = {...tag, ...value};
    setTag(newValue);
    updateTag(newValue, index);
  }
  return (
    <Paper sx={{margin:"5px", padding:'5px', position:'relative'}}>
      <IconButton
        aria-label="close"
        onClick={()=>{removeTag(index)}}
        size="small"
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex:20,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon  size="small" />
      </IconButton>
      <TextField size="small"
           autoFocus margin="dense"
           name='description' value={tag.text}
           onInput={(e)=>setTagData({text: e.target.value})}
           label="Текст" fullWidth variant="outlined"/>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small">Макет</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={tag.variant}
          label="Age"
          onChange={(e)=>{setTagData({variant: e.target.value})}}
        >
          <MenuItem value="">
            <em>Свои цвета</em>
          </MenuItem>
          <MenuItem value={"default"}>
            <Chip color={"default"} size={'small'} label={'Default'} />
          </MenuItem>
          <MenuItem value={"error"}>
            <Chip color={"error"} size={'small'} label={'Error'} />
          </MenuItem>
          <MenuItem value={"secondary"}>
            <Chip color={"secondary"} size={'small'} label={'Secondary'} />
          </MenuItem>
          <MenuItem value={"primary"}>
            <Chip color={"primary"} size={'small'} label={'Primary'} />
          </MenuItem>
          <MenuItem value={"info"}>
            <Chip color={"info"} size={'small'} label={'Info'} />
          </MenuItem>
          <MenuItem value={"warning"}>
            <Chip color={"warning"} size={'small'} label={'Warning'} />
          </MenuItem>
        </Select>
      </FormControl>
      {!tag.variant && <Box my={2} display={'flex'} justifyContent={'space-between'}>
        <Box textAlign={"center"}>
          Текст
          <MuiColorInput value={tag.color}  size="small" variant="outlined"
                         onChange={(e)=>setTagData({color:e})}/>
        </Box>
        <Box textAlign={"center"}>
          Фон
          <MuiColorInput value={tag.bgColor}   size="small" variant="outlined"
                         onChange={(e)=>setTagData({bgColor:e})}/>
        </Box>
      </Box>}
    </Paper>
  );
}

export default AdmTagElement;