import React, {useEffect, useState} from 'react';
import {
  Box,
  Container,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer, Typography
} from "@mui/material";
import HeaderButton from "./HeaderButton";
import MenuIcon from '@mui/icons-material/Menu';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import HomeIcon from '@mui/icons-material/Home';
import {Link, Navigate, useNavigate, useParams} from "react-router-dom";
import HeaderLink from "./HeaderLink";
import Api from "../../api";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import InfoIcon from '@mui/icons-material/Info';
import DehazeIcon from '@mui/icons-material/Dehaze';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {useDispatch, useSelector} from "react-redux";
import {fetchCode} from "../../store/codeSlice";

function Header(props) {

  const {place} = useSelector(state => state.place);

  const [drawer, setDrawer] = useState(false);
  const [categories, setCategories] = useState({})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const order = useSelector(state => state.order.current);
  const orderList = useSelector(state => state.order.orders);
  const codeData = useSelector(state => state.code);

  const goToMenu = ()=>{ toggleDrawer(false); navigate(`/p/${place.code}/menu`);}
  const goToMain = ()=>{ toggleDrawer(false); navigate(`/p/${place.code}`);}
  const goToAbout = ()=>{ toggleDrawer(false); navigate(`/p/${place.code}/about`);}
  const goToCart = ()=>{ toggleDrawer(false); navigate(`/p/${place.code}/cart`);}
  const goToCode = ()=>{ toggleDrawer(false); navigate(`/p/${place.code}/codes/${codeData.code.code}`);}
  const goToAskForCode = ()=>{ toggleDrawer(false); navigate(`/p/${place.code}/ask_code`);}
  const goToOrders = ()=>{ toggleDrawer(false); navigate(`/p/${place.code}/orders`);}
  const goToLanding = ()=>{ toggleDrawer(false); navigate(`/`);}

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  useEffect(()=>{
    Api.post('/categories', {place:place.code,onlyactive:true})
      .then(res => res.ok ? res.json() : null)
      .then((data) => setCategories(data))
      .catch()
      .finally(()=>  {})
  },[place])


  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={goToMain}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={place.name} />
          </ListItemButton>
        </ListItem>

        {(place?.data?.show_code_links && codeData?.code) && <ListItem disablePadding>
          <ListItemButton onClick={goToCode}>
            <ListItemIcon>
              <ShoppingCartIcon color={'success'} />
            </ListItemIcon>
            <ListItemText color={'red'} primary={
              <Typography color={'success.main'}>
                Заказ на {codeData.sum} руб.
              </Typography>

            } />
          </ListItemButton>
        </ListItem>}
        {(place?.data?.show_code_links && !codeData?.code) && <ListItem disablePadding>
          <ListItemButton onClick={goToAskForCode}>
            <ListItemIcon>
              <ShoppingCartIcon color={'success'} />
            </ListItemIcon>
            <ListItemText color={'red'} primary={
              <Typography color={'success.main'}>
                Мой заказ
              </Typography>

            } />
          </ListItemButton>
        </ListItem>}
        {(order?.itemsCount > 0) && <ListItem disablePadding>
          <ListItemButton onClick={goToCart}>
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary={`Корзина (${order.itemsCount})`} />
          </ListItemButton>
        </ListItem>}
        {(orderList?.length > 0) && <ListItem disablePadding>
          <ListItemButton onClick={goToCart}>
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary={`Заказы (${orderList.length})`} />
          </ListItemButton>
        </ListItem>}
        <ListItem disablePadding>
          <ListItemButton onClick={goToAbout}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary={"Info"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={goToMenu}>
            <ListItemIcon>
              <RestaurantMenuIcon />
            </ListItemIcon>
            <ListItemText primary={"Меню"} />
          </ListItemButton>
        </ListItem>
        {categories.categories && categories.categories.length > 0 &&
          categories.categories.map((category) =>
            <ListItem disablePadding key={category.id}>
              <ListItemButton sx={{padding:"0 1rem"}} onClick={()=>{navigate(`/p/${place.code}/menu/${category.id}`)}}>
                <ListItemIcon>
                  <DehazeIcon/>
                </ListItemIcon>
                <ListItemText primary={category.name}/>
              </ListItemButton>
            </ListItem>
          )
        }
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={goToLanding}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary={"О Lite Menu"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );



  return (
      <Box
        sx={{position: 'absolute', zIndex:100, color:'white',
        background:"linear-gradient(180deg, rgba(0,0,0,0.7559173327534139) 0%, rgba(0,0,0,0.55) 53%, rgba(0,0,0,0) 100%)",
        // backdropFilter:'blur(5px)',
        padding: '8px 16px'}}
        width={'100%'}
      >
        <Container>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <HeaderLink to={-1}><ArrowBackIosNewIcon fontSize={'small'} /></HeaderLink>
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <React.Fragment>
                <HeaderButton onClick={toggleDrawer(true)}><MenuIcon /></HeaderButton>
                <SwipeableDrawer
                  anchor={'right'}
                  open={drawer}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                >
                  {list('right')}
                </SwipeableDrawer>
              </React.Fragment>
            </Box>
          </Box>

        </Container>
      </Box>

  );
}

export default Header;