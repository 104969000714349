import React from 'react';
import {Box, Button,Stack} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import LinkButton from "./LinkButton";

function AdmSideBar(props) {
  const {place} = useParams();
  const gotoLogout = ()=>{
    Api.logout(place);
  }


  return (
    <Box display={'flex'} flexDirection={"column"} height={'100%'} minHeight={"100vh"}
         p={"10px"} color={"white"} sx={{background: "#454D55"}}  displayPrint={'none'}>
      <Stack spacing={2}>

        <LinkButton variant={'contained'} to={`/adm/${place}`}>Главная страница</LinkButton>
        <LinkButton variant={'contained'} to={`/adm/${place}/unit`}>Позиции</LinkButton>
        <LinkButton variant={'contained'} to={`/adm/${place}/category`}>Категории на сайте</LinkButton>
        <LinkButton variant={'contained'} to={`/adm/${place}/tags`}>Теги</LinkButton>
        <LinkButton variant={'contained'} to={`/adm/${place}/reservations`}>Бронирования</LinkButton>
        <LinkButton variant={'contained'} to={`/adm/${place}/codes`}>QR-коды</LinkButton>
        <hr />
        {/*<LinkButton variant={'contained'} to={`/adm/${place}/keeper`}>Категории Keeper</LinkButton>*/}
        {/*<LinkButton variant={'contained'} to={`/adm/${place}/component`}>Топпинги</LinkButton>*/}
        <LinkButton variant={'contained'} to={`/adm/${place}/style`}>Стили</LinkButton>
        <LinkButton variant={'contained'} to={`/adm/${place}/place`}>Настройки заведения</LinkButton>
        <LinkButton variant={'contained'} to={`/adm/${place}/users`}>Персонал</LinkButton>
        <hr />

        <LinkButton variant={'contained'} to={`/hostess/${place}`}>Хостесс</LinkButton>
        <hr />
        <LinkButton variant={'contained'} color={'secondary'} target={'_blank'}
                    to={`/p/${place}`}>На сайт</LinkButton>
        <hr />
        <Button variant={'contained'} color={'error'} fullWidth
                onClick={gotoLogout}>Выход</Button>
      </Stack>
    </Box>
  );
}

export default AdmSideBar;