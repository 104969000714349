import React from 'react';
import Api from "../../api";
import {Box, Card, CardContent, CardMedia, Chip, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import TagChip from "./TagChip";
import {useSelector} from "react-redux";

function CategoryLinkCard({category, width=130}) {
  let {place, style} = useSelector(state => state.place);
  let individualStyle = Api.processStyle(style, 'categoryCard',
    'categoryList', category.id);

  const tags = category.tags ?? [];
  return (
    <Link to={`/p/${place.code}/menu/${category.id}`} style={{textDecoration: "none",width: width,
      minHeight:'150px'}}>
      <Card sx={{...{ width: width, height: '100%', borderRadius:'8px'},
        ...individualStyle}} >
        <CardMedia
          component="img"
          height="120"
          image={Api.fileUrl(category.image)}
          alt={category.name}
        >
          {/*<img src="/img/default.png" alt={category.name}></img>*/}
        </CardMedia>
        <CardContent sx={{padding: "8px"}}>
          <Typography gutterBottom fontSize={12} component="div" fontWeight={'bold'}>
            {category.name}
          </Typography>
          <Box sx={{display: 'flex', flexWrap:'wrap', columnGap: 0.5}}>
            {tags.map((i, key) =>
              <TagChip tag={i} key={key} />
            )}
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
}

export default CategoryLinkCard;