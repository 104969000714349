import React from 'react';
import {Box, Paper, Typography} from "@mui/material";
import SpreadTextElement from "../SpreadTextElement";
import Api from "../../../api";
import {useSelector} from "react-redux";

function InfoBlock({unit, noTrans = false, my=0}) {

  let {place, style} = useSelector(state => state.place);
  let individualStyle = Api.processStyle(style, 'goods');
  const {ecost, weight, volume} = JSON.parse(unit.data) ?? {};
  if(!unit.contents.trim() && !ecost && !weight && !volume)
    return (<></>);

  return (
    <Paper sx={{...{borderRadius: '10px', transform:noTrans ? "translateY(-20px)" : 'unset',
        padding: '0.75rem', my:my},
      ...individualStyle}}>
      {unit.contents.trim() &&
        <Box marginBottom={'0.5rem'}>
          <Typography fontWeight={'bold'} fontSize={16}>
            Состав
          </Typography>
          <Box marginBottom={'0.5rem'}>
            <Typography fontSize={14} variant={"subtitle1"} color={individualStyle.secondaryColor} lineHeight={"130%"}>
              {unit.contents}
            </Typography>
          </Box>
        </Box>
      }



      {ecost && ecost.show !== 0 &&
        <Box>
          <Box marginBottom={'0.5rem'}>
            <Typography fontWeight={'bold'} fontSize={16}>
              Пищевая ценность на 100 гр.
            </Typography>
          </Box>
          {ecost && <SpreadTextElement color={individualStyle.secondaryColor}
                                       text={"Энергетическая ценность"} value={(ecost.ecost ?? 0)}/>}
          {ecost && <SpreadTextElement color={individualStyle.secondaryColor}
                                       text={"Белки"} value={(ecost.protein ?? 0) + " гр"}/>}
          {ecost && <SpreadTextElement color={individualStyle.secondaryColor}
                                       text={"Жиры"} value={(ecost.lipid ?? 0) + " гр"}/>}
          {ecost && <SpreadTextElement color={individualStyle.secondaryColor}
                                       text={"Углеводы"} value={(ecost.carbohydrate ?? 0) + " гр"}/>}
        </Box>
      }
      {weight && parseInt(weight) > 0 &&
        <Box>
          <SpreadTextElement color={individualStyle.secondaryColor}
                             text={"Вес"} value={weight + " гр"}/>
        </Box>
      }
      {volume && parseInt(volume) > 0 &&
        <Box>
          <SpreadTextElement color={individualStyle.secondaryColor}
                             text={"Объем"} value={volume + " мл"}/>
        </Box>
      }
    </Paper>
  );
}

export default InfoBlock;