import React, {useEffect, useState} from 'react';
import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import AdmUnitRow from "./AdmUnitRow";
import {useParams} from "react-router-dom";
import Api from "../../api";
import LoadingPage from "../../Pages/LoadingPage";



function Paginator(props){
  return(
    <>
      {props.pagination && props.pagination.last_page > 1 &&
        <Pagination count={props.pagination.last_page} page={props.pagination.current_page}
                    onChange={props.handlePageChange}  variant="outlined" shape="rounded"
        />}
    </>
  )
}
function AdmUnitTable(props) {
  const {place, id} = useParams();
  const [goods, setGoods] = useState({});
  const [pagination, setPagination] = useState({current_page:1, last_page:1,per_page:25});
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({...props.filter});
  useEffect(()=>{
    let data = {...props.filter,category:id ?? 0, page:pagination.current_page, perpage:pagination.per_page};
    Api.get(`/goods/${place}`, data)
      .then(res => res.ok ? res.json() : null)
      .then((data) => {
        setGoods(data.goods);
        // setCategory(data.category);
        let pag = {...data.goods};
        delete (pag.data);
        pag.per_page = parseInt(pag.per_page);
        setPagination(pag);

      })
      .catch()
      .finally(()=>{
        setLoading(false);
      })
  },[filter, place, id, pagination.current_page, pagination.per_page])
  useEffect(()=>{
    setFilter({...props.filter});
    setPagination({...pagination, current_page: 1});
  },[props.filter])


  const updateRow = (index, newValue)=>{
    let glist = goods.data;
    glist[index] = newValue;
    setGoods({...goods, data:glist});
  }
  const swap = (index, direction) => {
    if(!goods?.data)
      return
    if(index <= 0 && direction === "up")
      return;
    if(index >= goods.length - 1 && direction === "down")
      return;
    let movedIndex = direction === "up" ? index - 1 : index + 1;
    let catMoving = goods.data[index];
    let catMoved = goods.data[movedIndex];
    Api.get(`/goods/${place}/swap`,
      {moving:catMoving.id, moved:catMoved.id})
      .then(res => res.ok ? res.json() : null)
      .then((data) => {
        // console.log(data);
        let glist = goods.data;
        glist[index] = data.moved;
        glist[movedIndex] = data.moving;
        setGoods({...goods, data:glist});
      })
      .catch()
      .finally(() => setLoading(false))
  }
  if(loading)
    return (<LoadingPage />);
  return (
    <Box>
      {/*<Paginator pagination={pagination} handlePageChange={(page)=>props.updateFilter({...props.filter, page:page})} />*/}

      <TablePagination
        component="div"
        count={pagination.total ?? 0}
        page={pagination.current_page - 1}
        onPageChange={(e, page)=>setPagination({...pagination, current_page: page+1})}
        rowsPerPage={pagination.per_page}
        labelRowsPerPage={'на странице'}

        onRowsPerPageChange={
          (e)=>setPagination({...pagination, per_page: e.target.value, current_page:1})
        }
      />
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="center">Название</TableCell>
              <TableCell align="center">Категория</TableCell>
              <TableCell align="center">Описание</TableCell>
              <TableCell align="center">Цена</TableCell>
              <TableCell align="center">Статус</TableCell>
              <TableCell align="center">Изображение</TableCell>
              <TableCell align="center">Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {goods?.data?.map((row, index) => (
              <AdmUnitRow row={row} swap={swap} last={index == goods.data.length - 1}
                          categories={props.categories}
                          index={index} key={row.id} updateRow={updateRow} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/*<Paginator pagination={pagination} handlePageChange={(page)=>props.updateFilter({...props.filter, page:page})} />*/}
    </Box>

  );
}

export default AdmUnitTable;
