import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogTitle, FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper, Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import AdmCategoryModal from "../../components/Adm/AdmCategoryModal";
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdmStyleModal from "../../components/Adm/AdmStyleModal";
import AdmTagsModal from "../../components/Adm/AdmTagsModal";
import TagChip from "../../components/Qr/TagChip";

function AdmTagsListPage(props) {

  document.title = 'Теги';

  const [tags, setTags] = useState({categories:[]});
  const [tagType, setTagType] = useState('all');
  const [loading, setLoading] = useState(true);

  const {place} = useParams();
  const navigate = useNavigate();


  const reloadPage = () =>{
    setLoading(true);
    Api.post('/adm/tags/get',
      {place:place, type:tagType})
      .then(res => res.ok ? res.json() : null)
      .then((data) => {
        setTags(data.tags ?? []);
      })
      .catch()
      .finally(() => setLoading(false))
  };

  useEffect(()=>{
    reloadPage();
  }, [place, tagType])
  const goBack = () => navigate(-1);

  if(loading)
    return (<CircularProgress />);

  const updateRow = (index, newValue)=>{
    let tagList = tags;
    tagList[index] = newValue;
    setTags([...tagList]);
  }



  return (
    <Stack spacing={2}>
      <Box p={'0.5rem'} display={'flex'} gap={'10px'}>
        <Typography variant={'h5'}>
          Теги
        </Typography>
      </Box>
      <Box p={'0.5rem'} display={'flex'} gap={'10px'}>
        <AdmTagsModal tag={{}} color={'success'}
          text={<AddBoxIcon />} variant={'contained'} new reload={reloadPage}
        />
        <FormControl size={'small'} sx={{minWidth:"130px"}}>
          <InputLabel id="demo-simple-select-label">Тип</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tagType}
            label="Age"
            onChange={(e) => {setTagType(e.target.value)}}
          >
            <MenuItem value={'all'}>Все</MenuItem>
            <MenuItem value={'goods'}>Позиция</MenuItem>
            <MenuItem value={'category'}>Категория</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell >Название</TableCell>
              <TableCell >Короткий текст</TableCell>
              <TableCell >Описание</TableCell>
              <TableCell >Тип</TableCell>
              <TableCell >Вид</TableCell>
              <TableCell >Редактировать</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Box display={'flex'} gap={2} alignItems={"center"}>
                    {row.id}
                  </Box>

                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.short_text}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>
                  <TagChip tag={row} />
                </TableCell>
                <TableCell>
                  <AdmTagsModal index={index} update={updateRow} tag={row}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default AdmTagsListPage;