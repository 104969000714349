import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import Api from "../api";

export const fetchPlace = createAsyncThunk(
  'place/fetchPlace',
  async function(params){

    const resp = await Api.post('/adm/place/find', params);
    const data = resp.ok ? await resp.json() : null;
    return data;
  }
)

const placeSlice = createSlice({
  name:'place',
  initialState: {
    loading: false,
    place: null,
    style: null,
    staff: {},
  },
  reducers:{
  },
  extraReducers:{
    [fetchPlace.pending]:(state, action)=>{
      state.loading=true;
    },
    [fetchPlace.fulfilled]:(state, action)=>{
      state.loading=false;
      if(!action.payload) {
        state.place = null;
        state.style = null;
        return;
      }
      let p = {...action.payload.place};
      p.data = typeof p.data == 'object' ? p.data : JSON.parse(p.data ? p.data : "{}")
      state.place = p;
      state.staff = action.payload.staff;
      state.style = action.payload.place.style ?? Api.getDefaultStyle();
    },
    [fetchPlace.rejected]:(state, action)=>{
      state.loading=false;
      state.place = null;

    }
  }
})



export const {} = placeSlice.actions;
export default placeSlice.reducer;