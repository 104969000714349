import React, {useEffect} from 'react';
import {
  Alert, Autocomplete, Box,
  Button, ButtonGroup, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, FormControlLabel, Grid,
  Snackbar, Stack, TextareaAutosize,
  TextField, Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/icons-material/HighlightOff';
import Image from "mui-image";
import Api from "../../api";
import axios from "axios";
import _ from "lodash";
import ImageSelectBlock from "./ImageSelectBlock";
import AdmTagElementController from "./AdmTagElementController";

function AdmCategoryModal(props) {
  const [open, setOpen] = React.useState(false);

  const [options, setOptions] = React.useState([{name:"Верхний уровень", id:0}]);
  const [styles, setStyles] = React.useState([{name:"По умолчанию", id:0}, ...props.styles]);
  const [snack, setSnack] = React.useState({message:'Данные успешно сохранены', type:'success'})
  const [loading, setLoading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedWideImage, setSelectedWideImage] = React.useState(null);

  const [cat, setCat] = React.useState(props.category)
  const {place, text, update, index} = props;
  // console.log('styles from modal', styles, props.styles)

  const getImage = () => {
    return selectedImage ? URL.createObjectURL(selectedImage) : Api.fileUrl(cat.image);
  }
  const getWideImage = () => {
    return selectedWideImage ? URL.createObjectURL(selectedWideImage) : Api.fileUrl(cat.image);
  }
  useEffect(()=>{
    setLoading(true);
    Api.post('/public/categories', {place:place, nomenu:true, all:true})
      .then(res => res.ok ? res.json() : null)
      .then((data) => setOptions([{name:"Верхний уровень", id:0}, ...data.categories]))
      .catch()
      .finally(()=>{setLoading(false)})
  }, [place])
  const handleSnackOpen = () => {
    setSnackOpen(true);
  };
  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const save = () => {
    setLoading(true);
    const formData = new FormData();
    formData.set('place', place);
    for ( var key in cat ) {
      formData.append(`category[${key}]`, cat[key]);
    }

    let savedTags = JSON.stringify(cat.tags ? cat.tags.map(i => i.id) : []);
    formData.append(`category[tags]`,savedTags );
    if(props.new)
      formData.set('new', true);
    console.log('saving', cat, savedTags);
    // Update the formData object
    if(selectedImage) {
      formData.append(
        "photo",
        selectedImage,
        selectedImage.name
      );
    }
    if(selectedWideImage) {

      // console.log('saving wide image', selectedWideImage);
      formData.append(
        "photo_wide",
        selectedWideImage,
        selectedWideImage.name
      );
    }



    Api.postForm('/adm/category/update', formData, {}, true)
      .then(resp => {
        setSnack({...{message:"Данные успешно сохранены", type:'success'}})
        handleSnackOpen();
        setOpen(false);

        let item = resp.data.item
        console.log('return', resp.data);
        setCat(item);
        if(props.update)
          props.update(index, item);
        if(props.new && props.reload)
          props.reload();
      }).catch(error=>{
        let errdata = error.response.data;
        setSnack({...{message:"Ошибка при сохранении данных: " + errdata.error, type:'error'}})
        handleSnackOpen();
      })
      .finally(()=>{
        setLoading(false);
      })
  };

  const setCatData = (value) => {
    console.log( 'set data', value, cat)
    setCat({...cat, ...value});
  }
  const findParentCat = () => {
    let value = _.find(options, function(option){
      if(option.id == cat.parent_id)
        return true;
    });
    if(!value)
      value = options[0];
    return value;
  }
  const findStyle = () => {
    let value = _.find(styles, function(option){
      if(option.id == cat.style_id)
        return true;
    });
    if(!value)
      value = options[0];
    return value;
  }


  return (
    <>
      <Button variant={props.variant ? props.variant : 'outlined'}
              color={props.color ? props.color : 'primary'} onClick={handleClickOpen}>
        {text ? text : <EditIcon />}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth={"lg"} fullWidth={true}>
        <DialogTitle>Редактирование категории</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} component={'form'} onSubmit={save}>
            <Grid item md={4} lg={3}>
              <Typography fontWeight={'bold'}>
                Малое изображение (~4x3)
              </Typography>
              <ImageSelectBlock setImage={setSelectedImage} image={cat.image} />
            </Grid>
            <Grid item md={8} lg={9}>
              <Typography fontWeight={'bold'}>
                Большое изображение (~10x3)
              </Typography>
              <ImageSelectBlock setImage={setSelectedWideImage} image={cat.image_wide} />
            </Grid>
            <Grid item md={12} fontSize={13} color={'text.disabled'}>
              Малое изображение используется для карточек категорий и оно же отображается
              в заголовке категории меню, если большое изображение не указано, но при этом
              оно может обрезаться на широком экране (> 800 пикселей).
              Большое же изображение используется исключительно для отображения в заголовке категории.
              При создании большого изображения стоит учитывать, что основной контент
              лучше всего держать строго в центре, потому как на малом экране (планшет, телефон) будет
              отображаться только центральная область.
            </Grid>
            <Grid item md={8}>
                <TextField size="small"
                  autoFocus margin="dense"
                  name='name' value={cat.name}
                  onInput={(e)=>setCatData({name: e.target.value})}
                  label="Название" fullWidth variant="standard"
                />
            </Grid>
            <Grid item md={4} alignItems={'center'} display={'flex'}>
              <FormControlLabel
                control={
                  <Checkbox defaultChecked={cat.status == 'active'}
                            onChange={(event)=>{setCatData({status:event.target.checked?'active':'inactive'})}} />
                }
                label="Активен"
              />
            </Grid>

            <Grid item md={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={options}
                value={findParentCat()}
                onChange={(event, newValue)=> {
                  setCatData({parent_id: newValue.id});
                }
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }

                sx={{ width: "100%" }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Родительская категория" />}
              />
            </Grid>
            <Grid item md={4}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={styles}
                value={findStyle()}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                onChange={(event, newValue)=> {
                    setCatData({style_id: newValue.id});
                  }
                }
                sx={{ width: "100%" }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Стиль элемента" />}
              />
            </Grid>

            <Grid item md={4}></Grid>
            <Grid item md={12}>
              <TextField multiline rows={4} size="small"
                         autoFocus margin="dense"
                         name='description' value={cat.description}
                         onInput={(e)=>setCatData({description: e.target.value})}
                         label="Описание" fullWidth variant="standard"
              />
            </Grid>
            <Grid item md={12}>
              <AdmTagElementController tags={cat.tags} setTags={(tags)=>setCatData({tags: tags})}/>
            </Grid>
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={save}>Сохранить</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackOpen} autoHideDuration={6000}
        onClose={handleSnackClose} anchorOrigin={{ vertical:'top', horizontal:'right' }}
      >
        <Alert onClose={handleSnackClose} severity={snack.type} sx={{ width: '100%' }}>
          {snack.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AdmCategoryModal;
