import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Stack, TextField, Typography} from "@mui/material";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Image from "mui-image";
import Api from "../api";
import {fetchUser, setAuth} from "../store/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {fetchPlace} from "../store/placeSlice";
import LoadingPage from "./LoadingPage";

function LoginPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/";
  const dispatch = useDispatch();
  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    dispatch(fetchUser())
  },[]);

  const user = useSelector(state => state.user);
  if(user.loaded && user.place && user.user){
    navigate(`/adm/${user.place}`)
  }

  const performLogin = ()=>{
    setLoggingIn(true);
    Api.post('/auth/login', {login: login, password: password})
      .then(resp => resp.json())
      .then((data)=>{
        if(data.error) {
          setError(data.error ?? 'Ошибка в процессе авторизации!')
          return;
        }
        if(!data.place){
          setError('Нет доступа в админ. панель')
          return;
        }
        setError('');
        localStorage.setItem('token', data.token);
        dispatch(setAuth(data));

        let from = searchParams.get('from');
        console.log('from', from);
        let addr = `/${from ?? 'adm'}/${data.place}`
        navigate(addr);
      })
      .finally(()=>setLoggingIn(false))
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={8}>
        <Image src={Api.fileUrl('bg.jpg')} fit={"cover"} duration={0} />
        <Box sx={{height:'100%', display:"flex", alignItems:"center", justifyContent:"center"}}
          xs={{display:"none"}} md={{display:"flex"}}>
        </Box>
      </Grid>
      {!user.loaded && <LoadingPage />}
      {user.loaded && <Grid item xs={12} md={6} lg={4}>
        <Stack
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete="off"
          display={"flex"}
          width={"100%"}
          minHeight={"100vh"}
          p={2}
          alignItems={"center"} justifyContent={'center'}
        >
          <Typography m={2} variant={"h5"}>
            Вход
          </Typography>
          <TextField sx={{width:"100%"}} onChange={(e) => setLogin(e.target.value)} label="Логин" variant="outlined" />
          <TextField fullWidth label="Пароль" variant="outlined"
             onChange={(e) => setPassword(e.target.value)}
             type="password"
             autoComplete="current-password"
          />
          <Button variant="contained" onClick={performLogin}>Войти</Button>
          {error && <Typography my={1} color={'error.main'}>
            {error}
          </Typography>}
        </Stack>

      </Grid>}

    </Grid>
  );
}

export default LoginPage;