import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import {
  Box, Button,
  CircularProgress, Pagination,
  Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import _ from "lodash";
import PageNotExistsPage from "../PageNotExistsPage";
import AdmCategoryModal from "../../components/Adm/AdmCategoryModal";
import AddBoxIcon from '@mui/icons-material/AddBox';
import Utils from "../../utils";

function AdmKeeperCategoryListPage(props) {
  document.title = 'Категории Keeper';

  const [cat, setCat] = useState({categories:[], goods:[]});
  const pagination = cat.pagination;
  const rows = cat.categories;
  const [loading, setLoading] = useState(true);
  const [onlyActive, setOnlyActive] = useState(false);

  const {place, id} = useParams();
  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };


  const reloadPage = () =>{
    setLoading(true);
    Api.post('/adm/keeper/get',
      {place:place, id:id, page:page})
      .then(res => res.ok ? res.json() : null)
      .then((data) => setCat(data))
      .catch()
      .finally(()=>  setLoading(false))
  };

  useEffect(()=>{
    reloadPage();
  }, [place, id, page])
  const goBack = () => navigate(-1);

  if(loading)
    return (<CircularProgress />);
  const updateRow = (index, newValue)=>{
    rows[index] = newValue;
    cat.categories = rows;
    setCat({...cat});
  }

  const getDefaultCatForNew = ()=> {
    return {
      id:0,
      name:'Новая категория',
      description:"Описание новой категории",
      parent_id:id,
      status:'active',
      image:Utils.goodsDefaultImage()
    }
  }


  return (
    <Stack spacing={2}>
      <Box py={'0.5rem'} display={'flex'} gap={'10px'}>
        <Typography variant={'h5'}>
          {cat.current ? cat.current.name : "Все категории Keeper"}
        </Typography>
      </Box>
      {pagination && pagination.totalPages > 1 &&
        <Pagination count={pagination.totalPages} page={page}
                    onChange={handlePageChange}  variant="outlined" shape="rounded"
        />
      }
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="center">Название</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cat.categories.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell>
                  <Link to={`/adm/${place}/keeper/${row.id}`}>{row.name}</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && pagination.totalPages > 1 &&
        <Pagination count={pagination.totalPages} page={page}
                    onChange={handlePageChange}  variant="outlined" shape="rounded"
        />
      }
    </Stack>
  );
}

export default AdmKeeperCategoryListPage;