import React, {useState} from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import StyleElement from "./StyleElement";
import {useDispatch, useSelector} from "react-redux";
import {updateStyle} from "../../../store/sandboxStyleSlice";

function CategoryStyles(props) {
  const {setImage} = props;
  const style = useSelector(state => state.sandboxStyle.style);
  const dispatch = useDispatch();
  const [elmId, setElmId] = useState(0);
  const update = (id,val) => {
    const list = {...style.categoryList, [id]:val} ?? {[id]:val};
    dispatch(updateStyle({...style, categoryList:list}))
  }
  const remove = (id) => {
    let list = {...style.categoryList} ?? {};
    delete list[id];
    dispatch(updateStyle({...style, categoryList:list}))
  }
  return (

    <Grid container spacing={1} my={2}>
      <Grid item md={4}>

      </Grid>
      <Grid item md={8}>
        {(elmId > 0) &&
        <StyleElement label={""} id={elmId} setImage={setImage} part={'categoryBlock'}
                      labels={{secondaryColor:'Текст описания'}} />}
      </Grid>

    </Grid>
  );
}

export default CategoryStyles;