import {configureStore} from "@reduxjs/toolkit";
import sandboxStyleReducer from './sandboxStyleSlice'
import snackbarReducer from './snackbarSlice'
import placeReducer from './placeSlice'
import staffReducer from './staffSlice'
import userReducer from './userSlice'
import orderReducer from './orderSlice'
import codeReducer from './codeSlice'


export default configureStore({
  reducer:{
    sandboxStyle:sandboxStyleReducer,
    place:placeReducer,
    code:codeReducer,
    staff:staffReducer,
    snackbar:snackbarReducer,
    user:userReducer,
    order:orderReducer,
  }
});