import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../api";
import PageNotExistsPage from "../PageNotExistsPage";
import {CircularProgress, Container, Grid, Pagination, Paper, Typography} from "@mui/material";
import ImageHeader from "../../components/Qr/ImageHeader";
import CategoryBlock from "../../components/Qr/MenuPage/CategoryBlock";
import GoodsCardVertical from "../../components/Qr/GoodsCardVertical";
import LoadingBlock from "../../components/Qr/LoadingBlock";
import {useSelector} from "react-redux";

function QrMenuIndexPage(props) {

  const [goods, setGoods] = useState(null);
  const [loading, setLoading] = useState(true);

  let {place, style} = useSelector(state=> state.place)
  const defaultStyle = Api.processStyle(style, 'main');

  useEffect(()=>{
    document.title = 'Наше меню';
    setLoading(true);

    Api.post('/public/goods', {place:place.code,only_index:true, random:true})
      .then(res => res.ok ? res.json() : null)
      .then((d) => {
        // console.log(d)
        setGoods(d);
      })
      .catch()
      .finally(()=>  {setLoading(false)})
  }, [place]);


  if(loading)
    return (<LoadingBlock />);

  return (
    <>
      <ImageHeader cover noMargin  height={"190px"}
                   image={Api.fileUrl(place.menu_image ?? place.image) } />

      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CategoryBlock slider={false} />
          </Grid>
          {goods.goods.length > 0 && <Grid item xs={12}>
            <Typography fontWeight={'bold'} variant={"h6"} textAlign={'center'} mb={2}
              color={defaultStyle.tertiaryColor}
            >
              Рекомендуем
            </Typography>
          </Grid>}
          {goods.goods.map((i, index)=>
            <Grid item xs={6} sm={4} lg={3} key={index}>
              <GoodsCardVertical goods={i}/>
            </Grid>
          )}


        </Grid>
      </Container>
    </>
  );
}

export default QrMenuIndexPage;